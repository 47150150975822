import Vuex from 'vuex'
import Vue from 'vue'
import dashboardstore from './modules/dashboardstore'
import * as PayoutsStore from '@/store/modules/payoutstore'
import {nocodb,entymApi} from '@/axios';
//Load Vuex
Vue.use(Vuex);

// Create Store
export default new Vuex.Store({
    modules: {
        dashboardstore , PayoutsStore
    },
    state: {
        apiAuth : {
            token: '',
            expiry: ''

        },
        links : [
            
            {icon:"mdi-database-settings" , text:"Global Data" , route: "/globaldata"},
            {icon:"mdi-account-group" , text:"Users" , route: "/users"} ,
            {icon:"mdi-bell-cog" ,text:"Notify Users" ,route:"/sendnotifications"},
            {icon:"mdi-content-save-off", text:"Content Moderation", route:"/moderate"},
            {icon:"mdi-cash-clock", text:"Payouts", route:"/payouts-all"} ],
        count: 0 ,
        mainCardData : [
            {title: "Registered Users", text: 0, icon: "mdi-account-group", color: "blue"},
            {title: "Timeslots Sold", text: "0", icon: "mdi-timer", color: "green"},
            {title: "Revenue from timeslots", text: "0", icon: "mdi-cash-multiple", color: "blue"},
            {title: "Users offering timeslots", text: 0, icon: "mdi-account-multiple-check", color: "green"},
 
        ],
        secondRowData: [
            {title: "Booked Slots", text: "0", icon: "mdi-clock-plus",color: "blue"},
            {title: "Cancelled Slots", text: "0", icon: "mdi-clock-remove",color: "red"},
            {title: "Abandoned Slots", text: "0", icon: "mdi-clock-alert",color: "blue"},
            {title: "Rejected Slots", text: "0", icon: "mdi-clock-minus",color: "red"},
        ],
        regularUsersTable: [
            {category: "<2" , users: ""},
            {category: "2-10" , users: ""},
            {category: "10-50" , users: ""},
            {category: ">50" , users: ""}
        ],
        airtableData : {
            MainLocations: {
                header : [
                    { text: 'Mainlocattionsid',align: 'start',sortable: false,value: 'Mainlocattionsid',},
                    { text: 'Name', value: 'Locationname' },
                    { text: 'Country', value: 'Country' },
                    { text: 'Countrycode', value: 'Countrycode' },
                    { text: 'Subcountry', value: 'Subcountry' },
                    { text: 'Coordinates', value: 'Coordinates' },
                    { text: 'Actions', value: 'actions', sortable: false } ],
                url: {
                    getUrl: "GlobaldataMainlocations?fields=mainlocationsid%2CGeonameid%2CLocationname%2CCountrycode%2CPopulation%2C%2CTimezone%2CCoordinates",
                    deleteUrl : "GlobaldataMainlocations/",
                    updateUrl : "GlobaldataMainlocations/",
                    createUrl : "GlobaldataMainlocations",
                    countUrl : "GlobaldataMainlocations/count",
                    searchUrl: "GlobaldataMainlocations?where=(Locationname%2Clike%2C",
                    
                },
                sqlIdfield : 'mainlocationsid',
                
                editDisabledFields: {
                    mainlocationsid : true
                },
                editedItem: {
                    
                    Locationname: '',
                    Subcountry : '',
                    Countrycode: '',
                    Country: '',                    
                    Coordinates: '',
                },
                defaultItem: {
                    Locationname: '',
                    Subcountry : '',
                    Countrycode: '',
                    Country: '',                    
                    Coordinates: '',
                },
                editDialogItems: [
                    {}
                ]
            },
            Companies: {
                header : [
                    { text: 'Id',align: 'start',sortable: false,value: 'Companydbid',},
                    { text: 'Name', value: 'Companyname' },
                    { text: 'Web Page', value: 'Companywebpage' },
                    { text: 'YearFounded', value: 'Yearfounded' },
                    { text: 'Industry', value: 'Companyindustry' },
                    { text: 'SizeRange', value: 'ConpanysizeRange' },
                    { text: 'Locality', value: 'Companylocality' },
                    { text: 'Country', value: 'Companycountry' },
                    { text: 'LinkedinUrl', value: 'ConpanylinkedinUrl' },
                    { text: 'Actions', value: 'actions', sortable: false } ],
                url: {
                    getUrl: "GlobaldataCompanydb?limit=10",
                    deleteUrl : "GlobaldataCompanydb/",
                    updateUrl : "GlobaldataCompanydb/",
                    createUrl : "GlobaldataCompanydb",
                    countUrl : "GlobaldataCompanydb/count",
                    searchUrl: "GlobaldataCompanydb?where=(Companyname%2Clike%2C"
                },
                sqlIdfield : 'Companydbid',
                editDisabledFields: {
                    Companydbid : true,
                    Companydbuid : true,
                    Ctimestamp : true
                },
                editedItem: {
                    Companyname: '',
                    Companyindustry: '',
                    Companywebpage: '',
                    Companycountry: '',
                    Companylocality: '',
                    ConpanylinkedinUrl: '',
                    ConpanysizeRange: '',
                    Yearfounded: ''
                },
                defaultItem: {
                    Companyname: '',
                    Companyindustry: '',
                    Companywebpage: '',
                    Companycountry: '',
                    Companylocality: '',
                    ConpanylinkedinUrl: '',
                    ConpanysizeRange: '',
                    Yearfounded: ''
                },
                editDialogItems: [
                    {}
                ]
            },
            EduInstitutes: {
                header : [
                    { text: 'Id',align: 'start',sortable: false,value: 'Eduinstitutedbid',},
                    { text: 'Name', value: 'Name' },
                    { text: 'Web Page', value: 'Website' },
                    { text: 'Locationabbv', value: 'Locationabbv' },
                    { text: 'Actions', value: 'actions', sortable: false } ],
                url: {
                    getUrl: "GlobaldataEduinstitutedb?limit=10",
                    deleteUrl : "GlobaldataEduinstitutedb/",
                    updateUrl : "GlobaldataEduinstitutedb/",
                    createUrl : "GlobaldataEduinstitutedb/",
                    countUrl : "GlobaldataEduinstitutedb/count",
                    searchUrl: "GlobaldataEduinstitutedb?where=(Name%2Clike%2C"
                },
                sqlIdfield : 'Eduinstitutedbid',
                editDisabledFields: {
                    Eduinstitutedbid : true,
                    Eduinstitutedbuid : true,
                    Ctimestamp : true
                },
                editedItem: {
                    Language: '',
                    Name: '',
                    Visible: 0,
                    Website: '',
                    Locationabbv: '',
                },
                defaultItem: {
                    Language: '',
                    Name: '',
                    Visible: 0,
                    Website: '',
                    Locationabbv: '',
                },
                editDialogItems: [
                    {}
                ]
            },
            Degrees: {
                header : [
                    { text: 'Id',align: 'start',sortable: false,value: 'Edudegreesdbid',},
                    { text: 'Degreename', value: 'Degreename' },
                    { text: 'Degreeshortname', value: 'Degreeshortname' },
                    { text: 'Degreeabbv', value: 'Degreeabbv' },
                    { text: 'Category', value: 'Category' },
                    { text: 'Actions', value: 'actions', sortable: false } ],
                url: {
                    getUrl: "GlobaldataEdudegreesdb?limit=10",
                    deleteUrl : "GlobaldataEdudegreesdb/",
                    updateUrl : "GlobaldataEdudegreesdb/",
                    createUrl : "GlobaldataEdudegreesdb/",
                    countUrl : "GlobaldataEdudegreesdb/count",
                    searchUrl: "GlobaldataEdudegreesdb?where=(Degreename%2Clike%2C"
                },
                sqlIdfield : 'Edudegreesdbid',
                editDisabledFields: {
                    Edudegreesdbid : true,
                    Ctimestamp : true,
                    Edudegreesdbuid : true
                },
                editedItem: {
                    Degreename: '',
                    Degreeshortname: '',
                    Degreeabbv: '',
                    Category: ''
                },
                defaultItem: {
                    Degreename: '',
                    Degreeshortname: '',
                    Degreeabbv: '',
                    Category: ''
                },
                editDialogItems: [
                    {}
                ]
            },
            Skills: {
                header : [
                    { text: 'Id',align: 'start',sortable: false,value: 'Skillsdbid',},
                    { text: 'Name', value: 'Skillsname' },
                    { text: 'Actions', value: 'actions', sortable: false } ],
                url: {
                    getUrl: "GlobaldataSkillsdb?limit=10",
                    deleteUrl : "GlobaldataSkillsdb/",
                    updateUrl : "GlobaldataSkillsdb/",
                    createUrl : "GlobaldataSkillsdb/",
                    countUrl : "GlobaldataSkillsdb/count",
                    searchUrl: "GlobaldataSkillsdb?where=(Skillsname%2Clike%2C"
                },
                sqlIdfield : 'Skillsdbid',
                editDisabledFields: {
                    Skillsdbid : true,
                    Ctimestamp : true,
                    Skillsdbuid : true
                },
                editedItem: {
                    Skillsname: '',
                    Skillsdesc: '',
                    Skillsabbv: '',
                    SkillsCategoryName: '',
                    Visible: 0
                },
                defaultItem: {
                    Skillsname: '',
                    Skillsdesc: '',
                    Skillsabbv: '',
                    SkillsCategoryName: '',
                    Visible: 0
                },
                editDialogItems: [
                    {}
                ]
            },
            Hobbies: {
                header : [
                    { text: 'Id',align: 'start',sortable: false,value: 'HobbiesId',},
                    { text: 'Name', value: 'Name' },
                    { text: 'Category', value: 'Category' },
                    { text: 'Actions', value: 'actions', sortable: false } ],
                url: {
                    getUrl: "GlobaldataHobbiesdb?limit=10",
                    deleteUrl : "GlobaldataHobbiesdb/",
                    updateUrl : "GlobaldataHobbiesdb/",
                    createUrl : "GlobaldataHobbiesdb/",
                    countUrl : "GlobaldataHobbiesdb/count",
                    searchUrl: "GlobaldataHobbiesdb?where=(Name%2Clike%2C"
                },
                sqlIdfield : 'HobbiesId',
                editDisabledFields: {
                    HobbiesId : true,
                    HobbiesUid : true,
                },
                editedItem: {
                    Name: '',
                    Category: ''
                },
                defaultItem: {
                    Name: '',
                    Category: ''
                },
                editDialogItems: [
                    {}
                ]
            }
        }
    },
    mutations: {
        ADD_APITOKEN(state , value){
            state.apiAuth.token = value
        },
        ADD_REGISTEREDUSERS(state,value) {
            state.mainCardData[0]['text'] = value
        },
        ADD_TOTALTIMESLOTS(state,value){
            state.mainCardData[1]['text'] = value
        },
        ADD_USERSOFFERINGTIMESLOTS(state,value){
            state.mainCardData[3]['text'] = value
        },
        ADD_BOOKEDSLOTS(state,value){
            state.secondRowData[0]['text'] = value
        },
        ADD_ABANDONEDSLOTS(state,value){
            state.secondRowData[2]['text'] = value
        },
        ADD_CANCELLEDSLOTS(state,value){
            state.secondRowData[1]['text'] = value
        },
        ADD_REJECTEDSLOTS(state,value){
            state.secondRowData[3]['text'] = value
        },
        UPDATE_USERENGAGEMENTTABLE(state,value){
            state.regularUsersTable[0]['users'] = value.twoOrLess
            state.regularUsersTable[1]['users'] = value.twoandTen
            state.regularUsersTable[2]['users'] = value.tentoFifty
            state.regularUsersTable[3]['users'] = value.aboveFifty
        },
    },
    actions: {
        async getFirstrowData(context){
            //TODO: Get EntymUsersCount fro Dashboard/data         
            const resp = await nocodb.get('Entymusers/count')
            context.commit('ADD_REGISTEREDUSERS', resp.data.count)
            //TODO: Get UsertimeslotsCount from Dashboard/data
            const resp1 = await nocodb.get('Usertimeslots/count')
            context.commit('ADD_TOTALTIMESLOTS',resp1.data.count)
            const resp2 = await entymApi.get('Dashboard/data')
            context.commit('ADD_USERSOFFERINGTIMESLOTS',resp2.data.usersOfferingTimeslots)
            context.commit('ADD_BOOKEDSLOTS',resp2.data.bookedSlots)
            context.commit('ADD_ABANDONEDSLOTS',resp2.data.abandonedSlots)
            context.commit('ADD_CANCELLEDSLOTS',resp2.data.cancelledSlots)
            context.commit('ADD_REJECTEDSLOTS',resp2.data.rejectedSlots)
        },
        async getUserEngagement(context,dates){
            console.log("inside store  "+ dates)
            const resp = await entymApi.get("Dashboard/userengagement", {params:{startMonth: dates.startDate, endMonth:dates.endDate}})
            console.log(resp.data)
            context.commit('UPDATE_USERENGAGEMENTTABLE',resp.data)
        },
        async setToken(context,token){
            context.commit('ADD_APITOKEN',token)
        }
    },
    getters: {
        getAirTableHeaders: (state) => (value) => {
            return state.airtableData[value].header
        } 
        
    }

})