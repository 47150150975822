<template>
    <finance-layout>
      <h1>  All Pending Payouts </h1>
  
          <v-data-table
          dense
          hide-default-footer
          :headers="payoutsTableHeaders"
          :items="payoutsTableContent"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :loading = "loader"
          loading-text="Loading... Please wait"
          item-key="name"
          class="elevation-1">
              <template  v-slot:[`item.UserId`]="{ item }">
                          <router-link :to="{ name: 'User' , params: {userId:item.UserId} }">
                              {{ item.UserId }}
                          </router-link>
              </template>
              <template v-slot:[`item.Timeslotdata`]="{ item }">
                  <span>{{ new Date(item.Timeslotdata).toString() }}</span>
              </template>
              <template v-slot:[`item.Timeslot-id`]="{item}">
                  <span> {{item.Timeslotid}} {{ `( ${item.Timeslottrackerid} )`}} </span>
              </template>
              <template v-slot:footer>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
              ></v-pagination>
            </template>
  
            <template v-slot:[`item.Action`]="{ item }">
              
              <router-link :to="{ name: 'PayoutsConfirmV2' , params: {Id:item.Userpayoutsid} }">
                              Complete Payout
              </router-link>
                            
              
      </template>
          
          </v-data-table>
  
    </finance-layout>
    
  </template>
  
  <script>
  import FinanceLayout from '@/layouts/FinanceLayout.vue'
  import {nocodb} from '@/axios';
  
  export default {
    name: "payouts-all",
    components: {
      FinanceLayout
    },
    data(){
          return{
              search: '',
              loader : false,
              options: {},
              page: 0 ,
              itemsPerPage : 0 ,
              totalPayouts: 0 ,
              pageCount : 0,
              payoutsTableHeaders: [
                  {text:'UserId' , align:'start' , sortable : 'false' , value : 'Entymuserid'},
                  {text: 'email' , sortable : 'false', value: 'Email'},
                  {text: 'Amount' , sortable : 'false', value: 'Amount'},
                  {text: 'Currency' , sortable : 'false', value: 'Amountcurrency'},
  
                  {text: 'Action' , sortable : 'false', value: 'Action'},
  
              ],
              payoutsTableContent : [],
          }
      },
      methods: {
          async getPendingPayouts(){
              // Set loader to true
              this.loader = true
              //Call Api
              let resp = await nocodb.get('PayoutsPendingAll', {params: {sort : 'Lastmodifiedtimestamp', offset: this.getPaginationOffset(), limit : 25}})
              this.payoutsTableContent = resp.data.list
              this.totalPayouts = resp.data.pageInfo.totalRows
              this.page = resp.data.pageInfo.page
              this.itemsPerPage = parseInt(resp.data.pageInfo.pageSize)
              this.pageCount = this.getPageCount()
              //Finally set loader to false
              this.loader = false
              },
          getPaginationOffset(){
              let offset = 0;
              if (this.page > 1){
                  offset = (this.page-1) * this.itemsPerPage
              }
              return offset 
          },
         getPageCount(){
          return  Math.ceil(this.totalPayouts / this.itemsPerPage)
         },
         redirectToPayoutsConfirm(item){
          
          console.log(item) 
          this.$router.push({path: `/payouts-confirm-v2/${item.Userpayoutsid}`})
         }
  
      },
      computed : {
  
      },
       watch: {
        page: {
          handler () {
            this.getPendingPayouts()
          },
          deep: true,
        },
      },
      async created(){
          await this.getPendingPayouts()
      }
  }
  </script>
  
  
  <style>
  
  </style>