<template>
  <v-app-bar dense clipped-left app>
            <!-- -->
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-app-bar-title > <v-btn  @click="$router.push({path:'/'})"><v-img contain max-height="35" max-width="40" dark :src=logoImage></v-img> Entym  </v-btn>  </v-app-bar-title>
            <v-spacer></v-spacer>
            <v-menu
                offset-y
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-btn text v-if="!$auth.loading && $auth.isAuthenticated" @click="$router.push({path:'/profile'})">
                        <span>{{$auth.user.name}}</span>
                        <v-icon right> <img src="$auth.user.picture" alt=""></v-icon>
                    </v-btn>

                  </v-list-item>
                  <v-list-item>
                    <v-btn text v-if="!$auth.loading && $auth.isAuthenticated" @click="logout">
                        <span>Sign Out</span>
                        <v-icon right>exit_to_app</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
            </v-menu>
            
        </v-app-bar>
</template>

<script>
export default {
    data() {
        return {
            overlay: true,
            drawer: true,
            group: null,
            logoImage : require('@/assets/entym_low.png')
        }

    },
    methods: {
         // Log the user out
        logout() {
            this.$auth.logout({
                returnTo: "https://entym.app"
            });
        },
        handleClick() {
            
        }

    },
    watch: {
      drawer () {
        this.$emit("drawerFromChild");
      },
    },

}
</script>

<style>

</style>