<template>
  
  <v-data-table
                        :loading="loader"
                        :headers="tableHeaders"
                        :items="tableContent"
                        item-key="Id"
                        :options.sync="options"
                        :server-items-length="totalRows"
                        :items-per-page="itemsPerPage"
                        @click:row="handleRowClick"
                        >

                        <!-- <template slot="" -->
            </v-data-table>
</template>

<script>
import {nocodb} from '@/axios'

export default {
    async created(){
        this.fillTable()

    },
    data() {
        return {
            tab: null,
            loader: false,
            tableHeaders : [
                { text: 'PayoutId', value: 'Id',sortable: false, },
                { text: 'User', value: 'Entymuserid',sortable : false, },
                { text: 'Staus', value: 'Status',sortable : false, },
                { text: 'Amount', value: 'Amount',sortable : false, },               
                { text: 'Time', value: 'CreatedAt',sortable: false, },
                { text: 'Payout Done by', value: 'PayoutDoneBy',sortable: false, },
            ],
            tableContent: [],
            successTableContent: [],
            failedTableContet: [],
            totalRows : 0 ,
            itemsPerPage : 20,
            options: {},
        }
    },
    methods : {
        async fillTable(offset){
            this.loader = true
            const data = await nocodb.get('PayoutsAttempts',{params: {limit :this.itemsPerPage, offset:offset ,where:'(Status,eq,Error)',sort:'-CreatedAt'}})
            console.log(data.data.list)
            this.tableContent = data.data.list
            this.totalRows = data.data.pageInfo.totalRows

            this.loader = false

        },
        getPaginationOffset(pageNumber){
          let offset = 0;
          if (pageNumber > 1){
              offset = (pageNumber-1) * 10
          }
          return offset 
      },
      handleRowClick(event){
        let path = '/trackpayouts/' + event.Id 
        this.$router.push({path:path})
      }

     },
     watch : {
        options: {
            async handler(newValue){
                // Call Api according to the next page
                
                const offset = this.getPaginationOffset(newValue.page)
                await this.fillTable(offset)
            },
            deep: true
        }
     }

}
</script>

<style>

</style>