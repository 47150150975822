<template>
  <div class="d-inline-flex align-center mx-8"  >
      <v-select
        class="mx-2"
        v-model="select"
        :items="items"
        item-text="state"
        item-value="abbr"
        label="Select"
        return-object
        single-line
      ></v-select>
      
      <v-btn @click="applyCharge"> Apply </v-btn>
  </div>
</template>

<script>
import { nocodb } from '@/axios';
// This comes from a table in the database
export default {
  name: "NumberInput",
    data () {
      return {
        select: { },
        items: [
          
        ],
      }
    },
    async created(){
      const resp = await nocodb.get("https://ncdb.entym.app/api/v1/db/data/v1/dashboarddb/PayoutsNumberInput",{ params: { where:`(IsVisible,eq,1)` } })
      const transformedResponse = resp.data.list.map( (i) => {
        return  { state : ` ${i.ChargeName} - ${i.ChargeAmount} ${i.AmountCurrency}` , ChargeName: i.ChargeName , ChargeAmount: i.ChargeAmount, ChargeCurrency : i.ChargeCurrency, id : i.Id}
      })
      
      this.items = transformedResponse
      
    },
    methods :{
      applyCharge(){
        //Emits the event to listen in the parent component
        this.$emit('NumberInputSelect', this.select)
      }
    }
  }
</script>