const state = { 
    
}

const mutations = { 

}

const actions = {

}

const getters = {
    allMainCardData: (state) => state.allMainCardData
}

export default{
    state,mutations,actions,getters
}