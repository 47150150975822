<template>
  <dashboard-layout :links="links">
    <v-sheet>
    <h2> Send Notifications to all users</h2>

    <v-form ref="form" v-model="valid">
    <v-text-field
            v-model="title"
            :counter="titleMax"
            label="Message Title (Should not exceede 65 characters)"
            required
          ></v-text-field>

          <v-textarea
      required
      label="Message Content"
      auto-grow
      :counter="messageMax"
    ></v-textarea>
    
  </v-form>

   
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn 
        v-on="on"
        v-bind="attrs"
        class="mx-auto mb-4" 
        color="success" 
        large>
        Send to all
        </v-btn>

        </template>

     <v-card>
        <v-card-title class="success"> Confirm</v-card-title>
        <v-card-text> Send message to all users </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
          class="mx-auto"
            color="success"
            text
            @click="confirm()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
     </v-card>
    </v-dialog>
 
    </v-sheet>



  </dashboard-layout>
  
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'

//import sendNotificationForm from '@/components/sendNotificationForm.vue'
export default {
    computed : {
        links() {
            return this.$store.state.links
        }
     },
    components : {DashboardLayout}
    ,
    data(){
        return {
            titleMax : 65,
            messageMax : 400,
            valid : true
        }
    },
    methods : {
        confirm(){
            console.log('Send ')
        }
    }

}
</script>

<style>

</style>