<template> 
  <div class="d-inline-flex align-center mx-8"  >
    <v-select
          v-model="selectedItem"
          class="mx-2"
          :items="items"
          item-text="state"
          item-value="ChargePercent"
          label="Select the value"
          return-object
          single-line
        ></v-select>
        <v-btn @click="applyCharge"> Apply </v-btn>
  </div>
        
</template>

<script>
import { nocodb } from '@/axios';
export default {
  name: "PercentageInput",
    data () {
      return {
        selectedItem: {},
        items: [
          
        ],
      }
    },
    async created(){
      const resp = await nocodb.get("https://ncdb.entym.app/api/v1/db/data/v1/dashboarddb/PayoutsPercentageInput", { params: { where:`(IsVisible,eq,1)` } })
      
      const transformedResponse = resp.data.list.map( (i) => {
        return  { state : ` ${i.ChargeName} - ${i.ChargePercent}%` , ChargeName: i.ChargeName , ChargePercent: i.ChargePercent, id : i.Id}
      })
      
      this.items = transformedResponse
    },
    methods: {
      applyCharge(){
        //Emits the event to listen in the parent component
        this.$emit('percentInputSelect', this.selectedItem)
      }
    }
  }
</script>