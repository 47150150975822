<template>
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn width="40%" 
        v-on="on"
        v-bind="attrs"
        class="mx-auto mb-4" 
        color="success" 
        large
        >
        Pay
        </v-btn>

      </template>

     <v-card v-if="!loading">
        <v-card-title class="primary" > Confirm</v-card-title>
        <v-card-text class="text-subtitle-1 font-weight-bold" dark> 
          
          <div> Please review the details before clicking confirm </div>
          
          <!-- <div v-for=" (item,index) in payoutsObject" :key="index">
            <ol>
            <li v-for="(k,innerIndex) in  item.Charges" :key="innerIndex">
              <div class="d-inline-flex ">
                <div> Charge: {{ k.Charge }}</div>
                <div> Amount: {{ k.Amount }}</div>
              </div>
            </li>

          </ol>
          </div> -->

          <ol>
            <li v-for="(k,innerIndex) in  charges" :key="innerIndex">
              <div class="d-inline-flex ">
                <div> Charge: {{ k.Charge }}</div>
                <div> Amount: {{ k.Amount }}</div>
              </div>
            </li>

          </ol>

        </v-card-text>
        <v-divider></v-divider>



        <v-card-actions class="d-flex justify-center align-center">
          <v-btn @click="dialog= false"> 
            Cancel
          </v-btn>

          <v-btn
            color="primary"
            @click="confirmPayment()"
          >
            Confirm
          </v-btn>
          
        </v-card-actions>
     </v-card>

    <v-card v-if="loading && !processingDone" min-height="300" class="d-flex justify-center align-center">
      <div>
        <v-progress-circular     
          :size="70"
          color="primary"
          indeterminate
          :width="10"
        ></v-progress-circular>
        <p class="d-block"> Processing</p>

      </div>

    </v-card>

    <v-card v-if="loading && processingDone" min-height="300" class="d-flex justify-center align-center">  
      
      <div v-if="apiSuccess" >
        <v-alert
        type="success"
        > {{apiMessage}}</v-alert>
        <p> Please do not change the page. You will be redirected</p>


      </div>

      <div v-if="!apiSuccess">
        <v-alert min-height="50"
        type="error"
        > {{apiMessage}} </v-alert>
        <p> Please do not change the page. You will be redirected</p>
      </div>
     
    </v-card>

    <v-card> 


    </v-card>

    </v-dialog>
</template>

<script>
import {entymApi} from '@/axios'
export default {
    props: ["payouts","charges"],
    created(){
      console.log('payouts object ',this.payouts)
    },
    data(){
      return {
        dialog : false,
        loading : false,
        processingDone: false,
        apiSuccess: false,
        apiMessage : ''
        
      }
    },
    computed: {
      payoutsObject: function(){
          return this.payouts
        },
      chargesObject: function() {
        return this.charges
      }
    },
    methods : {
      updateCharges(){
        console.log('adadad ',this.payoutsObject)
      },
        async confirmPayment(){
            //TODO
            // Start Spinner
            this.loading = true
            this.payoutsObject[0].Charges = this.charges
            // Validate Info
            console.log("payoutsOject ",this.payoutsObject)
            const body = {
              StripePayoutModel : this.payoutsObject,
              PayoutDoneBy : this.$auth.user.email
            }
            // Call Stripe API
            try{
              console.log(body)
              var resp = await entymApi.post('Payouts/InitiateStripePayout', body)
              console.log(resp.data)
              if(resp.data.statusCode === 200){
                this.processingDone = true
                this.apiSuccess = true
                this.apiMessage = resp.data.message
              }
              if(resp.data.statusCode === 500){
                this.processingDone = true
                this.apiSuccess = false
                this.apiMessage = resp.data.message
              }
              console.log(resp.data)             

            }
            catch(e){
              console.log('Catch block')
              console.log(e)
              this.processingDone = true
              this.apiSuccess = false
              this.apiMessage = 'Internal Processing error '
            
            }

            setTimeout(() => {
              this.$router.push({path:'/payouts-user'})
            },10000)

        }
    },    

}
</script>

<style>

</style>