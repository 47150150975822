<template>
 <v-expansion-panels multiple v-model="panel" accordion>
            
            <v-expansion-panel>
                
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Posts</v-expansion-panel-header>
                <v-expansion-panel-content >
                    <v-row v-if="loading"> 
                        <v-col  v-for="c in 3" :key="c">
                            <v-skeleton-loader
                                class="mx-auto"
                                max-width="300"
                                type="card"
                                ></v-skeleton-loader>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" v-for="(k,index) in posts" :key="index">
                            <v-card
                                :loading="loading"
                                max-width="300"
                                >
                                <template slot="progress">
                                    <v-progress-linear
                                    color="primary"
                                    height="10"
                                    indeterminate
                                    ></v-progress-linear>
                                </template>
                                                
                                <v-carousel height="300">
                                     <v-carousel-item
                                        v-for="(item, i) in k.mediaUrl"
                                        :key="i"
                                        :src="item"
                                        class="carousel-item"
                                    >
                                    </v-carousel-item>

                                </v-carousel>

                                <v-card-text class="text-caption">
                                    <v-row dense>
                                        <v-col class="text-caption"> <span class="font-weight-bold">Caption: </span> {{k.caption}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                         <span class="font-weight-bold">Description: </span> {{k.description}}
                                    </v-row>
                                    <v-row dense>


                                        <v-col> <post-likes :postId="k.postId" :count="k.likesCount"> </post-likes></v-col>
                                        <v-col><span class="font-weight-bold">Views: </span> {{k.viewsCount}}</v-col>
                                        <v-col><span class="font-weight-bold">Comments: </span> {{k.commentsCount}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="8"> <span class="font-weight-bold">Posted on: </span> {{k.timeStamp}}</v-col>
                                        <v-col><span class="font-weight-bold">PostId: </span> {{k.postId}}</v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
 </v-expansion-panels>


</template>

<script>
import {entymApi} from '@/axios'
import PostLikes from '@/components/UserProfileComponents/PostLikes.vue'
export default {
    props: ['userId'],
    components: {PostLikes},
    async created(){
        this.loading = true

        const resp = await entymApi.get('EntymUser/Posts',{params: {EntymUserId: this.userId}})
        console.log(resp.data.posts)
        this.posts = resp.data.posts
        this.postSrc = resp.data.posts.medaiUrl

        this.loading = false

    },
    data(){
        return {
            loading: false,
            panel: [0,1,2,3,4,5,6],
            expanded: [],
            singleExpand: true,
            posts: [],
            postSrc: []
            }
        },
    computed: {
        rows(){
            //Get the number of rows and round off greater value
            let rows = this.posts.length / 3
            return Math.ceil(rows)
        },
    }

}
</script>

<style scoped>


</style>