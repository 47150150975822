<template>
    <dashboard-layout :links="links">
        <h1>Notification History</h1>
        <v-data-table
                    :loading="loader"
                    v-model="selected"
                    :headers="tableHeaders"
                    :items="tableContent"
                    item-key="Entymuserid"
                    :options.sync="options"
                    :server-items-length="totalRows"
                    :items-per-page="itemsPerPage"
                    >
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import {nocodb} from '@/axios'
export default {
    components :{DashboardLayout},
    async created(){
        this.fillTable(0)

     },
     data(){
        return {
            loader : false,
            tableHeaders : [
                { text: 'Title', value: 'NotificationTitle',sortable: false, },
                { text: 'Content', value: 'NotificationMessage',sortable : false, },
                { text: 'ImageLink', value: 'ImageLink',sortable : false, },               
                { text: 'Users', value: 'Users',sortable: false, },
                { text: 'Time', value: 'Timestamp',sortable: false, },
                { text: 'Sent by', value: 'Sentby',sortable: false, }
            ],
            tableContent : [],
            totalRows : 0 ,
            itemsPerPage : 10,
            options: {},

        }
     },
     methods : {
        async fillTable(offset){
            this.loader = true
            const data = await nocodb.get('NotificationsSentLogs',{params: {limit :this.itemsPerPage, offset:offset }})
            this.tableContent = data.data.list
            this.totalRows = data.data.pageInfo.totalRows

            this.loader = false

        },
        getPaginationOffset(pageNumber){
          let offset = 0;
          if (pageNumber > 1){
              offset = (pageNumber-1) * 10
          }
          return offset 
      },

     },
     computed : {
        links() {
            return this.$store.state.links
        },
        
     },
     watch : {
        options: {
            async handler(newValue){
                // Call Api according to the next page
                
                const offset = this.getPaginationOffset(newValue.page)
                await this.fillTable(offset)
                


            },
            deep: true
        }
     }
     
     
}
</script>

<style>

</style>