<template>
   <v-expansion-panels multiple v-model="panel" accordion>

            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Summary </v-expansion-panel-header>
                <div v-if="userActivityHistoryoader" class="grid">
                    <v-progress-circular  indeterminate :size="50" color="primary" ></v-progress-circular>

                </div>
                <v-expansion-panel-content>
                    <iframe 
                    :src="metabaseDash"
                    style = "min-height:350px; width:100%;"
                    frameborder="0"
                    allowtransparency
                ></iframe>

                </v-expansion-panel-content>

            </v-expansion-panel>
            
            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">User Activity History</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :headers="userActivityHeader"
                    :items="userActivityContent"
                    item-key="name"
                    :loading="userActivityHistoryoader"
                    class="elevation-1">
                    <template  v-slot:[`item.userId`]="{ item }">
                        <router-link :to="{ name: 'user' , params: {userId:item.userId} }">
                            {{ item.userId }}
                        </router-link>
                        <!-- <a target="_blank" :href="item.recieptUrl">
                            {{ item.recieptUrl }}
                        </a> -->
                    </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

   </v-expansion-panels>
</template>

<script>
import {entymApi} from '@/axios'
export default {
     props: ['userId'],
    async created() {
        //Start Loader
        this.userActivityHistoryoader = true
        //Call API
        const [MetabseUserDashResp,resp] = await Promise.all(
        [  
            entymApi.get('Dashboard/UserActivityDash',{params: {userid:this.userId}}),
            entymApi.get('EntymUser/UserActivity', {params: {EntymUserId: this.userId}})
        ]
       )
        this.userActivityContent = resp.data.userActivityHistory
        this.metabaseDash = MetabseUserDashResp.data.url
        //Stop Loader
        this.userActivityHistoryoader = false
    },
    data(){
        return {
            panel: [0],
            expanded: [],
            singleExpand: true,
            userActivityHistoryoader: false,
            metabaseDash:'',
            userActivityHeader: [
                {text: 'App Location',align: 'start',sortable: false,value: 'appLocation',},
                { text: 'Time', value: 'timeStamp' , sortable: false},
                {text: 'Type of action', value: 'typeOfAction' , sortable: false},],
            userActivityContent: []
                            
        }
    }
    

}
</script>

<style scoped>
.grid{
  display: grid;
  place-items: center;
}

</style>