<template>
  <h1>Item {{code}}</h1>
</template>

<script>
export default {
    props: ['code'],
    created(){
        console.log(this.code)
    },

}
</script>

<style>

</style>