<template>
    <finance-layout>
        <v-container>

            <v-progress-circular v-if="loading" indeterminate size="80" color="primary" ></v-progress-circular>

            <v-row v-if="!loading" no-gutters> 

                <v-col>
                    <h3 class="mx-auto px-auto" > Time Slots </h3>
                    <v-card v-if="!showList" class="ma-auto mt-8" max-width="500">
                        <h2> This user has no timeslots </h2>  
                    </v-card>

                
                <div v-if="showList"
                    class="mx-auto"
                    max-width="500"
                >
                

                    <v-list>
                    <v-list-item-group v-model="items">
                        <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        @click="handleClick(item,'timeslots')"
                        >
                        
                        <v-list-item-content>
                            <v-list-item-title :v-text="item.text"> Timeslotid : {{ item.Usertimeslotsid }} </v-list-item-title>
                            <v-list-item-subtitle>Date :  {{ item.TimeslotData }} </v-list-item-subtitle>
                            <p> Amount : {{ item.Amountcurrency }} {{ item.Amountcurrency }} </p>
                            <v-list-item-subtitle> Status : {{ item.Status }}</v-list-item-subtitle>
                            
                        </v-list-item-content>
                        
                        </v-list-item>
                    </v-list-item-group>
                    
                    </v-list>


                    </div>
                    

                
                </v-col>
                <v-col>
                    
                    <h3 class="mx-auto px-auto" >  Bookings </h3>
                        <v-card v-if="!showBookings" class="ma-auto mt-8" max-width="500">
                            <h2> This user has no Booking </h2>  
                        </v-card>
                        <v-list v-if="showBookings"> 
                            <v-list-item v-for="(item,i) in Bookings" :key="i" @click="handleClick(item,'bookings')"> 
                                <!-- <p>  {{ item }} </p> -->
                                <v-list-item-content>
                                <v-list-item-title > Timeslotid : {{ item.usertimeslotsid }} </v-list-item-title>
                                <v-list-item-subtitle> Date : {{ item.timeslotData }}</v-list-item-subtitle>
                                <p> Amount : {{ item.Amount }} {{ item.amountcurrency }}</p>
                                <v-list-item-subtitle> Status : {{ item.status }}</v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    
                    
                </v-col>
            </v-row>
             
    
  <v-divider vertical> </v-divider>
</v-container>
</finance-layout>
</template>

<script>
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import {nocodb,entymApi} from '@/axios'
export default {
    props: ['id'],
    components: {
    FinanceLayout
  },
  data(){
    return {
        items: [],
        showList: false,
        showBookings: false,
        Bookings: [],
        loading: false
        

    }
  },
  async created(){
    this.loading = true
    await this.getTimeSlots()
    await this.getBooking()

    this.loading = false
  },
  methods : {

    async getTimeSlots(){
        let resp = await nocodb.get('Usertimeslots',{params: {where:`(Entymuserid,eq,${this.id})`}})
        this.items = resp.data.list
        resp.data.list.length > 0 ? this.showList = true : this.showList = false

    },
    async getBooking(){
        const resp = await entymApi.get('Payouts/GetUserBookingsForRefund',{params: {EntymUserId:this.id }})
        //const resp = await nocodb.get('Bookingrequeststart',{params: {where:`(Buyerid,eq,${this.id})`}})
        this.Bookings = resp.data
        if(resp.data.length > 0) {
            this.showBookings = true
        }

    },
    handleClick(item,type){
        let commonDataInterface = {}
        if (type === 'timeslots'){
            commonDataInterface.Usertimeslotsid = item.Usertimeslotsid
            commonDataInterface.Amount= item.Amount
            commonDataInterface.Amountcurrency = item.Amountcurrency
            commonDataInterface.Timeslotdata = item.TimeslotData
            commonDataInterface.Status = item.Status
            commonDataInterface.Entymuserid = this.id

        }
        else if(type === 'bookings'){
            commonDataInterface.Usertimeslotsid = item.usertimeslotsid
            commonDataInterface.Amount= item.amount
            commonDataInterface.Amountcurrency = item.amountcurrency
            commonDataInterface.Timeslotdata = item.timeslotData
            commonDataInterface.Status = item.status
            commonDataInterface.Entymuserid = this.id

        }
        let path = `/refunds-payout/slot/slot/${item.Usertimeslotsid}`
        console.log(path)
        this.$router.push({name:'PayoutsCancellationsRefundsInitiateRefund', params: {id:item.Usertimeslotsid,obj : commonDataInterface}})


    }
  }
}
</script>

<style>

</style>