<template>
    <dashboard-layout :links="links">
        <v-container>
            <v-row class="ml-auto">
                    <v-subheader class="pt-auto"> Search in a specific field</v-subheader>
                    <v-checkbox dense v-model="checkboxVals.email" label="Email"> </v-checkbox>
                    <v-checkbox  dense v-model="checkboxVals.name" label="Name"></v-checkbox>
                    <v-checkbox dense v-model="checkboxVals.userId" label="Unique Id"></v-checkbox>                  
                    <v-checkbox dense v-model="checkboxVals.id" label="Id"></v-checkbox> 
                </v-row>
            <v-text-field
                    v-model="searchTerm"
                    append-icon="mdi-magnify"
                    filled
                    clear-icon="mdi-close-circle"
                    clearable
                    label="Search"
                    type="text"
                    @click:append="search()"
                    v-on:keyup.enter= "search()"

                ></v-text-field>
                <v-data-table v-if="showTable"
                    :headers="tableHeaders"
                    :items="tableContent"
                    :hide-default-header="hideHeader"
                    hide-default-footer
                    class="elevation-1"
                    :loading="loader"
                    @click:row="handleRowClick"
                >
                <template  v-slot:[`item.Entymuserid`]="{ item }">
                        <v-avatar size="36">
                        <img v-if="item.Profileimage"                        
                            alt="Avatar"
                            :src= item.Profileimage>

                        </v-avatar>
                    </template>

                </v-data-table>
                
        </v-container>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import {nocodb} from '@/axios'
export default {
    components : {
        DashboardLayout
    },
    data(){
        return{
            loader: false,
            hideHeader : true,
            searchTerm: '',
            checkboxVals: {
                id : false,
                userId : false,
                email : true ,
                name : true
            },
            usersViewColumns : {
                id : 'Entymuserid',
                userId : 'Uid',
                email : 'Emailid',
                name : 'Name'
            },
            tableHeaders: [
                { text: 'Image', value: 'Entymuserid',sortable: false, },
                { text: 'Emailid', value: 'Emailid',sortable : false, },
                { text: 'Name', value: 'Name',sortable: false, },
                {text: 'User-Id',sortable: false,value: 'Uid',width: '20%'},
            ],
            tableContent: []

        }
    },
    computed: { 
      links() {
        return this.$store.state.links
      },
      showTable(){
        let x = false
        if (Object.keys(this.tableContent).length > 0) x= true
        return x
      }
      

      
    },
    watch : {
        checkboxVals : {
            handler () {
                //  this.checkBoxFilter(value)
                // let temp = []
                // for (const [key, value] of Object.entries(value1)) {
                //     if(value == true){
                //         temp.push( `${this.usersViewColumns[key]},like,%${this.searchTerm}%` )
                //     }          
                // }
                // console.log(temp)
            },
        deep: true,
        }
    },
    methods : {
        async search(){
            //Call Search API
            this.loader = true 
            try{
                var data = await nocodb.get('UsersView', { params: { where:`${this.dynamicSearchParams()}` , limit : 7} })
                this.tableContent = data.data.list

            }
            catch(e){
                console.log(e)
            }
            finally{
                this.loader = false
                this.hideHeader = false
            }   
             
        },
        
        dynamicSearchParams(){
        let temp = []
        for (const [key, value] of Object.entries(this.checkBoxFilter(this.checkboxVals))) { // eslint-disable-line no-unused-vars
                temp.push( `(${this.usersViewColumns[key]},like,%${this.searchTerm}%)` )
                      
        }
        let result = temp.join("~or")
        return  result

      },
      checkBoxFilter(obj){
            let res =  Object.fromEntries(Object.entries(obj).filter(([key, value]) => value == true) ) // eslint-disable-line no-unused-vars

            return res
        },
        handleRowClick(event){
        let path = '/users/' + event.Entymuserid 
        this.$router.push({path:path})
      },
               
        
    }

}
</script>

<style>

</style>