<template> 

    <finance-layout> 

        <v-data-iterator
        :items="items"
        :loading="mainLoader"
        hide-default-footer
        :key="componentKey"
        >

        <template v-slot:header>
        <v-toolbar
          class="mb-2"
          color="accent"
          dark
          flat
        >
          <v-toolbar-title> Confirm Payout - {{ $route.query.email }} - {{ $route.params.userId }}</v-toolbar-title>
          
        </v-toolbar>
      </template>

      <template v-slot:default="{items}"> 

        <v-container >
            <v-row > 
                <v-col
                    v-for="(item,index) in items" :key="index"
                    
                    cols="12"
                    sm="6"
                    md="4"
                    lg="4"
                >
                <v-card outlined style="border:1px solid black;">
                <v-card-title >
                    Timeslot-Id : {{ item.Timeslotid }}
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                <!-- <v-list-item>
                  <v-list-item-content>Guest:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.calories }}
                  </v-list-item-content>
                </v-list-item> -->

                <v-list-item>
                  <v-list-item-content>Time:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.Timeslotdata }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Amount:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.Amount}} {{item.Amountcurrency}}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="mx-2"> 

                  <v-row>
                  <v-card> 
                  <v-card-title class="subtitle-1"> User Provided Location </v-card-title>
                  <v-card-text>  
                      
                      <div v-if="item.locationData.userProvidedLocation">
                          <div> City : {{item.locationData.userProvidedLocation.city ? item.locationData.userProvidedLocation.city : '' }} </div> 
                          <div> Country : {{item.locationData.userProvidedLocation.country ? item.locationData.userProvidedLocation.country  : '' }} </div>
                      </div>
                  

                  </v-card-text>
                </v-card>

                <v-card> 
                  <v-card-title class="subtitle-1"> Stripe Location </v-card-title>
                  <v-card-text>  
                      
                      <div v-if="item.locationData.stripeLocation">
                          <div> City : {{item.locationData.stripeLocation.city ? item.locationData.stripeLocation.city : ''}} </div> 
                          <div> Country : {{item.locationData.stripeLocation.country ? item.locationData.stripeLocation.country : ''}} </div>
                      </div>
                  

                  </v-card-text>
                </v-card>

                <v-card> 
                  <v-card-title class="subtitle-1">Geo Location </v-card-title>
                  <v-card-text>  
                      
                      <div v-if="item.locationData.geoLocation">
                          <div> City : {{item.locationData.geoLocation ? item.locationData.geoLocation.city : ''}} </div> 
                          <div> Country : {{item.locationData.geoLocation.country ? item.locationData.geoLocation.country : '' }} </div>
                      </div>
                  

                  </v-card-text>
                </v-card>
                </v-row>

                </v-list-item>
                

                
                 

          
                

                <v-card class="mx-2">
                  <v-card-title class="ml-3 mt-2 subtitle-1"> Charges : </v-card-title>
                  <v-card-text>
                    <ol>                
                      <li v-for="(k, index2) in item.Charges" :key="index2">
                        
                        <div  class="d-inline-flex">
                          <div class="mx-2 body-1  grey--text text--darken-4"> {{ k.Charge}} </div>
                          <div class="mx-2 body-1"> {{ k.Amount}}</div>
                        </div> 
                    </li>
                  </ol>  
                  </v-card-text>
                </v-card>

                <v-list-item v-if="item.showDropdown === false">
                    <v-list-item-content class="align-end">
                                <v-btn icon @click="addRow(item.Userpayoutsid)"> 
                                  Add Charge  
                                <v-icon small right>mdi-plus-circle</v-icon>
                                    
                                </v-btn> 
                            </v-list-item-content>

                            <v-list-item-content class="align-end">
                                <v-btn  icon @click="deleteRow(item.Userpayoutsid)"> 
                                  Delete row 
                                    <v-icon small right>mdi-minus-circle</v-icon>
                                    
                                </v-btn> 
                            </v-list-item-content> 
                </v-list-item>
                <div v-else>             
                    <v-select
                      class="mx-4"
                      :items="AddRowSelectOptions"
                      :menu-props="{ top: false, offsetY: true }"
                      label="Select the type of charge"
                      @change="AddRowOptionSelected"
                    ></v-select>
                      
                    <div>
                        <component v-bind:is="selectedComponent" v-on:percentInputSelect="handlepercentInputSelected(item,...arguments)" v-on:NumberInputSelect="handleNumberInputSelect(item,...arguments)" v-on:CustomInputSelect="handleCustomInputSelect(item,...arguments)"></component>
                    </div>
                  </div>

                  <v-list-item>
              <v-list-item-content>Charges :</v-list-item-content>
              <v-list-item-content class="align-end">
                <span>
                    
                </span>

              </v-list-item-content>                    
            </v-list-item>

            <v-list-item>
              <v-list-item-content> Amount to be paid :</v-list-item-content>
              <v-list-item-content class="align-end">
                <span>  </span>
                <!-- <v-text-field
                label="Amount"i
                v-model="totalPaymentAmount"
                solo
                readonly
                ></v-text-field> -->
              </v-list-item-content>
            </v-list-item>

            </v-list>

                </v-card>
                </v-col>
            </v-row>
        </v-container>

      </template>

      <template v-slot:footer>

        <v-card class="mt-4">
        <v-card-title> Penalties </v-card-title>
        <v-container>
            <v-row>
            <v-col> <b>Amount</b>  </v-col>
            <v-col> <b>Issued by </b> </v-col>
            <v-col> <b>Type </b>  </v-col>
            <v-col> <b>Date Issued </b> </v-col>
            <v-col>  <b>Status</b>  </v-col>
            </v-row>
            <v-row v-for="(penalty,l) in penaltyData" :key="l">
            <v-checkbox
                v-model="penalty.selected"
            
            ></v-checkbox>
            <v-col> {{ penalty.Penaltyamount }}  {{ penalty.Penaltycurrency  }}</v-col>
            <v-col>  {{ penalty.Issuedby }}</v-col>
            <v-col>  {{ penalty.Type }}</v-col>
            <v-col>  {{ penalty.Dateissued }} </v-col>
            <v-col> {{penalty.Status}}</v-col>
            
            </v-row>
        </v-container>
        </v-card>

        <v-card class="mt-2 " color="accent">

        <v-card-title justify="center"> Totals </v-card-title>
        <v-row class="ml-6"> <v-col>  Total Timeslot Value : {{ totalTimeslotValue  }}</v-col> </v-row>
        <v-row class="ml-6"> <v-col> Total Penalty : {{totalPenalty   }}</v-col></v-row>
        <v-row class="ml-6"> <v-col> Total Charges : {{ totCharges  }}</v-col></v-row>
        <v-row class="ml-6"> <v-col> Payout Amount : {{  payoutAmount}}</v-col></v-row>
        <v-row>
          <v-dialog
              v-model="dialog"
              persistent
              width="500"
            >
          <template v-slot:activator="{ on, attrs }">

            <v-btn width="40%" 
            v-on="on"
            v-bind="attrs"
            class="mx-auto mb-4" 
            color="success" 
            large
            >
            Pay
            </v-btn>

          </template>

          <v-card v-if="!loading && isAuthenticated">
              <v-card-title class="primary" > Confirm</v-card-title>
              <v-card-text class="text-subtitle-1 font-weight-bold" dark> 
                
                <div> Please review the details before clicking confirm </div>
                
                <!-- <div v-for=" (item,index) in payoutsObject" :key="index">
                  <ol>
                  <li v-for="(k,innerIndex) in  item.Charges" :key="innerIndex">
                    <div class="d-inline-flex ">
                      <div> Charge: {{ k.Charge }}</div>
                      <div> Amount: {{ k.Amount }}</div>
                    </div>
                  </li>

                </ol>
                </div> -->



              </v-card-text>
              <v-divider></v-divider>



              <v-card-actions class="d-flex justify-center align-center">
                <v-btn @click="dialog= false"> 
                  Cancel
                </v-btn>

                <v-btn
                  color="primary"
                  @click="confirmPayout()"
                >
                  Confirm
                </v-btn>
                
              </v-card-actions>
          </v-card>

          <v-container fluid fill-height v-if="!isAuthenticated">
              <v-layout align-center justify-center>
              <v-flex>
                  <v-form @submit.prevent="signinToFirebase()">
                  <v-card class="elevation-12">
                      <v-toolbar dark color="primary">
                      <v-toolbar-title>Login</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                      <v-text-field
                          v-model="id"
                          prepend-icon="person"
                          name="login"
                          label="Login"
                          
                          type="text"
                      ></v-text-field>
                      <v-text-field
                          v-model="password"
                          prepend-icon="lock"
                          name="password"
                          label="Password"
                          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                          @click:append="() => (showPassword = !showPassword)"
                          :type="showPassword ? 'text' : 'password'"
                      ></v-text-field>
                      <p v-if="loginError" class="red--text" >{{errorMessage}}</p>
                      </v-card-text>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <!-- Check that the SDK client is not currently loading before accessing is methods -->
                      <div>
                        <v-btn @click="dialog = false"> Cancel </v-btn>
                      <v-btn  type="submit" @click="signinToFirebase()" color="primary">Login</v-btn>
                      
                      </div>
                      </v-card-actions>
                  </v-card>
                  </v-form>
              </v-flex>
              </v-layout>
          </v-container>


            <v-card v-if="loading && !processingDone" min-height="300" class="d-flex justify-center align-center">
              <div>
                <v-progress-circular     
                  :size="70"
                  color="primary"
                  indeterminate
                  :width="10"
                ></v-progress-circular>
                <p class="d-block"> Processing</p>

              </div>

            </v-card>

            <v-card v-if="loading && processingDone" min-height="300" class="d-flex justify-center align-center">  
              
              <div v-if="apiSuccess" >
                <v-alert
                type="success"
                > {{apiMessage}}</v-alert>
                <p> Please do not change the page. You will be redirected</p>


              </div>

              <div v-if="!apiSuccess">
                <v-alert min-height="50"
                type="error"
                > {{apiMessage}} </v-alert>
                <p> Please do not change the page. You will be redirected</p>
              </div>
            
            </v-card>



    </v-dialog>
        </v-row>
        
        </v-card>
        </template>
    </v-data-iterator>

        

        
    </finance-layout>

</template>

<script> 
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import PercentageInput from '@/components/PayoutComponents/V2/PercentageInput.vue'
import NumberInput from '@/components/PayoutComponents/V2/NumberInput.vue'
import CustomInput from '@/components/PayoutComponents/V2/CustomInput.vue'
import ConfirmDialog from '@/components/PayoutComponents/PayoutsConfirmDialog.vue'
import LocationComponent from '@/components/PayoutComponents/V2/LocationComponent.vue'
import { nocodb,entymApi } from '@/axios'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
//var firebaseui = require('firebaseui');
import "firebaseui/dist/firebaseui.css";

export default{
    components : {FinanceLayout,PercentageInput,NumberInput,CustomInput,ConfirmDialog,LocationComponent},
    props: ['userId'],
    data(){
        return {
            mainLoader : false,
            items: [],
            penaltyData : [],
            componentKey : 0,
            selectedComponent: {},
            AddRowSelectOptions: ["Percentage Charge","Number Charge","Custom Charge"],
            totCharges : 0,
            dialog : false,
            processingDone: false,
            apiMessage : '',
            apiSuccess : false,
            loading : false,
            bearerToken : '',
            isAuthenticated : false,
            id: "",
            password: "",
            loginError : "",
            errorMessage : "",
            showPassword : false
            
        }
    },
    async created(){
      this.mainLoader = true
      // Get the query params
      console.log(this.$route)
        const idsquery = this.$route.query.ids.join()
        console.log(this.$route.query.ids, 'query')      
        var resp = await nocodb.get('Userpayouts',{ params: {where: `(Userpayoutsid,in,${idsquery})~and(Status,eq,payoutpending)`}})       
        this.items = resp.data.list
        this.items.forEach((element,index) => {
          const Charges =  [
            
            ]
          this.items[index].Charges = Charges
          this.items[index]['showDropdown'] = false;
        })

        let penaltyResp = await nocodb.get('Userpenalty', {params : { where: `(Entymuserid,eq,${this.userId})~and(Status,eq,pending)`}})
        //Add selected value for checkbox
        let newKey = "selected"
        let newValue = false
        for (var i = 0; i < penaltyResp.data.list.length; i++) {
          penaltyResp.data.list[i][newKey] = newValue;
          
          }
        this.penaltyData = penaltyResp.data.list
        console.log('penalty data',this.penaltyData)
        
        //this.penaltyData.map(obj => ({ ...obj, selected: false }));
        const locationsResp = await entymApi.post('/Payouts/GetPayoutLocations',
        this.$route.query.ids  )
        console.log(locationsResp,'locationk resp')

        //Map this onto the main object
        console.time('Foreach')
        this.items.forEach((item) => {
          //Find index of the second object
          const locationIndex = locationsResp.data.findIndex(i => i.userPayoutsId === item.Userpayoutsid)
          item.locationData = locationsResp.data[locationIndex]
        })
        console.timeEnd('Foreach')
        console.time('myFunction');
        //const a3 = this.items.map(t1 => ({...t1, ...locationsResp.data.find(t2 => t2.userPayoutsId === t1.Userpayoutsid)}))
        console.timeEnd('myFunction')
         this.componentKey++
        this.mainLoader = false


    },
    computed : { 
      totalTimeslotValue(){
        const sum =  this.items.reduce( (accumulator, item) => {
          return  accumulator +  parseFloat(item.Amount)
        },0.0)
        console.log(sum, 'totalTimsltValue')
        
        return sum
      },
      totalPenalty(){
        return this.penaltyData.reduce( (accumulator,item) => {
          if(item.selected){
            return accumulator = accumulator +  parseFloat(item.Penaltyamount)
          }
          return accumulator
        },0.0)
      },
      payoutAmount() {
        return this.totalTimeslotValue - (this.totalPenalty + this.totCharges)
      }
    },
    // watch : {
    //   totalTimeslotValue :{
    //     deep : true,
    //     handler : function(){
    //       this.componentKey++
    //     }
    //   }
    // },
    methods : {
      totalCharges(){
        return this.items.reduce( (accumulator, item) => {

           const temp =  item.Charges.reduce( (charge, j)=> {
             return charge + parseFloat(j.Amount)
          },0.0 )
          console.log('Chargeeeee',temp)
          return accumulator + temp

        },0.0 )
      },
        addRow(userpayoutsid){
            //Find the object in the array and update the showDropdown valeu
            let objIndex = this.items.findIndex(i => i.Userpayoutsid === userpayoutsid)
            this.items[objIndex].showDropdown = true
            console.log(this.items[objIndex])
            this.componentKey++
        },
        deleteRow(userpayoutsid){

           const index = this.items.findIndex(i => i.Userpayoutsid === userpayoutsid);
           this.items[index].Charges.pop()
           this.componentKey++

        },
        
      AddRowOptionSelected(selectedOption){
          if (selectedOption === 'Percentage Charge') {
              this.selectedComponent = "PercentageInput"
          } else if (selectedOption === 'Number Charge') {
              this.selectedComponent = "NumberInput"
          }
          else if(selectedOption === 'Custom Charge'){
            this.selectedComponent = "CustomInput"
          }

        },
        handlepercentInputSelected(item,eventData){
        //Add the charge to Charges Object in Items array

        //Find the object from items array
        //Get ChargeId 
        this.items.forEach((i,index)=>{
          if(i.Userpayoutsid == item.Userpayoutsid){
            const ChargeId = i.Charges.length + 1
                    //Calculate Amount 

            const Amount = i.Amount * eventData.ChargePercent / 100
            const charge = {ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount}
            this.items[index].Charges.push(charge)
            //this.$set(this.items[i],'charge'+ChargeId, true);
            i['showDropdown'] = false;
          }
          this.totCharges = this.totalCharges()
          this.componentKey++
        })
        
        
       
        //this.$set(this.items[0].Charges, ChargeId, "asdasd");
        //this.showDropdown = false

      },
      handleNumberInputSelect(item,eventData){
        const index = this.items.findIndex( i => i.Userpayoutsid == item.Userpayoutsid )
        const ChargeId = this.items[index].Charges.length + 1
        const Amount = eventData.ChargeAmount 
        
        //let charge = {ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount}
        this.items[index].Charges.push({ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount})
        //this.$set(this.items[index],'showDropdown', false);
        this.items[index]['showDropdown'] = false
        this.totCharges = this.totalCharges()
        this.componentKey = this.componentKey + 1
      },


      handleCustomInputSelect(item,eventData){
        console.log(eventData,'event')
        const index = this.items.findIndex( i => i.Userpayoutsid == item.Userpayoutsid )
        const ChargeId = this.items[index].Charges.length + 1
        const Amount = eventData.ChargeAmount 
        
        this.items[index].Charges.push({ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount})
        this.items[index]['showDropdown'] = false
        this.totCharges = this.totalCharges()
        this.componentKey++
      },
      async confirmPayout(){
        this.loading = true
        const body = {
          Payouts : this.items,
          Penalties : this.penaltyData.filter( (i) =>{
            return i.selected === true
          }),
          PayoutDoneBy : this.$auth.user.email,
          token : this.bearerToken

        }
        console.log(body)
        try{
          const resp = await entymApi.post('Payouts/InitiateStripeMultiplePayout',body)
          if(resp.data.statusCode === 200){
            this.processingDone = true
            this.apiSuccess = true
            this.apiMessage = resp.data.message
          }
          if(resp.data.statusCode === 500){
            this.processingDone = true
            this.apiSuccess = false
            this.apiMessage = resp.data.message
          }
        }        
        catch(e){
          console.log('Catch block')
          console.log(e)
          this.processingDone = true
          this.apiSuccess = false
          this.apiMessage = 'Internal Processing error '
            
            }

            setTimeout(() => {
              this.$router.push({path:'/payouts-user'})
            },10000)
          },
          signinToFirebase(){
            const auth = getAuth();
            signInWithEmailAndPassword(auth, this.id, this.password)
            .then((userCredential) => {
                // Signed in 
                this.bearerToken = ''
                this.bearerToken = userCredential.user.accessToken;
                console.log(this.bearerToken)            
                this.isAuthenticated = true
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode,errorMessage)
                this.loginError =true
            });
          }
     
      }
            
      
    

}
</script>