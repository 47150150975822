<template>
    <v-expansion-panels multiple v-model="panel" accordion>
            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Summary</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-if="commonLoader" class="grid">
                        <v-progress-circular  indeterminate :size="50" color="primary"> </v-progress-circular>
                    </div>

                    <iframe
                    :src="metabaseDash"
                    frameborder="0"
                    style = "min-height:450px; width:100%;"

                    allowtransparency
                ></iframe>

                </v-expansion-panel-content>
                <!-- <v-expansion-panel-content>
                    <v-row v-if="commonLoader">
                        <v-col class="text-body-2">
                                <v-progress-linear
                                color="primary"
                                indeterminate
                                rounded
                            ></v-progress-linear>
                        </v-col> 
                    </v-row>

                    <v-row v-if="!commonLoader">
                            <v-col class="text-body-2">
                                Total Time slots booked:
                            </v-col>
                            <v-col class="text-body-2">
                                {{bookedSlotsCount}}
                            </v-col>


                            <v-col class="text-body-2">
                                Booking attempts
                            </v-col>
                            <v-col class="text-body-2">
                                {{attemmptedBookingsCount}}
                            </v-col>
                        </v-row>

                    <v-row v-if="!commonLoader">
                            <v-col class="text-body-2">
                                Rejected Bookings:
                            </v-col>
                            <v-col class="text-body-2">
                                {{rejectedBookingsCount}}
                            </v-col>


                            <v-col class="text-body-2">
                                Abandoned bookings:
                            </v-col>
                            <v-col class="text-body-2">
                                {{abandonedBookingsCount}}
                            </v-col>
                        </v-row>

                        <v-row v-if="!commonLoader">
                            <v-col class="text-body-2">
                                Cancelled bookings:
                            </v-col >
                            <v-col class="text-body-2">
                                Value
                            </v-col>
                            <v-col class="text-body-2">
                                No show bookings:
                            </v-col>
                            <v-col class="text-body-2">
                                Value
                            </v-col> 

                        </v-row>
                        
                </v-expansion-panel-content> -->
            </v-expansion-panel>

             <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Decision Pending Bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    hide-default-footer
                    :headers="decisionPendingHeader"
                    :items="decisionPendingContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

             <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Accepted Bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    hide-default-footer
                    :headers="acceptedHeader"
                    :items="acceptedContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Completed Bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    hide-default-footer
                    :headers="completedHeader"
                    :items="completedContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Rejected bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    hide-default-footer
                    :headers="rejectedHeader"
                    :items="rejectedContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Abandoned bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    hide-default-footer
                    :headers="abandonedHeader"
                    :items="abandonedContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Cancelled bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    hide-default-footer
                    :headers="cancelledHeader"
                    :items="cancelledContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">No show bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    hide-default-footer
                    :headers="noshowHeader"
                    :items="noshowContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

    </v-expansion-panels>
    
</template>

<script>
import {entymApi} from '@/axios'
export default {
    props: ['userId'],
    async created(){
        //Loader
        this.commonLoader = true

        const [BookingsDash,bookingResp] = await Promise.all(
        [  
            entymApi.get('Dashboard/UserBookingsDash',{params: {userid:this.userId}}),
            entymApi.get('EntymUser/bookings',{params: {EntymUserId: this.userId}})
        ]
        )
        this. metabaseDash = BookingsDash.data.url
        this.attemmptedBookingsCount = bookingResp.data.bookingAttemptsCount.count
        this.abandonedBookingsCount = bookingResp.data.abandonedBookingsCount.count
        this.rejectedBookingsCount = bookingResp.data.rejectedBookingsCount.count
        this.bookedSlotsCount = bookingResp.data.bookedSlotsCount.count
        this.decisionPendingContent = bookingResp.data.decisionPendingBookings
        this.acceptedContent = bookingResp.data.acceptedBookings
        this.completedContent = bookingResp.data.completedBookings
        this.abandonedContent = bookingResp.data.abandonedBookings
        this.rejectedContent = bookingResp.data.rejectedBookings

        //Stop Loader
        this.commonLoader = false

    },
    data(){
        return {
            panel: [0,1,2,3,4,5,6],
            commonLoader: false,
            attemmptedBookingsCount : 0 ,
            rejectedBookingsCount : 0,
            abandonedBookingsCount : 0 ,
            bookedSlotsCount :0 ,
            metabaseDash: '',            
            decisionPendingHeader: [
                { text: 'Host Name',align: 'start',sortable: false,value: 'hostName',},
                { text: 'Host Id',sortable: false,value: 'hostId',},
                { text: 'TimeSlotId',sortable: false,value: 'timeSlotId',},
                { text: 'Time', value: 'time' , sortable: false},
                { text: 'Duration (m)', value: 'duration', sortable: false },
                { text: 'Amount ', value: 'amount',sortable: false },
                { text: 'Mode', value: 'mode' ,sortable: false},
                { text: 'Status', value: 'status' ,sortable: true},
            ],
            decisionPendingContent: [],
            acceptedHeader: [
                { text: 'Host Name',align: 'start',sortable: false,value: 'hostName',},
                { text: 'Host Id',sortable: false,value: 'hostId',},
                { text: 'TimeSlotId',sortable: false,value: 'timeSlotId',},
                { text: 'Time', value: 'time' , sortable: false},
                { text: 'Duration (m)', value: 'duration', sortable: false },
                { text: 'Amount', value: 'amount',sortable: false },
                { text: 'Mode', value: 'mode' ,sortable: false},
                { text: 'Status', value: 'status' ,sortable: true},
            ],
            acceptedContent: [],
            completedHeader: [
                { text: 'Host Name',align: 'start',sortable: false,value: 'hostName',},
                { text: 'Host Id',sortable: false,value: 'hostId',},
                { text: 'TimeSlotId',sortable: false,value: 'timeSlotId',},
                { text: 'Time', value: 'time' , sortable: false},
                { text: 'Duration (m)', value: 'duration', sortable: false },
                { text: 'Amount', value: 'amount',sortable: false },
                { text: 'Mode', value: 'mode' ,sortable: false},
                { text: 'Status', value: 'status' ,sortable: true},
            ],
            completedContent: [],
            abandonedHeader: [
                { text: 'Host Name',align: 'start',sortable: false,value: 'hostName',},
                { text: 'Time', value: 'time' , sortable: false},
                { text: 'Duration (m)', value: 'duration', sortable: false },
                { text: 'Amount ($)', value: 'amount',sortable: false },
                { text: 'Mode', value: 'mode' ,sortable: false},
            ],
            abandonedContent: [],
            rejectedHeader: [
                { text: 'Host Name',align: 'start',sortable: false,value: 'hostName',},
                { text: 'Time', value: 'time' , sortable: false},
                { text: 'Duration (m)', value: 'duration', sortable: false },
                { text: 'Amount ($)', value: 'amount',sortable: false },
                { text: 'Mode', value: 'mode' ,sortable: false},
            ],
            rejectedContent: [],
            cancelledHeader: [
                { text: 'Host Name',align: 'start',sortable: false,value: 'hostName',},
                { text: 'Time', value: 'time' , sortable: false},
                { text: 'Duration (m)', value: 'duration', sortable: false },
                { text: 'Amount ($)', value: 'amount',sortable: false },
                { text: 'Mode', value: 'mode' ,sortable: false},
            ],
            cancelledContent: [
            ],

            noshowHeader: [
                { text: 'Host Name',align: 'start',sortable: false,value: 'hostName',},
                { text: 'Time', value: 'time' , sortable: false},
                { text: 'Duration (m)', value: 'duration', sortable: false },
                { text: 'Amount ($)', value: 'amount',sortable: false },
                { text: 'Mode', value: 'mode' ,sortable: false},
            ],
            noshowContent: [
            ]
        }
    }
}
</script>

<style scoped>
.grid{
  display: grid;
  place-items: center;
}

</style>