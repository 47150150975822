<template>
    <dashboard-layout :links="links">
      <v-container  style="width:100%;">

        <v-overlay v-if="loading"
          :model-value="overlay"
          class="align-center justify-center"
        >
        <v-progress-circular
          indeterminate
          color="primary"
          
          class="align-center justify-center"
        ></v-progress-circular>
        </v-overlay>

        <v-snackbar
        timeout="3000"
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackbar = false"
          
          
        >
        <v-icon> mdi-close-circle</v-icon>
        </v-btn>
      </template>
      </v-snackbar>


      
      <v-snackbar
      v-model="validationSnackbar"
      timeout="2000"
      color="red"

    >
      Please enter required data
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="validationSnackbar = false"
        >
          <v-icon> mdi-close-circle</v-icon>
        </v-btn>
      </template>
      </v-snackbar>

        <v-sheet elevation="1">       
        <v-row >
            <v-col cols="6" class="pl-6">
              <v-row>  <v-col >  <h4>  Table Name : {{ dataObj.Tablename }} [Entered by User] </h4> </v-col> </v-row>
              <v-row> <v-col> <p class="text-body-2"> Added by :   <router-link :to="toUserPage"> {{ dataObj.Emailid }} </router-link> </p>  </v-col> </v-row>

                  <v-row v-for="(i,index) in dataObj.Data" :key="index">
                      <v-col class="text-body-1" cols="3" > {{ index }} : </v-col> <v-col cols="4" > {{ i  }} </v-col> 
                  </v-row>


              <v-row class="text-body-2" > <v-col cols="3">Added on :</v-col> <v-col cols="4"> {{ new Date(dataObj.Timestamp.toString()) }}</v-col>  </v-row>

            </v-col>

            <v-divider inset vertical></v-divider>

            <v-col cols="6">

              <v-row> <v-col> <strong>   Add {{ dataObj.Tablename }}  to database </strong> </v-col></v-row>
              

              
              <v-form v-if="dataObj.Tablename === 'Skills'" ref="form" v-model="valid" @submit.prevent >
                <v-text-field
                  v-model="skillsdata.name"                  
                  label="Skill"
                  :rules="rules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="skillsdata.description"                  
                  label="Skill Description"
                  
                ></v-text-field>
                <v-text-field
                  v-model="skillsdata.abbv"                  
                  label="Skill Abbreviation"                  
                ></v-text-field>

                <v-text-field
                  v-model="skillsdata.category"                  
                  label="Skill Category Name"
                  required
                ></v-text-field>

                <v-btn :disabled="buttonDisabled" width="25%" type="submit"  @click="submit('Skills')"> Add </v-btn>
              </v-form>

            

              <v-form v-else-if="dataObj.Tablename === 'Degrees'" ref="form" v-model="valid" @submit.prevent>
                <v-text-field
                  v-model="degreesdata.degreename"
                  :rules="rules"                  
                  label="Degree Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="degreesdata.degreeshortname"
                  label="Degree Short Name"
                  
                ></v-text-field>

                <v-text-field
                  v-model="degreesdata.degreeabbv"
                  :rules="rules"    
                  required              
                  label="Degree Abbreviation"
                  
                ></v-text-field>
                <v-text-field
                  v-model="degreesdata.category"
                  label="Category"
                  
                ></v-text-field>

                
                <v-btn :disabled="buttonDisabled" width="25%" type="submit"  @click="submit('Degrees')"> Add </v-btn>
              </v-form>

              <v-form v-else-if="dataObj.Tablename === 'EduInstitues'" ref="form" v-model="valid" @submit.prevent >
                <v-text-field
                  v-model="edudegreesdata.name"
                  :rules="rules"                  
                  label="Institute Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="edudegreesdata.locationabbv"
                  label="Location Abbreviation"                  
                ></v-text-field>

                <v-text-field
                  v-model="edudegreesdata.website"
                  label="Website"                  
                ></v-text-field>

                
                <v-btn :disabled="buttonDisabled" width="25%" type="submit"  @click="submit('EduInstitues')"> Add </v-btn>
              </v-form>

              <v-form v-else-if="dataObj.Tablename === 'Hobbies'" ref="form" v-model="valid" @submit.prevent>
                <v-text-field
                  v-model="hobbydata.name"
                  :rules="rules"
                  
                  label="Hobby Name"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="hobbydata.category"
                  :rules="rules"
                   required
                  label="Cateogy"
                  
                ></v-text-field>
                
                <v-btn :disabled="buttonDisabled" width="25%" type="submit"  @click="submit('Hobbies')"> Add </v-btn>
              </v-form>

              <v-form v-else-if="dataObj.Tablename === 'Companies'" ref="form" v-model="valid" @submit.prevent>
                
                <v-text-field
                  v-model="companydata.companyname"
                  :rules="rules"                  
                  label="Company Name"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="companydata.companyindustry"
                  label="Company Industry"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="companydata.companylocality"
                  label="Company Locality"
                  
                ></v-text-field>

                <v-text-field
                  v-model="companydata.companywebpage"
                  label="Webpage"
                  
                ></v-text-field>
                <v-text-field
                  v-model="companydata.conpanylinkedinUrl"
                  label="LinkedIn URL"
                  
                ></v-text-field>
                <v-text-field
                  v-model="companydata.conpanysizeRange"
                  label="Size Range"
                  
                ></v-text-field>
                <v-text-field
                  v-model="companydata.yearfounded"
                  label="Year Founded"
                
                ></v-text-field>

                <v-btn :disabled="buttonDisabled" width="25%" type="submit"  @click="submit('Companies')"> Add </v-btn>
              </v-form>

            </v-col>
          
        </v-row>
        </v-sheet>
    </v-container>

    </dashboard-layout>
  
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import { entymApi } from '@/axios'
export default {
    components: {DashboardLayout},
    computed: { 
      links() {
        return this.$store.state.links
      },
      toUserPage(){
        return '/users/' + this.dataObj.Entymuserid
      }
    },
    data(){
      return{
        dataObj: null,
        validationSnackbar : false,
        valid : false,
        loading: false,
        overlay: false,
        snackbar: false,
        buttonDisabled: false,
        snackbarText: '',
        snackbarColor: 'primary',
        rules: [
        value => {
          if (value) return true
          return 'This value is required'
        },
      ],
        hobbydata: {
          name: '',
          category: ''
        },
        skillsdata : {
          name: '',
          description : '',
          abbv : '',
          category: ''
        },
        degreesdata : {
          degreename: '',
          degreeabbv: '',
          category : '',
          degreeshortname : ''
        },
        edudegreesdata:{
          name: '',
          locationabbv: '',
          website: ''
        },
        companydata : {
          companyindustry: '',
          companylocality : '',
          companyname : '',
          companywebpage : '',
          conpanylinkedinUrl : '',
          conpanysizeRange : '',
          yearfounded : ''
        }
        
      }
      

    },

    created(){
      this.dataObj = this.$route.params.obj
      //Route to globaldata main page if no object
      if(!this.dataObj) {
        this.$router.push({name:'globaldata'})  }     
      
    },

    methods : {
      async submit(type){
        //const isValid = this.$refs.form.validate()
        if(!this.valid){
          console.log('Validation error')
          this.validationSnackbar = true
          return false
        }

        this.loading = true
        this.overlay = true

        var reqbody = {
          tempglobaldataid: this.dataObj.Id,
          reviewedby: this.$auth.user.email,
        }
        try{
          switch(type){
            case 'Skills':{   
              reqbody.type = "Skills"
              reqbody.skills =  this.skillsdata        
                const resp = await entymApi.post('Dashboard/AddGlobalData', reqbody)
                console.log(resp)
              }           
              break;

            case 'Degrees' :{
              reqbody.type = "Degrees"
              reqbody.degrees = this.degreesdata
              const resp = await entymApi.post('Dashboard/AddGlobalData', reqbody)
              console.log('From degrees')
              console.log(resp)
            }
            break;

            case 'EduInstitues' :{
              reqbody.type = "EduInstitues"
              reqbody.eduinstitutes = this.edudegreesdata
              const resp = await entymApi.post('Dashboard/AddGlobalData', reqbody)
              console.log('From Edu Institutes')
              console.log(resp)
            }
            break;

            case 'Hobbies' :{
              reqbody.type = "Hobbies"
              reqbody.hobbies = this.hobbydata
              const resp = await entymApi.post('Dashboard/AddGlobalData', reqbody)
              console.log('From Hobbies')
              console.log(resp)

            }
            break;

            case 'Companies' :{
              reqbody.type = "Companies"
              reqbody.companies  = this.companydata
              const resp = await entymApi.post('Dashboard/AddGlobalData', reqbody)
              console.log('From Companies')
              console.log(resp)

            }
            break;

          default:
            break;

        }
        //Add snackbar text and redirect back
        this.buttonDisabled = true
        this.snackbarText = 'Entry  Added'
        this.snackbarColor = 'green'

        

        }
        catch(e){
          this.snackbarText = 'Error Occured'
          this.snackbarColor = 'red'
          
          console.log(e)
        }
        finally{
          //Stop loader and 
        this.loading = false
        this.overlay = false
        this.snackbar = true

        setTimeout(()=>{
          this.$router.push({name:'globaldata'})
        },3000)

        }


        

      },
      

    }


}
</script>

<style>

</style>