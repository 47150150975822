<template>
<v-container>
  <app-bar> 

  </app-bar>
    <div class="mt-12">
    <div>
      <img :src="$auth.user.picture" />
      <h2>{{  $auth.user.name }}</h2>
      <p>{{ $auth.user.email }}</p>
    </div>

    <div>
      <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
    </div>
  </div>

</v-container>


  

</template>


<script>
import AppBar from '@/layouts/AppBar.vue'
export default {
    components : {AppBar}

}

</script>