<template>
   <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-form @submit.prevent="onClickLogin">
              <v-card class="elevation-12">
                <v-toolbar dark color="pink">
                  <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="id"
                    prepend-icon="person"
                    name="login"
                    label="Login"
                    :rules="rules.id"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    prepend-icon="lock"
                    name="password"
                    label="Password"
                    :rules="rules.password"
                    :append-icon="value ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- Check that the SDK client is not currently loading before accessing is methods -->
                  <div v-if="!$auth.loading">
                  <v-btn v-if="!$auth.isAuthenticated" type="submit" @click="onClickLogin" color="pink"
                    >Login</v-btn
                  >
                  </div>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
</template>

<script>
import LoginOrigin from "@/components/LoginOrigin";

export default {
  name: "LoginView",
  extends: LoginOrigin,
  data(){
    return {
      value: String,
      id: "",
      password: "",

    }
  },
  methods: {
    // Log the user in
    onClickLogin() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>

<style>

</style>