<template>
    <v-expansion-panels multiple v-model="panel" accordion>
            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Summary</v-expansion-panel-header>
                <p>Coming Soon</p>
                <!-- <v-expansion-panel-content>
                    <v-row v-if="summaryLoader">
                        <v-col class="text-body-2">
                                <v-progress-linear
                                color="primary"
                                indeterminate
                                rounded
                            ></v-progress-linear>
                        </v-col> 
                    </v-row>

                    <v-row v-if="!summaryLoader">
                            <v-col class="text-body-2">
                                Total Money Earned:
                            </v-col>
                            <v-col class="text-body-2">
                                TODO 
                            </v-col>


                            <v-col class="text-body-2">
                                Money Spent:
                            </v-col>
                            <v-col class="text-body-2">
                                TODO
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" class="text-body-2">
                                Penalties paid:
                            </v-col >
                            <v-col cols="3" class="text-body-2">
                                TODO
                            </v-col>


                        </v-row>
                        
                </v-expansion-panel-content> -->
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Earnings History</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    hide-default-footer
                    :headers="earningsHeader"
                    :items="earningsContent"
                    item-key="name"
                    :loading="EarningsHistoryLoader"
                    class="elevation-1">
                    <template  v-slot:[`item.recieptUrl`]="{ item }">
                        <a target="_blank" :href="item.recieptUrl">
                            {{ item.recieptUrl }}
                        </a>
                    </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Payments History</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    hide-default-footer
                    :headers="paymentsHeader"
                    :items="paymentsContent"
                    item-key="name"
                    :loading="PenaltyHistoryLoader"
                    class="elevation-1"
                    >
                    <template  v-slot:[`item.recieptUrl`]="{ item }">
                        <a target="_blank" :href="item.recieptUrl">
                            {{ item.recieptUrl }}
                        </a>
                    </template>
                    </v-data-table>

                </v-expansion-panel-content>
            </v-expansion-panel>

            

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Pending Payouts</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    hide-default-footer
                    :headers="pendingPayoutsHeader"
                    :items="pendingPayoutsContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Completed Payouts</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    hide-default-footer
                    :headers="completedPayoutsHeader"
                    :items="completedPayoutsContent"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

    </v-expansion-panels>
</template>

<script>
import {entymApi} from '@/axios'
export default {
    props: ['userId'],
    async created(){
        //Loader
        this.EarningsHistoryLoader = true
        this.PenaltyHistoryLoader = true
        this.summaryLoader = true

        const paymentsResp = await entymApi.get('EntymUser/payments',{params: {EntymUserId: this.userId}})
        console.log(paymentsResp.data)
        this.totalMoneyEarned = paymentsResp.data.totalMoneyEarned.sum
        this.totalMoneySpent = paymentsResp.data.totalMoneySpent.sum
        this.earningsContent = paymentsResp.data.earningHistory
        this.paymentsContent = paymentsResp.data.spendingHistory
        this.pendingPayoutsContent = paymentsResp.data.payoutsPending
        this.completedPayoutsContent = paymentsResp.data.payoutsCompleted
        // Loader stop
        this.summaryLoader = false
        this.EarningsHistoryLoader = false
        this.PenaltyHistoryLoader = false

    },
    data(){
        return {
            panel: [0,1,2,3,4,5,6],
            toalMoneySpent: 0,
            totalMoneyEarned: 0,
            summaryLoader : false,
            EarningsHistoryLoader: false,
            PenaltyHistoryLoader: false,
            earningsHeader: [
                { text: 'TimeSlotid',align: 'start',sortable: false,value: 'timeSlotId',},
                { text: 'Amount',sortable: false,value: 'amount',},
                { text: 'Currency', value: 'currency' , sortable: false},
                { text: 'Paymentmethod', value: 'paymentmethod' , sortable: false},
                { text: 'RecieptUrl', value: 'recieptUrl',sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                
            ],
            earningsContent: [],
            paymentsHeader: [
                { text: 'TimeSlotid',align: 'start',sortable: false,value: 'timeSlotId',},
                { text: 'Amount',sortable: false,value: 'amount',},
                { text: 'Currency', value: 'currency' , sortable: false},
                { text: 'Paymentmethod', value: 'paymentmethod' , sortable: false},
                { text: 'RecieptUrl', value: 'recieptUrl',sortable: false },
                { text: 'Status', value: 'status', sortable: false },
            ],
            paymentsContent: [],

            pendingPayoutsHeader: [
                { text: 'TimeSlotid',align: 'start',sortable: false,value: 'timeSlotId',},
                { text: 'Amount',align: 'start',sortable: false,value: 'amount',},
                { text: 'Currency', value: 'currency' , sortable: false},
                { text: 'Status', value: 'status', sortable: false },
            ],
            pendingPayoutsContent: [],
            completedPayoutsHeader: [
                { text: 'TimeSlotid',align: 'start',sortable: false,value: 'timeSlotId',},
                { text: 'Amount',align: 'start',sortable: false,value: 'amount',},
                { text: 'Currency', value: 'currency' , sortable: false},
                { text: 'Status', value: 'status', sortable: false },
            ],
            completedPayoutsContent: []

        }
    }
}
</script>

<style>

</style>