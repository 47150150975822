<template>
    <dashboard-layout :links="links">
      <v-card>
        
        <v-data-table
          :headers="headers"
          :items="usersContent"
          :loading="loading"
          :search="search"
          @click:row="handleRowClick"
          >
          
          <template v-slot:top>
              <v-toolbar class="primary" dark flat rounded="2">
                <v-toolbar-title> Users </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
          </template>
          </v-data-table>
    </v-card>
  </dashboard-layout>
  
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import {entymApi} from '@/axios'

export default {
  components: { DashboardLayout},
  computed: { 
      links() {
        return this.$store.state.links
      },
    },
    async created(){
      this.loading = true
        var resp = await entymApi.get('EntymUser/GetUsers')
        this.usersContent = resp.data

        this.loading = false

        
    },
    methods: {
      handleRowClick(event){
        let path = '/users/' + event.entymUserId 
        this.$router.push({path:path})
      },
    },

    data () {
      return {
        loading : false,
        headers: [
          {text: 'User Id',align: 'start',sortable: false,value: 'entymUserId',width: '20%'},
          { text: 'Name', value: 'name',sortable: false, },
        ],
        usersContent: [{}],
        search : '',
      }
    },
}
</script>

<style>

</style>

