<template> 
    <finance-layout>
      <v-alert v-if="paymentThroughReward"
        type="warning"
        prominent
        title="This slot  was completed through rewards"
        text="">
       
        This slot  was completed through rewards
      </v-alert>

    <v-container  fluid >
      <div  v-if="mainLoader" class="d-flex justify-center align-center">
        <v-progress-circular              
          :size="70"
          color="primary"
          indeterminate
          :width="10"
        ></v-progress-circular>
      </div>
        
        <v-data-iterator
        v-else
          :items="items"
          :loading="mainLoader"
          hide-default-footer>
          <template v-slot:header>
            <v-toolbar
              class="mb-2"
              color="accent"
              dark
              flat
            >
            
              <v-toolbar-title>Confirm Payout</v-toolbar-title>
            </v-toolbar>
            
          </template>

          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="(item,i) in props.items"
                :key="item.name"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <v-card>
                  <v-card-title >
                    Timeslot-Id : {{ item.Timeslotid }}
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-list dense>
                    <!-- <v-list-item>
                      <v-list-item-content>Guest:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.calories }}
                      </v-list-item-content>
                    </v-list-item> -->

                    <v-list-item>
                      <v-list-item-content>Time:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.Timeslotdata }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Amount:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.Amount}} {{item.Amountcurrency}}
                      </v-list-item-content>
                    </v-list-item>

                    <v-row v-if="showLocationAlert" class="mx-2 my-2">
                      <v-alert 
                        type="warning"
                        prominent >
                      
                        Location info not found
                      </v-alert>
                    </v-row>
                    <v-row  class="mx-2 my-2" v-else>
                      <div class="d-inline-flex">
                        <div  v-for="(j,int) in locations" :key="int">
                          <location-component :title="j.title" :location="j.location"> </location-component>
                        </div>
                        
                      </div>
                     
                      

                    </v-row>
                      
                                        
                    
                  <v-card class="mx-2">
                  <v-card-title class="ml-3 mt-2 subtitle-1"> Charges : </v-card-title>
                  <v-card-text>
                    <ol>                
                      <li v-for="(k, index) in charges" :key="index">
                        
                        <div  class="d-inline-flex">
                          <div class="mx-2 body-1  grey--text text--darken-4"> {{ k.Charge}} </div>
                          <div class="mx-2 body-1"> {{ k.Amount}}</div>
                        </div> 
                    </li>
                  </ol>  
                  </v-card-text>
                  </v-card>
                    
                    <v-list-item v-if="!showDropdown">                                  
                            <v-list-item-content class="align-end">
                                <v-btn icon @click="addRow(item.Userpayoutsid)"> 
                                  Add Charge  
                                <v-icon small right>mdi-plus-circle</v-icon>
                                    
                                </v-btn> 
                            </v-list-item-content>

                            <v-list-item-content class="align-end">
                                <v-btn  icon @click="deleteRow(item.Userpayoutsid)"> 
                                  Delete row 
                                    <v-icon small right>mdi-minus-circle</v-icon>
                                    
                                </v-btn> 
                            </v-list-item-content>                    
                    </v-list-item>

                  <div v-if="showDropdown">             
                    <v-select
                      class="mx-4"
                      :items="AddRowSelectOptions"
                      :menu-props="{ top: false, offsetY: true }"
                      label="Select the type of charge"
                      @change="AddRowOptionSelected"
                    ></v-select>
                      
                    <div>
                        <component v-bind:is="selectedComponent" v-on:percentInputSelect="handlepercentInputSelected" v-on:NumberInputSelect="handleNumberInputSelect" v-on:CustomInputSelect="handleCustomInputSelect"></component>
                    </div>
                  </div>
                    

                <v-list-item>
                  <v-list-item-content>Charges :</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <span>
                        {{individualCharges(charges)}}

                    </span>

                  </v-list-item-content>                    
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Amount to be paid :</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <span> {{individualAmount(charges,i)}}</span>
                    <!-- <v-text-field
                    label="Amount"i
                    v-model="totalPaymentAmount"
                    solo
                    readonly
                    ></v-text-field> -->
                  </v-list-item-content>
                </v-list-item>

                  </v-list>

                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-toolbar
              class="mt-2 d-flex justify-center"
              flat
              color="accent"
            >
              <v-toolbar-title class="subheading">            
              </v-toolbar-title>

              <v-toolbar-items >
                <v-list-item class="pa-0">
                  <v-list-item-content  class="my-auto"> 
                    <v-text-field
                      class="align-centre"
                      :value="totalPaymentAmount()"
                      label="Total Amount"
                      solo
                      readonly
                    
                    >  </v-text-field>
                    
                    </v-list-item-content>
                  <v-list-item-content class="align-end my-auto">
                    <confirm-dialog :payouts="items" :charges="charges">  </confirm-dialog>

                  </v-list-item-content>                    
                </v-list-item>

                
            </v-toolbar-items>
            </v-toolbar>
          </template>
        </v-data-iterator>
    
  </v-container>

  </finance-layout>
</template>
<script>
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import ConfirmDialog from '@/components/PayoutComponents/PayoutsConfirmDialog.vue'
import PercentageInput from '@/components/PayoutComponents/V2/PercentageInput.vue'
import NumberInput from '@/components/PayoutComponents/V2/NumberInput.vue'
import CustomInput from '@/components/PayoutComponents/V2/CustomInput.vue'
import LocationComponent from '@/components/PayoutComponents/V2/LocationComponent.vue'
import { nocodb, entymApi } from '@/axios'
export default {
  components: {
    FinanceLayout,
    ConfirmDialog,
    PercentageInput,
    NumberInput,
    CustomInput,
    LocationComponent
},
  props: ['Id'],

    data(){
        return {

            chargeName1: '',
            chargeAmount1: 0.00,
            chargeName2: '',
            chargeAmount2: 0.00,
            Amount : 0,
            items: [],
            amounts: [],
            charges: [],
            mainLoader: false,
            componentKey: 0,
            AddRowSelectOptions: ["Percentage Charge","Number Charge","Custom Charge"],
            selectedComponent: {},
            showDropdown: false,
            locations : [{title: "Geo Location" , location : {  country : "", city : "",  }},
            {title: "User Provided Location" , location : {  country : "", city : "",  }},
            {title: "Stripe Location" , location : {  country : "", city : "",  }}

          ],
          paymentThroughReward: false,
          showLocationAlert: false

        }
    },
  async created(){
      this.mainLoader = true

      
      // Get the query params
        const idsquery = this.Id
        var resp = await nocodb.get('Userpayouts',{params: {where: `(Userpayoutsid,in,${idsquery})~and(Status,eq,payoutpending)`}})       
        
        this.items = resp.data.list
        
        
        //Get the locations
        
        try{

          const locationsResp = await entymApi.get('/Payouts/GetTransactionLocations',{params: {'UserPayoutsId': this.Id}})
          console.log("Location Resp", locationsResp.data)
          if(locationsResp.data.isRewards == true){
              this.paymentThroughReward = true
            }
          
            //Update Stripe Location
            if(locationsResp.data.stripeLocation){
              const stripeIndex = this.locations.findIndex(x => x.title == 'Stripe Location');
              this.locations[stripeIndex].location = locationsResp.data.stripeLocation;
            }
            
            //Update GeoLocation
            if(locationsResp.data.geoLocation){
              const geoLocationIndex = this.locations.findIndex(x => x.title == 'Geo Location')
              this.locations[geoLocationIndex].location = locationsResp.data.geoLocation;
            }
            
            //Update User Provided Location
            if(locationsResp.data.userProvidedLocation){
              const userLocationIndex = this.locations.findIndex(x => x.title == 'User Provided Location')
              this.locations[userLocationIndex].location = locationsResp.data.userProvidedLocation;
            }
          
        }
        catch(e){
          this.showLocationAlert = true
        }

        
                        

        this.items.forEach((element,index) => {
          //Seed charges array if needed
          const Charges =  [
            
            
            ]
          //this.charges.push(Charges)
          this.items[index].Charges = Charges
        })
        this.mainLoader = false

    },
    methods : {
      // When charge type dropdown is called 
      AddRowOptionSelected(selectedOption){
          if (selectedOption === 'Percentage Charge') {
              this.selectedComponent = "PercentageInput"
          } else if (selectedOption === 'Number Charge') {
              this.selectedComponent = "NumberInput"
          }
          else if(selectedOption === 'Custom Charge'){
            this.selectedComponent = "CustomInput"
          }

        },
      // Handles event emitted from child
      handlepercentInputSelected(eventData){
        //Add the charge to Charges Object in Items array

        //Get ChargeId 
        const ChargeId = this.charges.length + 1
        //Calculate Amount 
        const Amount = this.items[0]['Amount'] * eventData.ChargePercent / 100
        this.charges.push({ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount})
        //this.$set(this.items[0].Charges, ChargeId, "asdasd");
        this.showDropdown = false

      },
      handleNumberInputSelect(eventData){
        console.log(eventData)
        const ChargeId = this.charges.length + 1
        const Amount = eventData.ChargeAmount 
        this.charges.push({ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount})
        this.showDropdown = false
      },
      handleCustomInputSelect(eventData){
        const ChargeId = this.charges.length + 1
        const Amount = eventData.ChargeAmount 
        this.charges.push({ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount})
        this.showDropdown = false
      },
      individualCharges(charges){
        var sum = 0 
        charges.forEach(element => {
          if(!Number.isNaN(parseFloat(element.Amount))  ){
            sum += parseFloat(element.Amount)
          }
        })

        return sum
          // Old Logic       
          // const charge = parseFloat(charge1) + parseFloat(charge2)
          // return Number.isNaN(charge) ? 0 : charge
      },
      individualAmount(charges,index){
      
        var sum = 0     
        charges.forEach(element => {
          if(!Number.isNaN(parseFloat(element.Amount))  ){
            sum += parseFloat(element.Amount)
          }
        })

        const amount = parseFloat(this.items[index].Amount) - sum
        this.items[index].paymentAmount = amount
        // const obj = { 
        //           index : index,
        //           amount : amount
        //       }
        // this.amounts.push(obj)
        return Number.isNaN(amount) ? 0 : amount 
        

          // var amount = 0 
          // try{
          //     //TODO: Check currency before adding
          //     amount=  parseFloat(this.items[index].Amount) - parseFloat(charge1) - parseFloat(charge2)
          //     this.items[index].paymentAmount = amount
          //     // const obj = { 
          //     //     index : index,
          //     //     amount : amount
          //     // }
          //     // this.amounts.push(obj)
          // }
          // catch(e){
          //     console.log(e)
          // }            
          // return Number.isNaN(amount) ? 0 : amount 
      },
      totalPaymentAmount(){
          let sum = 0
          this.items.forEach(element => {
              if(element.paymentAmount && !Number.isNaN(element.paymentAmount)){
                  sum += parseFloat(element.paymentAmount)
              }
          })
          return sum
      },
      addRow(userpayoutsid){
        // Find the right object in the array
        this.items.find((o,i) => {
          if(o.Userpayoutsid === userpayoutsid){

            //Get new chargeid
            
            const chargeId = this.items[i].Charges.length + 1
            const charge = { ChargeId: chargeId , Charge: '', Amount :0}
            //this.items[i].Charges.push(charge)
            this.$set(this.items[i].Charges, this.items[i].Charges.length , charge)
            //this.$router.go(0);
            //const length = this.items[i].Charges.length
            //this.componentKey = this.componentKey + 1 ;
            //console.log(this.componentKey)
            
            this.$set(this.items[i],'charge'+chargeId, true);
            this.showDropdown = true

          }

      });
      this.componentKey = this.componentKey + 1 ;
      },
      deleteRow(userpayoutsid){
        console.log('delete charge ',userpayoutsid)
        this.charges.pop()
        // this.items.find((o,i) => {
        //   if(o.Userpayoutsid === userpayoutsid){
        //     this.items[i].Charges.pop()

        //     const chargeId = this.items[i].Charges.length + 1
        //     //delete this.items[i]['charge'+chargeId];
        //     this.$delete(this.items[i],'charge'+chargeId);             
        //   }
            
      //});

      }
        
    }
}
</script>