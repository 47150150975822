<template>
  <div>
        <v-navigation-drawer v-model="drawer" clipped app color="primary">
            <v-list>
              
                <!-- <v-list-item v-if="$auth.isAuthenticated" to="/profile">
                <v-list-item-action>
                    <v-icon class="white--text"></v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="white--text">Profile</v-list-item-title>
                </v-list-item-content>
                </v-list-item> -->

                <!-- v-list-tile was replaced to v-list-item at Vuetify 2.0 -->
                <v-list-item v-for="(link,index) in PayoutsStore.navLinks" :key="index" router :to="link.route">
                <v-list-item-action>
                    <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <AppBar @drawerFromChild="drawer = !drawer" />

        <!-- Sizes your content based upon application components -->
        <v-main mx-4>

            <!-- Provides the application the proper gutter -->
            <v-container fluid>

            <!-- If using vue-router -->
            <slot/>
            
            </v-container>
        </v-main>

        <v-footer app>
            <!-- -->
        </v-footer>

        <v-overlay
      :opacity="1"
      :value="overlay"
    >
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import AppBar from './AppBar.vue'
import {mapState} from 'vuex'

export default {
    components : {
        AppBar
    },
    data() {
        return {
            overlay: true,
            drawer: true,
            group: null,
        }

    },
    watch: {
      group () {
        this.drawer = false
      },
    },
    methods: {
       
    },
    mounted() {
      // hide the overlay when everything has loaded
      // you could choose some other event, e.g. if you're loading
      // data asynchronously, you could wait until that process returns
      this.overlay = false
    },
    computed : {
        ...mapState(['PayoutsStore'])
    } 
}
</script>

<style>

</style>