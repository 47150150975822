<template>
  <v-row>
      <v-expansion-panels multiple v-model="panel" accordion>
          <v-expansion-panel>            
              <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Info</v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-row v-if="personalInfoLoader">
                        <v-col class="text-body-2">
                                <v-progress-linear
                                color="primary"
                                indeterminate
                                rounded
                            ></v-progress-linear>
                        </v-col>
                </v-row> 
                <v-container v-if="!personalInfoLoader">
                    <v-row no-gutters> 
                      <v-col cols="1" class="text-body-2" >Headline:</v-col>
                      <v-col class="text-body-2"> {{personalInfo.Bio}}</v-col>
                  </v-row>
                  <v-row no-gutters>
                      <v-col cols="1" class="text-body-2">Bio:</v-col>
                      <v-col class="text-body-2"> {{personalInfo.Headline}}</v-col>
                  </v-row>

                </v-container>
                  

              </v-expansion-panel-content>
          </v-expansion-panel>
          
          <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Locations</v-expansion-panel-header>
              <v-expansion-panel-content>
                  <v-data-table
                    dense
                    hide-default-footer
                    :headers="locationHeader"
                    :loading="personalInfoLoader"
                    :items="personalInfoData.locations"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>

              </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Work Experience</v-expansion-panel-header>
              <v-expansion-panel-content>
                  <v-data-table
                    dense
                    hide-default-footer
                    :headers="workexperienceHeaders"
                    :loading="personalInfoLoader"
                    :items="personalInfoData.workExperience"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>

              </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Degrees</v-expansion-panel-header>
              <v-expansion-panel-content>

                  <v-data-table
                    dense
                    hide-default-footer
                    :headers="degreeHeaders"
                    :loading="personalInfoLoader"
                    :items="personalInfoData.degrees"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>

              </v-expansion-panel-content>
          </v-expansion-panel>
          
          <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Schools</v-expansion-panel-header>
              <v-expansion-panel-content>
                  <v-data-table
                    dense
                    hide-default-footer
                    :headers="schoolsHeader"
                    :loading="personalInfoLoader"
                    :items="personalInfoData.schools"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>

              </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Skills</v-expansion-panel-header>
              <v-expansion-panel-content>
                  
                  <v-data-table
                    dense
                    hide-default-footer
                    :loading="personalInfoLoader"
                    :headers="skillsHeader"
                    :items="personalInfoData.skills"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>

              </v-expansion-panel-content>
          </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Hobbies</v-expansion-panel-header>
              <v-expansion-panel-content>
                 
                  <v-data-table
                    dense
                    hide-default-footer
                    :loading="personalInfoLoader"
                    :headers="hobbiesHeader"
                    :items="personalInfoData.hobbies"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                    
              </v-expansion-panel-content>
          </v-expansion-panel>

      </v-expansion-panels>
  </v-row>
</template>

<script>
export default {
    props: ['personalInfo','personalInfoData','personalInfoLoader'],
    computed: {},
    methods: {},
    data(){
        return {
            panel: [0,1,2,3,4,5],
            locationHeader: [
                { text: 'Location',align: 'start',sortable: false,value: 'location',},
                
                
            ],
            workexperienceHeaders: [
                { text: 'Company Name',align: 'start',sortable: false,value: 'companyName',},
                { text: 'Job Title', value: 'jobTitle' , sortable: false},
                { text: 'From', value: 'from', sortable: false },
                { text: 'To', value: 'to',sortable: false },
                { text: 'Experience', value: 'jobExperience' ,sortable: false}
                
            ],
            degreeHeaders: [
                { text: 'Degree Name',align: 'start',sortable: false,value: 'degreeName',},
                { text: 'Category', value: 'categoryName' , sortable: false}
            ],
            schoolsHeader: [
                { text: 'School Name',align: 'start',sortable: false,value: 'schoolName',},
                { text: 'From',align: 'start',sortable: false,value: 'from',},
                { text: 'To',align: 'start',sortable: false,value: 'to',},
                { text: 'Location', value: 'location' , sortable: false}
            ],
            
            skillsHeader: [
                { text: 'Name',align: 'start',sortable: false,value: 'name',},
                { text: 'Description', value: 'description' , sortable: false},
                { text: 'Cateogry', value: 'cateogry' , sortable: false}
            ],
            hobbiesHeader: [
                { text: 'Name',align: 'start',sortable: false,value: 'name',},
                { text: 'Cateogry', value: 'cateogry' , sortable: false}
            ],
                        
            
        }
    }
    
}
</script>

<style>

</style>