<template>
    
    <dashboard-layout :links="links">
        
        <v-card>
             <v-toolbar color="primary"> <v-toolbar-title>Send Notifications</v-toolbar-title> </v-toolbar>
            <h2> </h2>
        <v-list>
            <v-list-item-group v-model="model" >
                
                <!-- <v-list-item  @click="$router.push({ path: '/sendnotifications/all'})"> 
                    <v-list-item-content>
                <v-list-item-title > Send to all users </v-list-item-title>
              </v-list-item-content>                    
                 </v-list-item> -->


                <v-list-item @click="$router.push({ path: '/sendnotifications/select'})"> 
                
                <v-list-item-icon>
                    <v-icon > mdi-account-multiple-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Send to selected users </v-list-item-title>
                </v-list-item-content> </v-list-item>

                <v-list-item @click="$router.push({ path: '/sendnotifications/history'})"> 
                
                <v-list-item-icon>
                    <v-icon > mdi-list-status </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Notifications History </v-list-item-title>
                </v-list-item-content> </v-list-item>

            </v-list-item-group>
        </v-list>

        </v-card>

    </dashboard-layout>
  
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'

export default {

     computed : {
        links() {
            return this.$store.state.links
        }
     },
      components: {
      DashboardLayout
    },
    data(){
        return {
            model: [1]
        }
    }


}
</script>

<style>

</style>