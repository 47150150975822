<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-chip
        class="text-caption font-weight-bold"
        v-on="on"
        v-bind="attrs">
        Likes: 
        <span class="font-weight-regular">{{count}} </span>
        </v-chip>

        <!-- <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Post Likes
        </v-btn> -->
        </template>

        <v-data-table
        dense
        :headers="likesTableHeaders"
        :items="likesTableContent"
        item-key="name"
        class="elevation-1">
            <template  v-slot:[`item.Likedby`]="{ item }">
                        <router-link :to="{ name: 'user' , params: {userId:item.Likedby} }">
                            {{ item.Likedby }}
                        </router-link>
            </template>
            <template v-slot:[`item.Timestamp`]="{ item }">
                <span>{{ new Date(item.Timestamp).toUTCString() }}</span>
            </template>


        
        </v-data-table>
    </v-dialog>
</template>

<script>
import {nocodb} from '@/axios';
export default {
    props: ['postId','count'],
    data(){
        return{
            likesTableHeaders: [
                {text:'User Id' , align:'start' , sortable : 'false' , value : 'Likedby'},
                {text: 'Time' , value: 'Timestamp'}
            ],
            likesTableContent : [],
            

        }
    },
    async created(){
        const likes = await nocodb.get('Userpostlikes',{params: {where:`(Userpostsid,eq,${this.postId})~(Deleted,eq,0)`}})
        this.likesTableContent = likes.data.list
        console.log(likes.data)
    }

}
</script>

<style>

</style>