import axios from "axios";
import { APIUNAME, APIPASS,APIURL } from '../app_config.json'
import { getInstance } from './auth/index';
import jwt_decode from "jwt-decode";
import store from '@/store/index'

// axios.defaults.baseURL = ""
// axios.defaults.headers = {
//     'xc-auth': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzaGlrLnNhbGlAZW50eW0uY29tIiwiZmlyc3RuYW1lIjpudWxsLCJsYXN0bmFtZSI6bnVsbCwiaWQiOjEsInJvbGVzIjoidXNlciIsImlhdCI6MTY0OTA2MzE2Nn0.GzNYSBanJu7eQBbVg5PMg4aqkWUiuYRPwS-cvDr3ctM' //the token is a variable which holds the token
//     }

const entymApiBaseurl = APIURL;
const authUrl = entymApiBaseurl+'Auth/authenticate';

const nocodb = axios.create({
        baseURL: 'https://ncdb.entym.app/api/v1/db/data/v1/MainDb',
        headers: {
            'xc-token': 'd-626Orr4taBrKI2WvUIWVN5w6b30vRtjhF7RDCL' // token is a variable which holds the token
            }

        
    });
const dashboarddb = axios.create({
    baseURL: 'https://ncdb.entym.app/api/v1/db/data/v1/dashboarddb',
    headers : {
        'xc-token': 'tpxpD8AtUqiURCiCe6U4h-6A_wu4koxMgBUhDgBQ' 
    }
});
        
const entymApi = axios.create({
        baseURL: entymApiBaseurl
    });

const entymApiInterceptor =  function () {
        entymApi.interceptors.request.use( async request => {
            // add auth header with jwt if account is logged in and request is to the api url
            const authService = await getInstance();
            const isLoggedIn = authService.isAuthenticated;
    
            if (!isLoggedIn) {
                return null
            }

            //Check if token exists
            if(store.state.apiAuth.token){
                console.log('Token exists')
                //Get expiration time
                const expiration = jwt_decode(store.state.apiAuth.token).exp
                // If expired
                if (Date.now()/1000 >= expiration){
                    delete entymApi.defaults.headers.common.Authorization
                    const response = await axios.post(authUrl,
                        {
                            name: APIUNAME,
                            password: APIPASS
                        })
                    // Update header in current request
                    request.headers.common.Authorization = `Bearer ${response.data.token}`
                    entymApi.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`


                }
                


            }
            else{
                // Call api and set token and expiry
                console.log('New Token')
                try{
                    var response = await axios({
                        method: 'post',
                        url: authUrl,
                        data: {
                            name: APIUNAME,
                            password: APIPASS
                        }});
                }
                catch(e){
        
                    const { response } = e;
                    const {...errorObject } = response; // take everything but 'request'
                    console.log(errorObject);
                }
        
                // Update header in current request
                request.headers.common.Authorization = `Bearer ${response.data.token}`
                //Save in stores
                store.dispatch('setToken',response.data.token)    
                    // Update header in the axios instance
                entymApi.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
            }
            //Check if token is present if not call api and set token and expiry in store
            
            // Request token old logic
            //const token =  await authService.getTokenSilently()
            //console.log(`token -- ${token}`)
            //request.headers.common.Authorization = `Bearer ${token}`;
    
            return request;
        }) };

        

export{
        nocodb,
        dashboarddb,
        entymApi,
        entymApiInterceptor

    }