<template>
    <dashboard-layout :links="links">
                
    <v-card height="100%" v-if="loader" class="grid">
        <v-progress-circular indeterminate :size="80" color="primary" class=""></v-progress-circular>
    </v-card>



    <v-container>
        <v-card>
            <TopSection :nameinitials="nameInitials" :email="emailid" :joinedtimestamp="joinedTimestamp" :profilesharelink="userProfileShareLink" :rating="rating" :timeslotrequestcount="timeslotrequestcount" :uid="uid" :username="userName"  > </TopSection>
               

                <!-- Row 3  -->
                <v-row >
                    <v-col  cols="12">
                        <v-tabs v-model="activeTab">  
                            <v-tab >
                                Personal Info
                            </v-tab>
                            <v-tab>
                                Timeslots
                            </v-tab>
                            <v-tab >
                                <v-tooltip bottom color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on"> Bookings </span>
                                    </template>
                                    <span> Bookings done by this user as a guest </span>
                                </v-tooltip>
                            </v-tab>
                            <v-tab >
                                Payments/Payouts
                            </v-tab>
                            <v-tab >
                                Ratings
                            </v-tab>
                            <v-tab >
                                User activity
                            </v-tab>
                            <v-tab >
                                Policies
                            </v-tab>
                            <v-tab >
                                User Posts
                            </v-tab>
                            <v-tab>
                                Communications
                            </v-tab>
                        </v-tabs>
                    </v-col>           
                </v-row>

        <v-card class="ma-3" flat>
            <v-tabs-items v-model="activeTab">
                <v-tab-item > 
                    <PersonalInfo :personalInfo="personalInfo" :personalInfoData="personalInfoData" :personalInfoLoader="personalInfoLoader"/>
                </v-tab-item>
                <v-tab-item >
                    <Timeslots :userId="userId" :timeslotDash="metabaseUrl"/>
                </v-tab-item>
                <v-tab-item>
                   <Bookings :userId="userId"/>
                </v-tab-item>
                <v-tab-item>
                    <Payments :userId="userId"/>
                </v-tab-item>
                <v-tab-item>
                    <Ratings :userId="userId" />
                </v-tab-item>
                <v-tab-item>
                    <UserActivity :userId="userId"/>
                </v-tab-item>
                <v-tab-item>
                    <Policies :userId="userId" />
                </v-tab-item>
                <v-tab-item>
                    <Posts :userId="userId" />
                </v-tab-item>
                <v-tab-item> 
                    <Communications/>
                </v-tab-item>
                                
            </v-tabs-items>    
        </v-card>

      </v-card>
    </v-container>

    
    </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import Payments from '@/components/UserProfileComponents/Payments.vue'
import Ratings from '@/components/UserProfileComponents/Ratings.vue'
import Policies from '@/components/UserProfileComponents/Policies.vue'
import Bookings from '@/components/UserProfileComponents/Bookings.vue'
import Timeslots from '@/components/UserProfileComponents/Timeslots.vue'
import PersonalInfo from '@/components/UserProfileComponents/PersonalInfo.vue'
import UserActivity from '@/components/UserProfileComponents/UserActivity.vue'
import Posts from '@/components/UserProfileComponents/Posts.vue'
import Communications from '@/components/UserProfileComponents/Communications.vue'
import TopSection from '@/components/UserProfileComponents/UserProfileTopSection.vue'
import {entymApi,nocodb} from '@/axios'

export default {
    props: ['userId'],
    components: {
        DashboardLayout,Payments,Ratings,Policies,Bookings,Timeslots,PersonalInfo,UserActivity,Posts,Communications,TopSection
    },
    async created(){
        //Set Loaders to true
        //this.loader = true
        this.personalInfoLoader = true

        //Get UserRating,UserProfileShareLink,Public Weblink
        try{
            const [UserRating,UserProfileShareLink,PublicWebLink,NocodbPersonalInfoResp,EntymUserResp,TimeslotRequestCountResp,PersonalInfoResp] = await Promise.all([
            nocodb.get('Userratingsmaster',{params: {where:`(Entymuserid,eq,${this.userId})`,fields:'Avguserrating'}}),
             nocodb.get('Userprofilesharelink',{params: {where:`(Entymuserid,eq,${this.userId})`,fields:'Sharelink'}}),
            nocodb.get('Profilepublicweblinks',{params: {where:`(Entymuserid,eq,${this.userId})~and(Deleted,eq,0)`,fields:'Weblink'}}),
            nocodb.get(`Userpersonaldata`,{params: {where:`(Entymuserid,eq,${this.userId})`}}),
            nocodb.get(`Entymusers`,{params: {where:`(Entymuserid,eq,${this.userId})`}}),
            nocodb.get('Usertimeslotrequest/count',{params: {where:`(Profilefortimeslotrequest,eq,${this.userId} )` }}),
            entymApi.get('EntymUser/PersonalInfo',{params: {EntymUserId:this.userId}}),
            
        ])

        UserRating.data.list.length > 0 ? this.rating = parseFloat(UserRating.data.list[0].Avguserrating) : this.rating = 0
        UserProfileShareLink.data.list.length > 0 ? this.userProfileShareLink = UserProfileShareLink.data.list[0].Sharelink : this.userProfileShareLink = ''
        PublicWebLink.data.list.length > 0 ? this.parsePublicWeblinks(PublicWebLink.data.list[0].Weblink ) : this.publicWebLink = []
        NocodbPersonalInfoResp.data.list.length > 0 ? this.personalInfo=NocodbPersonalInfoResp.data.list[0] : this.personalInfo = []
        EntymUserResp.data.list.length > 0 ?  this.assignUidTimestamp(EntymUserResp.data) : this.uid = ''
        PersonalInfoResp.data ? this.personalInfoData = PersonalInfoResp.data : this.personalInfoData = {}
        console.log(this.userId)
        console.log(TimeslotRequestCountResp.data)
        TimeslotRequestCountResp.data ? this.timeslotrequestcount = TimeslotRequestCountResp.data.count : this.timeslotrequestcount = 0

        //this.personalInfo=NocodbPersonalInfoResp.data.list[0]
        //this.uid = EntymUserResp.data.list[0].Uid
        //this.personalInfoData = PersonalInfoResp.data
        //console.log(NocodbPersonalInfoResp.data.list[0])
        

        if(NocodbPersonalInfoResp.data.list.length > 0){

            const name =  NocodbPersonalInfoResp.data.list[0].Name
            this.userName = name
            this.nameInitials = this.getInitials(name)
        }

                

        }
        
        catch(e){
            console.log(e)
        }
        finally{
            //Stop Loader
            this.personalInfoLoader = false
            //this.loader = false
        }
    },
    data(){
        return {
            loader: false,
            activeTab: `/users/${this.userId}`,
            personalInfoLoader: false,
            personalInfo: {},
            personalInfoData: {},
            tabRoutes:  { 
                personalInfo: `/users/${this.userId}`,
                timeSlots: `/users/${this.userId}/timeslots`,
                bookings: `/users/${this.userId}/bookings`
            },
            rating: 0,
            uid: '',
            joinedTimestamp: '',
            userProfileShareLink: '',
            publicWebLink: [] ,
            metabaseUrl : '',
            userName: '',
            nameInitials: '',
            emailid: '',
            timeslotrequestcount: null
        }
    },
    computed: { 
      links() {
        return this.$store.state.links
      }      
    },
      methods: {
        assignUidTimestamp(data){
            this.joinedTimestamp = data.list[0].Registertimestamp
            this.uid = data.list[0].Uid
            this.emailid = data.list[0].Emailid
        },
          getInitials: (fullName) => {
            if(fullName){
                const allNames = fullName.trim().split(' ');
                const initials = allNames.reduce((acc, curr, index) => {
                    if(index === 0 || index === allNames.length - 1){
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                    }
                    return acc;
                }, '');
                return initials;

                }            
            },
            parsePublicWeblinks(weblinks){
                weblinks.forEach(element => {
                    let data = element.split("-***-<>-***-")
                    this.publicWebLink.push(data[2])
                });
            },

            getUserName(){
                var fullName = ''
                if(this.personalInfo.Name){
                    fullName= this.personalInfo.Name
                
                }
                return fullName
                
            },
      }
    ,
}
</script>

<style scoped>
.linkspace {
    margin-right: 1em;
}
.rating-top{
    margin-top: 1em;
}
.grid{
  display: grid;
  place-items: center;
}
</style>