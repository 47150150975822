<template>
    <v-row class="ma-0 pa-0"> 
        <v-col class="pa-1"> 
            <v-row  no-gutters> <v-col  cols="4"> {{r1c1Header}} : </v-col> <v-col> {{ r1c1Value }}</v-col></v-row>    
        </v-col> 
        
        <v-col class="pa-1"> 
            <v-row no-gutters> <v-col  cols="4"> {{ r1c2Header }} : </v-col> <v-col > {{r1c2Value }} </v-col></v-row> 
        </v-col>

    </v-row>


  
</template>

<script>
export default {
    props:['r1c1Header','r1c1Value','r1c2Header','r1c2Value']
}
</script>

<style>

</style>