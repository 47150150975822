<template>
  <dashboard-layout :links="links">

  
  <iframe
class="nc-embed"
src="https://ncdb.entym.app/dashboard/#/nc/base/40ec6ea4-4b62-45b8-b5b5-8ae722cb3f32?embed"
frameborder="0"
width="100%"
height="700"
style="background: transparent; border: 1px solid #ddd"></iframe> 
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'


export default {
    components: {
        DashboardLayout,
    },
    computed: { 
      links() {
        return this.$store.state.links
      },
    },

    
}
</script>

<style>

</style>