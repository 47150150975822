<template>
  <finance-layout>
    
    <v-container class="mx-auto">
            <h2> Refunds</h2>
            <p> Select the user to initiate refund to</p>
    </v-container>

    <v-tabs center-active
      grow  v-model="tab">
    <v-tab>Pending Refunds </v-tab>
    <v-tab>Completed Refunds </v-tab>


    <v-tabs-items v-model="tab"> 
        <v-tab-item>
            <v-data-table
                :headers="pendingRefundHeaders"
                :items="pendingRefundData"
                :items-per-page="10"
                class="elevation-1"
            >
            <template v-slot:[`item.Action`]="{ item }">
            
            <v-btn
              small
              color="primary"
              dark
              @click="redirectToPayoutsConfirm(item.Id)"
            >
              Initiate Refund
              
            </v-btn>
            
            
            </template>

        </v-data-table>

        </v-tab-item>

        <v-tab-item>
            <CompletedRefunds/>
        </v-tab-item>
    
    </v-tabs-items>


</v-tabs>




    

  </finance-layout>
</template>

<script>
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import CompletedRefunds from '@/components/PayoutComponents/CompletedRefunds.vue'
import {nocodb} from '@/axios'
export default {
    components: {
    FinanceLayout, CompletedRefunds
    
},
  async created(){
    const resp = await nocodb.get('Userrefunds',{params: {where:'(Status,eq,pending)'} })
    this.pendingRefundData = resp.data.list

  },
  data(){
        return{
            tab: null,
            loader: false,
            hideHeader : true,
            searchTerm: '',
            checkboxVals: {
                id : false,
                userId : false,
                email : true ,
                name : true
            },
            usersViewColumns : {
                id : 'Entymuserid',
                userId : 'Uid',
                email : 'Emailid',
                name : 'Name'
            },
            pendingRefundHeaders: [
                {text: 'User Id', value: 'Entymuserid'  },
                {text: 'Email Id', value: 'Emailid'  },
                { text: 'Issued By', value: 'Issuedby',sortable: false, },
                { text: 'Refund Amount', value: 'Refundamount',sortable : false, },
                { text: 'Refund Currency', value: 'Refundcurrency',sortable: false, },
                {text: 'daterefundissued',sortable: false,value: 'Daterefundissued',width: '20%'},
                {text: 'Action' , sortable : 'false', value: 'Action'}
            ],
            pendingRefundData: []

        }
    },
    computed: { 
      links() {
        return this.$store.state.links
      },
      showTable(){
        let x = false
        if (Object.keys(this.tableContent).length > 0) x= true
        return x
      }
      

      
    },
    watch : {
        checkboxVals : {
            handler () {
                //  this.checkBoxFilter(value)
                // let temp = []
                // for (const [key, value] of Object.entries(value1)) {
                //     if(value == true){
                //         temp.push( `${this.usersViewColumns[key]},like,%${this.searchTerm}%` )
                //     }          
                // }
                // console.log(temp)
            },
        deep: true,
        }
    },
    methods : {
        async search(){
            //Call Search API
            this.loader = true 
            try{
                var data = await nocodb.get('UsersView', { params: { where:`${this.dynamicSearchParams()}` , limit : 7} })
                this.tableContent = data.data.list

            }
            catch(e){
                console.log(e)
            }
            finally{
                this.loader = false
                this.hideHeader = false
            }   
             
        },
        
        dynamicSearchParams(){
        let temp = []
        for (const [key, value] of Object.entries(this.checkBoxFilter(this.checkboxVals))) { // eslint-disable-line no-unused-vars
                temp.push( `(${this.usersViewColumns[key]},like,%${this.searchTerm}%)` )
                      
        }
        let result = temp.join("~or")
        return  result

      },
      checkBoxFilter(obj){
            let res =  Object.fromEntries(Object.entries(obj).filter(([key, value]) => value == true) ) // eslint-disable-line no-unused-vars

            return res
        },
        handleRowClick(event){
        let path = '/refunds-cancellations/' + event.Entymuserid 
        this.$router.push({path:path})
      },

      redirectToPayoutsConfirm(item){
        //const ids  = JSON.parse(item.Timeslots)
        this.$router.push({path: `/refunds-cancellations/${item}`})
       }
               
        
    }
}
</script>

<style>

</style>