<template>
  <v-row>
        <v-expansion-panels multiple v-model="panel" accordion>
            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium"> 
                    Summary
                </v-expansion-panel-header>

                <v-expansion-panel-content>

                    <div v-if="commonLoader" class="grid">
                        <v-progress-circular  indeterminate :size="50" color="primary"> </v-progress-circular>
                    </div>

                    <iframe                    
                    :src="metabaseUrl"
                    frameborder="0"
                    allowtransparency
                    style = "min-height:500px; width:100%;"
                ></iframe>
                    
                 </v-expansion-panel-content>

            </v-expansion-panel>
                                            
            <v-expansion-panel>

            <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Timeslot Requests</v-expansion-panel-header>
            <v-expansion-panel-content>              
            <!-- Table -->
            <v-data-table
                dense
                :loading="commonLoader"
                :headers="timeslotRequestsHeader"
                :items="timeslotRequestsData"
                item-key="name"
                class="elevation-1"
                ></v-data-table>
            </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Available Slots</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="availableSlotsHeader"
                    :items="availableSlotsData"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Decision Pending Slots</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="decisionPendingSlotsHeader"
                    :items="decisionPendingSlotsData"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Booked Slots</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="bookedSlotsHeader"
                    :items="bookedSlotsData"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Completed Slots</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="CompletedSlotsHeaders"
                    :items="completedSlotsData"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>


            

        <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Abandoned Bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="abandonedSlotsHeaders"
                    :items="abandonedSlotsData"
                    item-key="name"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Cancelled Bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="noshowSlotsHeaders"
                    :items="noshowSlotsData"
                    item-key="name"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

        <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Rejected Bookings</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="rejectedSlotsHeaders"
                    :items="rejectedSlotsData"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

        <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">No Show</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    :loading="commonLoader"
                    :headers="noshowSlotsHeaders"
                    :items="noshowSlotsData"
                    item-key="name"
                    class="elevation-1"
                    ></v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>
        
  </v-row>
</template>

<script>
import {entymApi} from '@/axios'
export default {
    props: ['userId','timeslotDash'],
    async created(){
        // On creating component call API and assing values
      this.commonLoader = true 
      const [MetabseUserDashResp,timeSlotsResp] = await Promise.all(
        [  
            entymApi.get('Dashboard/UserTimeSlotsDash',{params: {userid:this.userId}}),
            entymApi.get('EntymUser/TimeSlots' , {params: {EntymUserId: this.userId}})
        ]
       )
      
        //GetMetabaseUrl
        this.metabaseUrl = MetabseUserDashResp.data.url
        
        console.log(timeSlotsResp.data)
        this.availableSlotsData = timeSlotsResp.data.availableSlots
        this.decisionPendingSlotsData = timeSlotsResp.data.decisionPendingSlots
        this.bookedSlotsData = timeSlotsResp.data.bookedSlots
        this.abandonedSlots = timeSlotsResp.data.abandonedTimeSlotsCount.count
        this.completedSlotsData = timeSlotsResp.data.completedTimeSlots
        this.upcomingSlotsData = timeSlotsResp.data.upcomingTimeSlots
        this.abandonedSlotsData = timeSlotsResp.data.abandonedTimeSlots
        this.rejectedSlotsData = timeSlotsResp.data.rejectedslots
        this.Date = timeSlotsResp.data.decisionpendingslots
        this.commonLoader = false

        
    },
    computed: {
        currentTime(){
            let datenow = new Date();
            return datenow.toISOString().replace("T"," ").substring(0, 19);
        },
    },
    data(){
        return {
            panel: [0,1,2,3,4,5,6],
            expanded: [],
        singleExpand: true,
        commonLoader: false,
        totalSlots: 0,
        bookedSlots: 0,
        upcomingSlots: 0,
        completedSlots: 0,
        rejectedSlots: 0,
        abandonedSlots: 0,
        availableSlots: 0,
        metabaseUrl:'',
        timeslotRequestsHeader: [
            { text: 'Time', value: 'time' , sortable: false},
            { text: 'Duration (m)', value: 'duration', sortable: false },
            { text: 'Amount', value: 'amount',sortable: false },
            { text: 'Mode', value: 'mode' ,sortable: false},
            { text: 'TimeSlotId', value: 'timeSlotId', sortable: false }
        ],
        timeslotRequestsData: [],
        availableSlotsHeader: [
            { text: 'Time', value: 'time' , sortable: false},
            { text: 'Duration (m)', value: 'duration', sortable: false },
            { text: 'Amount', value: 'amount',sortable: false },
            { text: 'Mode', value: 'mode' ,sortable: false},
            { text: 'TimeSlotId', value: 'timeSlotId', sortable: false }
        ],
        availableSlotsData: [],
        decisionPendingSlotsHeader: [
            {text: 'Guest Name',align: 'start',sortable: false,value: 'guestName',},
            { text: 'Guest Id', value: 'guestId' , sortable: false},
            { text: 'Time', value: 'time' , sortable: false},
            { text: 'Duration (m)', value: 'duration', sortable: false },
            { text: 'Amount', value: 'amount',sortable: false },
            { text: 'Mode', value: 'mode' ,sortable: false},
            { text: 'TimeSlotId', value: 'timeSlotId', sortable: false }
        ],
        decisionPendingSlotsData: [],
        bookedSlotsHeader: [
            {text: 'Guest Name',align: 'start',sortable: false,value: 'guestName',},
            { text: 'Guest Id', value: 'guestId' , sortable: false},
            { text: 'Time', value: 'time' , sortable: false},
            { text: 'Duration (m)', value: 'duration', sortable: false },
            { text: 'Amount', value: 'amount',sortable: false },
            { text: 'Mode', value: 'mode' ,sortable: false},
            { text: 'TimeSlotId', value: 'timeSlotId', sortable: false }
        ],
        bookedSlotsData: [],
        CompletedSlotsHeaders: [
        {text: 'Guest Name',align: 'start',sortable: false,value: 'guestName',},
        { text: 'Guest Id', value: 'guestId' , sortable: false},
        { text: 'Duration (m)', value: 'duration', sortable: false },
        { text: 'Amount', value: 'amount',sortable: false },
        { text: 'Mode', value: 'mode' ,sortable: false},
        { text: 'Rating', value: 'ratings', sortable: false },
        { text: 'MeetingRoom Id', value: 'meetingRoomId', sortable: false },
        { text: 'TimeSlotId', value: 'timeSlotId', sortable: false }
        ],
        completedSlotsData: [],
        upcomingSlotsHeaders: [
        { text: 'Time', align: 'start' , value: 'time' , sortable: false},
        { text: 'Duration (m)', value: 'duration', sortable: false },
        { text: 'Amount', value: 'amount',sortable: false },
        { text: 'Currency', value: 'currency',sortable: false },
        { text: 'Mode', value: 'mode' ,sortable: false},
        ],
        upcomingSlotsData: [],
        abandonedSlotsHeaders: [
        {text: 'Guest Name',align: 'start',sortable: false,value: 'guestName',},
        { text: 'Time', value: 'time' , sortable: false},
        { text: 'Duration (m)', value: 'duration', sortable: false },
        { text: 'Amount ($)', value: 'amount',sortable: false },
        { text: 'Mode', value: 'mode' ,sortable: false},
        ],
        abandonedSlotsData: [],
        rejectedSlotsHeaders: [
        {text: 'Guest Name',align: 'start',sortable: false,value: 'guestName',},
        { text: 'Time', value: 'time' , sortable: false},
        { text: 'Duration (m)', value: 'duration', sortable: false },
        { text: 'Amount ($)', value: 'amount',sortable: false },
        { text: 'Mode', value: 'mode' ,sortable: false},
        ],
        rejectedSlotsData: [],
        noshowSlotsHeaders: [
        {text: 'Guest Name',align: 'start',sortable: false,value: 'guestName',},
        { text: 'Time', value: 'time' , sortable: false},
        { text: 'Duration (m)', value: 'duration', sortable: false },
        { text: 'Amount ($)', value: 'amount',sortable: false },
        { text: 'Mode', value: 'mode' ,sortable: false},
        ],
        noshowSlotsData: [],
        }
    }
}
</script>

<style>
.v-data-table td {
    border-bottom: none !important;
}
.grid{
  display: grid;
  place-items: center;
}

</style>