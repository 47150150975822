<template>
<dashboard-layout :links="links">

    <v-toolbar class="teal" dark flat rounded="2">
                <v-toolbar-title> Collections </v-toolbar-title>
                <v-spacer></v-spacer>
                
              </v-toolbar>
<v-row>
    <v-col class="box" cols="3" v-for="(k,index) in allCollections" :key="index" >
    <v-card  elevation="2"> 
       <v-card-title> {{k.name}} </v-card-title>
        
        <v-card-subtitle> {{k.num_documents}} </v-card-subtitle>
        
    </v-card>
    </v-col>
</v-row>

  </dashboard-layout>
</template>




<script>

import DashboardLayout from '@/layouts/DashboardLayout.vue'
const Typesense = require('typesense');


export default {
  data() {
    return {
      allCollections : []
    };
  },
  components: { DashboardLayout},
  computed: { 
      links() {
        return this.$store.state.links
      },
    },
    async created(){
        let client = new Typesense.Client({
      'nodes': [{
        'host': 'tpss.entym.app', // For Typesense Cloud use xxx.a1.typesense.net
        'port': '443',      // For Typesense Cloud use 443
        'protocol': 'https'   // For Typesense Cloud use https
      }],
      'apiKey': 'EEfwjSdnd3MTFD1iK6fDUVpvUsKZ2kUJ2WFGknS1Dvd3B1yr',
      'connectionTimeoutSeconds': 900
    });
    const list = await client.collections().retrieve();
    this.allCollections = list
    console.log(list)
    
    }
};
</script>

<style scoped>
body {
  font-family: sans-serif;
  padding: 1em;
}
.box {
    margin: 1em;
}
</style>