var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"text-caption font-weight-bold"},'v-chip',attrs,false),on),[_vm._v(" Likes: "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.count)+" ")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.likesTableHeaders,"items":_vm.likesTableContent,"item-key":"name"},scopedSlots:_vm._u([{key:"item.Likedby",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'user' , params: {userId:item.Likedby} }}},[_vm._v(" "+_vm._s(item.Likedby)+" ")])]}},{key:"item.Timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.Timestamp).toUTCString()))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }