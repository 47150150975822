<template> 
    <v-data-table
                :headers="pendingRefundHeaders"
                :items="pendingRefundData"
                :items-per-page="10"
                class="elevation-1"
            >

    </v-data-table>

</template>

<script>
import {nocodb} from '@/axios'
export default{
    async created(){
    const resp = await nocodb.get('Userrefunds',{params: {where:'(Status,neq,pending)'} })
    this.pendingRefundData = resp.data.list

  },
  data(){
    return {
        pendingRefundHeaders: [
                { text: 'Issued By', value: 'Issuedby',sortable: false, },
                { text: 'Refund Amount', value: 'Refundamount',sortable : false, },
                { text: 'Refund Currency', value: 'Refundcurrency',sortable: false, },
                {text: 'daterefundissued',sortable: false,value: 'Daterefundissued',width: '20%'},
                {text: 'Status',value: 'Status'},
                {text: 'Action' , sortable : 'false', value: 'Action'}
            ],
            pendingRefundData: []
    }
  }
}

</script>