<template>
 <dashboard-layout :links="links">
        <v-container>
            <v-stepper v-model="e1">
            <v-stepper-header>
            <v-stepper-step
                :complete="e1 > 1"
                step="1"
            >
                Select Users
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 2"
                step="2"
            >
                Compose Message
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 3"
                step="3"
            >
                Authenticate and Send
            </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-combobox
                    multiple
                    persistent-hint
                    small-chips
                    solo
                    deletable-chips
                    readonly
                    v-model="selectedChips"
                    label="Selected Users"
                    ></v-combobox>

                    <v-card outlined>
                    <v-card-title>
                     <small>  Search users  by email</small>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchParam"
                        append-icon="mdi-magnify"
                        @click:append="search()"
                        label="Search"
                        v-on:keyup.enter= "search()"
                        single-line
                        clearable
                        hide-details
                    ></v-text-field>
                    </v-card-title>

                    <v-data-table
                    :loading="loader"
                    v-model="selected"
                    :headers="tableHeaders"
                    :items="tableContent"
                    item-key="Entymuserid"
                    show-select
                    :options.sync="options"
                    :server-items-length="totalUsers"
                    :items-per-page="itemsPerPage"
                    >
                    <template  v-slot:[`item.Entymuserid`]="{ item }">
                            <v-avatar size="36">
                            <img v-if="item.Profileimage"                        
                                alt="Avatar"
                                :src= item.Profileimage>

                            </v-avatar>
                        </template>
                    
                    </v-data-table>

                    <v-btn
                    class="mt-4 ml-8"
                    color="primary"
                    @click="e1 = 2"
                    >
                    Continue
                    </v-btn>

                </v-card>

                   

                    

                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-form ref="form" v-model="valid">
                        <v-text-field
                                v-model="title"
                                :counter="titleMax"
                                label="Message Title (Should not exceede 65 characters)"
                                required
                            ></v-text-field>

                            <v-textarea
                            v-model="messageContent"
                            required
                            label="Message Content"
                            auto-grow
                            :counter="messageMax"
                            ></v-textarea>

                        <v-text-field
                                v-model="imageLink"
                                
                                label="Image URL"
                                required
                            ></v-text-field>
                        
                    </v-form>

                    <v-btn
                    @click="e1 =3"
                    color="primary"
                    >
                    Continue
                    </v-btn>

                    <v-btn text @click="e1 = 1" >
                    Back
                    </v-btn>
 
                    
                    </v-stepper-content>

                    
                    <v-stepper-content  step="3">
                        <div class="grid" v-if="sendNotificationLoader">
                            <v-progress-circular 
                            class="text-center"
                            :size="70"
                            :width="7"
                            indeterminate
                            color="primary"
                            ></v-progress-circular>                            
                        </div>

                        <div v-if="!sendNotificationLoader && apiResponseRecieved">
                            <div v-if="apiSuccessful">
                                <v-alert type="success"> Notification Sent </v-alert>
                            </div>
                            <div v-else>
                                <v-alert type="error"> Failed to Send Notifications </v-alert>
                            </div> 

                        </div>
                        




                        <div v-if="!sendNotificationLoader && !apiResponseRecieved"> 
                                            <v-alert
                        v-if="isAuthenticated"
                        outlined
                        type="success"
                        text
                        >
                        Authenticated
                        </v-alert>

                        <v-container fluid fill-height v-if="!isAuthenticated">
                        <v-layout align-center justify-center>
                        <v-flex xs12 sm8 md4>
                            <v-form @submit.prevent="signinToFirebase()">
                            <v-card class="elevation-12">
                                <v-toolbar dark color="primary">
                                <v-toolbar-title>Login</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                <v-text-field
                                    v-model="id"
                                    prepend-icon="person"
                                    name="login"
                                    label="Login"
                                    :rules="rules.id"
                                    type="text"
                                ></v-text-field>
                                <v-text-field
                                    v-model="password"
                                    prepend-icon="lock"
                                    name="password"
                                    label="Password"
                                    :rules="rules.password"
                                    :append-icon="value ? 'visibility' : 'visibility_off'"
                                    @click:append="() => (value = !value)"
                                    :type="value ? 'password' : 'text'"
                                ></v-text-field>
                                <p v-if="loginError" class="red--text" >{{errorMessage}}</p>
                                </v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <!-- Check that the SDK client is not currently loading before accessing is methods -->
                                <div>
                                <v-btn  type="submit" @click="signinToFirebase()" color="primary"
                                    >Login</v-btn
                                >
                                </div>
                                </v-card-actions>
                            </v-card>
                            </v-form>
                        </v-flex>
                        </v-layout>
                    </v-container>
                     <v-btn text @click="e1 = 2" >
                    Back
                    </v-btn>
                    <v-btn v-if="isAuthenticated" color="primary" @click="sendNotifications()"> Send </v-btn>


                        </div> 



                    
                    </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

    

            <!-- <h2> Select Users</h2> -->
            <!-- <v-row class="ml-auto">
                    <v-subheader class="pt-auto"> Search in a specific field</v-subheader>
                    <v-checkbox dense v-model="checkboxVals.email" label="Name"> </v-checkbox>
                    <v-checkbox  dense v-model="checkboxVals.name" label="Email"></v-checkbox>
                    <v-checkbox dense v-model="checkboxVals.userId" label="Unique Id"></v-checkbox>                  
                    <v-checkbox dense v-model="checkboxVals.id" label="Id"></v-checkbox> 
                </v-row> -->


                <!-- <v-combobox
                multiple
                persistent-hint
                small-chips
                solo
                deletable-chips
                readonly
                v-model="selectedChips"
                label="Selected Users"
                ></v-combobox> -->
         
                <!-- <v-btn class="my-auto" @click="$router.push({path:'/sendnotifications/select/compose' })"> Send Notification </v-btn> -->
            

                
                
        </v-container>
    </dashboard-layout>
  
</template>

<script>
//var firebase = require('firebase');
//import  firebase from "firebase/compat/app";
//const auth = require('firebase/auth');
// import { getAuth } from "firebase/compat/auth";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

//var firebaseui = require('firebaseui');
import "firebaseui/dist/firebaseui.css";

import DashboardLayout from '@/layouts/DashboardLayout.vue'
import {nocodb,entymApi} from '@/axios'
import LoginOrigin from "@/components/LoginOrigin";


export default {
    components : {DashboardLayout},
    extends : LoginOrigin ,
    data(){
        return {
            loader : false,
            searchParam: '',
            selected : [],
            selectedChips : [],
            uidArray: [],
            comboboxHint: "Selected Users will be displayed on this box",
            tableHeaders: [
                { text: 'Image', value: 'Entymuserid',sortable: false, },
                { text: 'Emailid', value: 'Emailid',sortable : false, },
                { text: 'Name', value: 'Name',sortable: false, },
                
            ],
            tableContent : [],
            totalUsers: 0,
            itemsPerPage: 10,
            options: {},
            e1: 1,
            imageLink: '',
            title: '',
            messageContent: '',
            titleMax: 65,
            messageMax: 400,
            dialog: false,
            isAuthenticated: false,
            value: String,
            id: "",
            password: "",
            errorMessage: "Error! Please verify email and password",
            loginError : false,
            bearerToken: '',
            sendNotificationLoader: false,
            apiResponseRecieved : false,
            apiSuccessful : false
            
        }
    },
    methods : {
        async search(offset = 0){
            this.loader = true
            if(this.searchParam){
                let data = await nocodb.get('UsersView', { params:  { where: `(Emailid,like,%${this.searchParam}%)`, offset:offset, limit : 10} })
                this.tableContent = data.data.list
                this.totalUsers = data.data.pageInfo.totalRows
                this.itemsPerPage = data.data.pageInfo.pageSize
            }
            else{
                let data = await nocodb.get('UsersView', { params: {  limit : 10 , offset: offset} })
                this.tableContent = data.data.list
                this.totalUsers = data.data.pageInfo.totalRows
                this.itemsPerPage = data.data.pageInfo.pageSize

            } 
            
            this.loader = false

        },
        getPaginationOffset(pageNumber){
          let offset = 0;
          if (pageNumber > 1){
              offset = (pageNumber-1) * 10
          }
          return offset 
      },
      getUidArray(){
        let uids = []
        this.selected.forEach((item)=>{
            uids.push(item.Uid)
        })
        return uids
      },
      getEmailIds(){
        let emails = []
        this.selected.forEach((item)=>{
            emails.push(item.Emailid)
        })
        return emails
      },
      async sendNotifications(){
        //Check if user is authenticated
        if(this.bearerToken){
            // Call api
            const uids = this.getUidArray()
            console.log('Uids')
            console.log(uids)
            try{
                this.sendNotificationLoader = true
                const resp = await entymApi.post('cf/sendnotification',
                {
                    title : this.title,
                    message : this.messageContent,
                    imageLink : this.imageLink,
                    users: uids,
                    token : this.bearerToken
                })
                if(resp.data.statusCode == 200){
                    this.apiResponseRecieved =true
                    this.apiSuccessful =true
                    this.sendNotificationLoader = false
                }
                else{
                    this.apiResponseRecieved =true
                    this.apiSuccessful = false
                    this.sendNotificationLoader = false
                }

                //Log the messages
                await nocodb.post('NotificationsSentLogs',{
                    NotificationTitle : this.title,
                    NotificationMessage : this.messageContent,
                    ImageLink : this.imageLink,
                    Users : JSON.stringify(this.getEmailIds()),
                    Sentby : this.$auth.user.email

                })
                
            }catch(e){
                console.log(e)
                this.apiResponseRecieved =true
                    this.apiSuccessful = false
                    this.sendNotificationLoader = false
            }
        }
        else{
            // Load popup
        }
      },
      signinToFirebase(){
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.id, this.password)
        .then((userCredential) => {
            // Signed in 
            this.bearerToken = ''
            this.bearerToken = userCredential.user.accessToken;
            console.log(this.bearerToken)            
            this.isAuthenticated = true
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode,errorMessage)
            this.loginError =true
        });
      }
     },
    computed : {
        links() {
            return this.$store.state.links
        }
     },
     watch: {
        selected: {
            handler(newValue) {
                this.selectedChips.length = 0 
                newValue.forEach(element => {
                    
                    this.selectedChips.push(element.Emailid)
                    console.log(element)
                    // Note: `newValue` will be equal to `oldValue` here
                    // on nested mutations as long as the object itself
                    // hasn't been replaced.
                });
                
            },
            deep: true
        },
       
        options: {
            async handler(newValue){
                // Call Api according to the next page
                const offset = this.getPaginationOffset(newValue.page)
                await this.search(offset)
                


            },
            deep: true
        }
  },
    
    async created(){
        await this.search()
    },

    mounted(){

    }

    

}
//
</script>

<style scoped>
.grid{
    display: grid;
    place-items: center;
}

</style>