<template>
  <dashboard-layout :links="links"> 



    <v-card>
  <v-data-table
    :headers="headers"
    :items="tabledata"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{tablename}}</v-toolbar-title>
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>

        <!-- <v-autocomplete
      v-model="select"
      disable-lookup
      clearable
      :loading="searchloading"
      :items="searchItems"
      :search-input.sync="search"
      cache-items
      class="mx-4"
      flat
      hide-no-data
      hide-details
      label="Seach by name"
    ></v-autocomplete> -->
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search by Name"
        single-line
        hide-details
        :loading="searchloading"
      ></v-text-field>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4" v-for="i in Object.keys(editedItem)" :key="i">
                    <v-text-field :disabled="disableField(i)" v-model="editedItem[i]" :label="i"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.Name" label="Name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.CountryCode" label="CountryCode"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.Population" label="Population"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.Timezone" label="TimeZone"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.Coordinates" label="Coordinates"></v-text-field>
                  </v-col> -->
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" :loading="loading" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset</v-btn>
    </template>
  </v-data-table>
    </v-card>

</dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import {nocodb} from '@/axios'

  export default {
      components: {
          DashboardLayout
      },
      props: ['tablename'],
    data: () => ({
      footerProps: {'disable-items-per-page': true},
      dialog: false,
      dialogDelete: false,
      tabledata: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: { },
      totalCount: 0,
      options: {},
      loading: false,
      searchloading: false,
      // Search Items
      searchItems: [],
      search: null,
      select: null,
      fieldDisabled: 0
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      links() {
        return this.$store.state.links
      },
      headers() {
          return this.$store.state.airtableData[this.tablename]['header']
      },
      getUrl() {
          return this.$store.state.airtableData[this.tablename]['url']['getUrl']
      },
      editDiaglog(){
          return this.$store.state.airtableData[this.tablename]['editDialogItems']
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      options: {
      handler () {
          this.initialize()
      },
      deep: true,
    },
    search (val) {
        val && val !== this.select && this.querySelections(val)
        console.log(val)
        if(val == null) this.initialize()
      },
    },

    created () {
      this.initialize()

    },

    methods: {
      async initialize () {
        this.loading = true;
        this.editedItem = this.$store.state.airtableData[this.tablename].editedItem
        this.defaultItem = this.$store.state.airtableData[this.tablename].defaultItem
        const url = this.getUrl + '&offset='+ this.getPaginationOffset(this.options.page)
        console.log(url)
        const resp = await nocodb.get(url)
        this.tabledata = resp.data.list
        const countResp = await nocodb.get(this.$store.state.airtableData[this.tablename].url.countUrl)
        this.totalCount = countResp.data.count
        this.loading = false
      },
      getPaginationOffset(pageNumber){
          let offset = 0;
          if (pageNumber > 1){
              offset = (pageNumber-1) * 10
          }
          return offset 
      },
      async querySelections(){
        this.searchloading = true
        let url = this.$store.state.airtableData[this.tablename].url.searchUrl + this.search +'%25)' 
        const resp = await nocodb.get(url)
        this.tabledata = resp.data.list
        this.searchloading = false
      },
      editItem (item) {
        this.editedIndex = this.tabledata.indexOf(item)
        this.editedItem = Object.assign({}, item)
        console.log()
        // Remove Fields which shouldn't be edited
        // this.$store.state.airtableData[this.tablename].editDisabledFields.forEach(element => {
        //   delete this.editedItem[element]
        // });
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.tabledata.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
          this.loading = true
        var sqlIdField = this.$store.state.airtableData[this.tablename].sqlIdfield

        const id = this.tabledata[this.editedIndex][sqlIdField]
        //Call API to Delete
        let delUrl = this.$store.state.airtableData[this.tablename].url.deleteUrl + id
        await nocodb.delete(delUrl)
        this.tabledata.splice(this.editedIndex, 1)
        this.loading = false
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        // Get SQL Id field value from store 
        var sqlIdField = this.$store.state.airtableData[this.tablename].sqlIdfield
        if (this.editedIndex > -1) {
          Object.assign(this.tabledata[this.editedIndex], this.editedItem)
          //Update API Call
          console.log('Edited Item')
          console.log(this.editedItem)
          // Generate Url using the SQL Id field value
          
          const id = this.tabledata[this.editedIndex][sqlIdField]
          const url = this.$store.state.airtableData[this.tablename].url.updateUrl + id
          // Delelete Id column from edited object           
          delete this.editedItem[sqlIdField]
          console.log(this.editedItem)
          await nocodb.patch(url,this.editedItem)
          //TODO:
        } else {
          let editedObject = this.editedItem
          //delete editedObject.GeonameId
          const url = this.$store.state.airtableData[this.tablename].url.createUrl
          console.log(editedObject)
          console.log('create' + url)
          const resp = await nocodb.post(url,editedObject,{
              headers: {
                  'content-type':'application/json'
              }
          })
          console.log(resp)   
          this.tabledata.push(resp.data)
        }
        this.close()
      },     
      disableField (field){
        const list = this.$store.state.airtableData[this.tablename].editDisabledFields
        return list[field]
      }
    
  }
}
</script>

<style>

</style>