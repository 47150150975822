<template>
  <component :is="view"></component>
</template>

<script>
import UsersAdminView from '@/components/UsersAdminView.vue'
import UsersAlternateView from '@/components/UsersAlternateView.vue'
export default {
  components : {
      UsersAdminView,UsersAlternateView
  },
  computed : {
    view(){
      return this.$auth.user["https://entym.app/roles"].includes("Admin") ? "UsersAlternateView"  : "UsersAdminView"
    }
  }
}
</script>

<style>

</style>