<template>
    <dashboard-layout :links="links">
        <v-card  max-width="100%" tile>
          <!-- <v-img height="100%" :src="$auth.user.picture"></v-img> -->
          <v-col>
            <v-avatar size="100">
              <v-img :src="$auth.user.picture"></v-img>
            </v-avatar>
          </v-col>
            <v-list-item color="rgba(0, 0, 0, .4)">
              <v-list-item-content>
                <v-list-item-title class="title">{{$auth.user.name}}</v-list-item-title>
                <v-list-item-subtitle>{{$auth.user.email}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-card>

    <v-expansion-panels multiple v-model="panel" accordion>
        <v-expansion-panel>
            <v-expansion-panel-header>
                Some Useful Info
            </v-expansion-panel-header>
            <v-expansion-panel-content>

                <v-card>
                    <v-card-title> Tools Used </v-card-title>
                    <v-container> 
                        <v-row> <v-col cols="2">Chat / Meetings : </v-col> <v-col> Microsoft Teams </v-col> </v-row>
                        <v-row> <v-col cols="2"> Project Management : </v-col> <v-col> Asana </v-col> </v-row>

                    </v-container>
                 

                    

                </v-card>

                
                
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    

    </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'

export default {
    computed : {
        links() {
            return this.$store.state.links
        },
        
        
    },
    data(){
          return {
            panel: [0]
          }
        },
    components: {
      DashboardLayout
    },

}
</script>

<style>

</style>