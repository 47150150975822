<template>
    <v-container  class="text-body-2">
        <v-row >
            <v-col cols="1" align-self="center"> 
                <v-avatar  color="teal" size="48">
                    <span class="white--text text-h5"> {{nameinitials}} </span>
                </v-avatar></v-col>

            <v-col>

                <v-row  class="ma-0 pa-0"> <v-col> {{username }} </v-col> <v-col> <v-row> <v-col cols="4"> Rating : </v-col> <v-col> <v-rating  :value="rating" background-color="orange lighten-3" color="orange" small half-increments readonly> </v-rating> </v-col></v-row>  </v-col> </v-row>
                <TopRow r1c1Header="Joined" :r1c1Value="joinedtimestamp" r1c2Header="Profile Sharelink" :r1c2Value="profilesharelink"/>
                <TopRow r1c1Header="Uid" :r1c1Value="uid" r1c2Header="Email" :r1c2Value="email"/>
                <TopRow r1c1Header="Time-slot requests" :r1c1Value="timeslotrequestcount" />

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TopRow from '@/components/UserProfileComponents/UserProfileTopSectionRow.vue'
export default {
    props: ['username','uid','joinedtimestamp','rating','profilesharelink','email','timeslotrequestcount','nameinitials'],
    components: {TopRow}

}
</script>

<style>

</style>