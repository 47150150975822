import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import  {entymApiInterceptor} from './axios.js'
import  './axios.js'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import InstantSearch from 'vue-instantsearch';
import iframeResize from 'iframe-resizer/js/iframeResizer';
import 'nprogress/nprogress.css'


// Import the Auth0 configuration
import { domain, clientId ,audience} from '../auth_config.json';
// Import the plugin here
import { Auth0Plugin } from './auth';
// Install the authentication plugin here



// Import the functions you need from the SDKs you need
import  firebase from "firebase/compat/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB7_s3jw1u3RjbFoQFsV0_3ICU4xsiV7B8",
  authDomain: "entym-c09bf.firebaseapp.com",
  databaseURL: "https://entym-c09bf.firebaseio.com",
  projectId: "entym-c09bf",
  storageBucket: "entym-c09bf.appspot.com",
  messagingSenderId: "1041726368396",
  appId: "1:1041726368396:web:0be40a409a0e8cba2b69a9",
  measurementId: "G-SH5FRZLL5D"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
Vue.use(InstantSearch);
Vue.config.productionTip = false
entymApiInterceptor()

Vue.directive('metabasResize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
