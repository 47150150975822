import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
    iconfont: 'md'
});

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: colors.pink.darken1,
            accent: colors.pink.lighten4,
            
          }
        }
    }
});
