<template> 
 <v-card> 
    <v-card-title class="subtitle-1"> {{ title}} </v-card-title>
    <v-card-text>  
        
        <div>
            <div> City : {{location.city}} </div> 
            <div> Country : {{location.country}} </div>
        </div>


    </v-card-text>
    

 </v-card>

</template>

<script> 
export default{
    name: 'LocationComponentBase',
    props: ['title','location'],
    created(){

    },
    data(){
        return {

        }
    }
}
</script>