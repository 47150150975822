import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import DashboardQRLink from '../views/DashboardQRLink.vue'
import DashboardQRLinkItem from '../views/DashboardQRLinkItem.vue'

import PeopleView from '../views/PeopleView.vue'
import ReportsView from '../views/ReportsView.vue'
import LoginView from '../views/LoginView.vue'
import GlobalDataView from '../views/GlobalDataView.vue'
import GlobalDataAddView from '../views/GlobalDataAddView.vue'
import EditQRLinks from '../views/EditQRMapper.vue'

import ProfileView from '../views/ProfileView.vue';
import AirTableView from '../views/AirTableView.vue';
import LandingPage from '../views/LandingPage.vue'
import UserView from '../views/UserView.vue'
import UsersView from '../views/UsersView.vue'
import TypeSenseSearch from '../views/TypeSenseSearch.vue'
import PayoutsView from '../views/PayoutsView.vue'
import PayoutsConfirm from '../views/PayoutsConfirm.vue'
import PayoutsMultipleConfirm from '../views/PayoutsMultipleConfirm.vue'
import PayoutsByUser from '../views/PayoutsByUser.vue'
import PayoutsAll from '../views/PayoutsAll.vue'
import PayoutsTracker from '../views/PayoutsTracker.vue'
import PayoutsTrackerSingleView from '../views/PayoutsTrackerSingleView.vue'
import RefundsCancellationsHome from '../views/PayoutsCancellationsRefundsHome'
import PayoutsCancellationsRefundsInitiateRefund from '../views/PayoutsCancellationsSelectSlot'
import RefundsCancellationsUser  from '../views/PayoutsCancellationsRefundsInitiateRefund.vue'
import PayoutsConfirmV2 from '../views/PayoutsConfirmV2.vue'
import PayoutsMultipleConfirmV2 from '../views/PayoutsMultipleConfirmV2.vue'

import Unauthorized from '../views/Unauthorized.vue'
import EmployeeHome from '../views/EmployeeHome.vue'
import SendNotifications from '../views/SendNotifications.vue'
import ComposeNotification from '../views/ComposeNotification.vue'
import SendNotificationToSelect from '../views/SendNotificationToSelectUsers.vue'
import GlobalDataMain from '../views/NocodbGlobaldataMain.vue'
import GlobalDataTemp from '../views/NocodbGlobaldataTemp.vue'
import SendNotificationHistory from '../views/SendNotificationHistory.vue'
import ContentModeration from '../views/ContentModeration.vue'
import ContentModerationPosts from '../views/ContentModerationPosts.vue'
import ContentModerationPostComments from '../views/ContentModerationPostComments'
import ContentModerationPublicLinks from '../views/ContentModerationPublicLinks'
import VerifyPost from '../views/ContentModerationVerfiPost'
import TestView from '../views/TestView.vue'
import NotFound from '../views/NotFound.vue'
import { authGuard } from '../auth/authGuard';



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: EmployeeHome,
    beforeEnter: authGuard,  },
  {
      path: '/test',
      name: 'test',
      component: TestView,
      beforeEnter: authGuard,  },
  {
    path: '/people',
    name: 'people',
    component: PeopleView,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/appdashboard',
    name: 'analyticsdash',
    component: DashboardView,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/qrlinkdashboard',
    name: 'qrlinkdash',
    component: DashboardQRLink,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/qrlinkdashboard-item/:code',
    name: 'qrlinkdash-item',
    component: DashboardQRLinkItem,
    beforeEnter: authGuard,
    props: true,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/editqrlinks',
    name: 'editqrlinks',
    component: EditQRLinks,
    beforeEnter: authGuard,
    props: true,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/globaldata',
    name: 'globaldata',
    component: GlobalDataView,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/globaldataadd/:id',
    name: 'globaldataadd',
    component: GlobalDataAddView,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/globaldata/main',
    name: 'GlobaldataMain',
    component: GlobalDataMain,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/globaldata/temp',
    name: 'GlobaldataTemp',
    component: GlobalDataTemp,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/table/:tablename',
    name: 'table',
    component: AirTableView,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/users/:userId',
    name: 'user',
    component: UserView,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/sendnotifications',
    name: 'sendnotifications',
    component: SendNotifications,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }

  },
  
  {
    path: '/sendnotifications/select',
    name: 'sendnotificationtoselect',
    component: SendNotificationToSelect,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] },
    

  },
  {
    path: '/sendnotifications/history',
    name: 'notificationhistory',
    component: SendNotificationHistory,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] },
    

  },
  {
    path: '/sendnotifications/select/compose',
    name: 'composenotification',
    component: ComposeNotification,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }
    

  },
  {
    path: '/moderate',
    name: 'moderate',
    component: ContentModeration,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }
    

  },
  {
    path: '/moderate/posts',
    name: 'moderateposts',
    component: ContentModerationPosts,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }
    

  },
  {
    path: '/moderate/posts/:postid',
    name: 'VerifyPost',
    props: true,
    component: VerifyPost,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }
    

  },
  
  {
    path: '/moderate/postcomments',
    name: 'moderatepostcomments',
    component: ContentModerationPostComments,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }
    

  },
  {
    path: '/moderate/publiclinks',
    name: 'moderatepubliclinks',
    component: ContentModerationPublicLinks,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin'] }
    

  },
  
  {
    path: '/payouts',
    name: 'payouts',
    component: PayoutsView,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Finance','Admin'] }
  },
  {
    path: '/payouts/:payoutid',
    name: 'payoutsconfirm',
    component: PayoutsConfirm,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },

  {
    path: '/payouts-user',
    name: 'payouts-user',
    component: PayoutsByUser,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },
  {
    path: '/payouts-all',
    name: 'payouts-all',
    component: PayoutsAll,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },
  {
    path: '/payouts-user/:userId',
    name: 'payoutsmultipleconfirm',
    component: PayoutsMultipleConfirm,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },
  {
    path: '/payouts-user-v2/:userId',
    name: 'payoutsmultipleconfirmv2',
    component: PayoutsMultipleConfirmV2,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },

  {
    path: '/payouts-confirm-v2/:Id',
    name: 'PayoutsConfirmV2',
    component: PayoutsConfirmV2,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },
  {
    path: '/trackpayouts',
    name: 'payoutstracker',
    component: PayoutsTracker,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },
  {
    path: '/trackpayouts/:id',
    name: 'payoutstrackersingle',
    component: PayoutsTrackerSingleView,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] }

  },
  {
    path: '/refunds-cancellations',
    name: 'RefundsCancellationsHome',
    component: RefundsCancellationsHome,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] },

  },
  {
    path: '/refunds-cancellations/:id',
    name: 'RefundsCancellationsUser',
    component: RefundsCancellationsUser,
    props: true,
    beforeEnter: authGuard,
    meta: {Roles: ['Admin','Finance'] },
    
    },
    {
      path: '/refunds-payout/slot/:timeslotid',
      name: 'PayoutsCancellationsRefundsInitiateRefund',
      component: PayoutsCancellationsRefundsInitiateRefund,
      props: true,
      beforeEnter: authGuard,
      meta: {Roles: ['Admin','Finance'] }
  
    },  
  {
    path: '/instantsearch',
    name : 'TypeSenseSearch' ,
    component : TypeSenseSearch,
    props: true ,
    beforeEnter : authGuard,
    meta: {Roles: ['Admin'] }

  },
  {
    path: '/unauthorized',
    name : 'Unauthorized' ,
    component : Unauthorized,
    props: true ,
  },
  {
    path: '/default',
    name: 'default',
    component: LandingPage
  },
  { path: '/404', component: NotFound },  
  { path: '*', redirect: '/404' },

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
