<template>
 <finance-layout>
    <v-container fluid>
    <v-data-iterator
      :items="cardItems"
      :loading="mainLoader"
      hide-default-footer
    >
      <template v-slot:header>
        <v-alert v-if="isSuccess" type="success"> This transaction was successful </v-alert>
        <v-alert v-else type="error"> This transaction has failed </v-alert>
        <v-toolbar
          class="mb-2"
          color="accent"
          
          flat
        >
          <v-toolbar-title>Payout #{{id}}</v-toolbar-title>
          <v-toolbar-items>
          
          </v-toolbar-items>
        </v-toolbar>
        
      </template>
    
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="(item,i) in props.items"
            :key="i"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title >
                Timeslot-Id : {{ item.Timeslotid }}
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <!-- <v-list-item>
                  <v-list-item-content>Guest:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.calories }}
                  </v-list-item-content>
                </v-list-item> -->

                <v-list-item>
                  <v-list-item-content>Time:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.Timeslotdata }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Amount:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.Amount}} {{item.Amountcurrency}}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-for="(row,index) in item.Charges" :key="index">
                  <v-list-item-content> 
                    <v-text-field
                    v-model="row.Charge"
                    label="Charge"
                    required
                    ></v-text-field> 
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-text-field
                  v-model="row.Amount"
                  label="Amount"
                  type="text"
                  required
                ></v-text-field>
                </v-list-item-content>
            </v-list-item> 
                
                                         
                <!-- <v-list-item>
                                  
                        <v-list-item-content class="align-end">
                            <v-btn icon @click="addRow(item.Userpayoutsid)">  
                                <v-icon small right>mdi-plus-circle</v-icon>
                            </v-btn> 
                        </v-list-item-content>
                    
                </v-list-item> -->


            <v-list-item>
              <v-list-item-content>Charges :</v-list-item-content>
              <v-list-item-content class="align-end">
                {{individualCharges(item.Charges)}}

              </v-list-item-content>                    
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Amount  paid :</v-list-item-content>
              <v-list-item-content class="align-end">
                {{item.paymentAmount}}
              </v-list-item-content>
            </v-list-item>

              </v-list>

            </v-card>
          </v-col>
        </v-row>

        <v-card class="mt-4">
        <v-card-title> Penalties </v-card-title>
        <v-container>
            <v-row>
            <v-col> <b>Amount</b>  </v-col>
            <v-col> <b>Id </b>  </v-col>
            <v-col> <b>Ref Id </b> </v-col>
            </v-row>
            <v-row v-for="(penalty,l) in penalites" :key="l">

            <v-col> {{ penalty.Penaltyamount }}  {{ penalty.Penaltycurrency  }}</v-col>
            <v-col>  {{ penalty.Id }}</v-col>
            <v-col>  {{ penalty.Penaltydocid }}</v-col>

            
            </v-row>
        </v-container>
        </v-card>

        

      </template>

      <template v-slot:footer>
        <v-card class="mt-2 " color="accent"> 

        <v-card-title justify="center"> Summary  </v-card-title>
        <v-row class="ml-6"> <v-col> Stripe Transfer  Id : {{response.StripeTransferid   }}</v-col></v-row>
        <v-row class="ml-6"> <v-col> Transcation Status : {{response.Status   }}</v-col></v-row>
        <v-row class="ml-6"> <v-col> Timestamp : {{ response.UpdatedAt  }}</v-col></v-row>
        <v-row class="ml-6"> <v-col> Payout Amount :  {{ response.Amount  }} {{ response.Amountcurrency }} </v-col></v-row>
        </v-card>
      </template>
    </v-data-iterator>
  </v-container>
 </finance-layout>
  
</template>

<script>
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import {nocodb} from '@/axios'
export default {
    components: {FinanceLayout},
    props: ['id'],
    async created(){
        // Call api and fill data
        this.mainLoader = true
        const resp = await nocodb.get('PayoutsAttempts',{params: {where:`(Id,eq,${this.id})`}})
        console.log(resp)
        if(resp.data.list){
            this.cardItems = resp.data.list[0].InputData.Payouts
            this.totalPaidAmount = resp.data.list[0].Amount +' '+ resp.data.list[0].Amountcurrency
            resp.data.list[0].Status=="Completed" ? this.isSuccess = true : this.isSuccess = false
            this.penalites = resp.data.list[0].InputData.Penalties
            this.response = resp.data.list[0]

          
        }

        this.mainLoader = false
        


    },
    data() {
        return {
            cardItems: [],
            mainLoader: false,
            totalPaidAmount : '',
            isSuccess : null,
            penalites : null,
            respnse : null
        }
    },
    methods : {
        individualCharges(charges){
          var sum = 0 
          charges.forEach(element => {
            if(!Number.isNaN(parseFloat(element.Amount))  ){
              sum += parseFloat(element.Amount)
            }
          })

          return sum

        },
    }

}
</script>

<style>

</style>