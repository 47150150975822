<template>
    <UserHeader :email="email" :uid="uid" :joinedtimestamp="joinedtimestamp" :profilesharelink="profilesharelink" :rating="rating" :timeslotrequestcount="timeslotrequestcount" :username="username"> </UserHeader>
  
</template>

<script>
import UserHeader from '@/components/UserProfileComponents/UserProfileTopSection.vue'
export default {
    components: {UserHeader},
    data(){
        return {
            email: "ashik.sali@gmail.com",
            joinedtimestamp : '12313',
            profilesharelink : 'wwww.google.com',
            rating : 4,
            timeslotrequestcount : 2,
            username : "Shik Sal",
            uid : '1sadasfsfef'





        }
    }
}
</script>

<style>

</style>