<template>
  <finance-layout> 
     <v-tabs
      center-active
      grow
      v-model="tab"
      
    >
      <v-tab >
        
        All
      </v-tab>
      <v-tab> Success </v-tab>
      <v-tab> Failed </v-tab>

     <v-tabs-items v-model="tab"> 
        <v-tab-item> 
            <v-data-table
                    :loading="loader"
                    :headers="tableHeaders"
                    :items="tableContent"
                    item-key="Id"
                    :options.sync="options"
                    :server-items-length="totalRows"
                    :items-per-page="itemsPerPage"
                    @click:row="handleRowClick"
                    >
                    <!-- <template  v-slot:[`item.Charges`]="{ item }">

                        <v-list-item>
                        <v-list-item-content v-for="(charge,index) in item.Charges" :key="index">
                            <v-list-item-title>{{charge.charges}} : {{charge.Amount}}</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        {{renderCharges(item.Charges)}}
                    </template> -->

            </v-data-table>
        </v-tab-item>

        <v-tab-item>
             <SuccessTable/>
        </v-tab-item>

        <v-tab-item> <FailedTable/> </v-tab-item>

     </v-tabs-items>
    </v-tabs>

  </finance-layout>

</template>

<script>
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import {nocodb} from '@/axios'
import SuccessTable from '@/components/PayoutComponents/SuccessTable.vue'
import FailedTable from '@/components/PayoutComponents/FailedTable.vue'
export default {
    components: {FinanceLayout,SuccessTable,FailedTable},
    async created(){
        this.fillTable()

    },
    data() {
        return {
            tab: null,
            tableHeaders : [
                { text: 'PayoutId', value: 'Id',sortable: false, },
                { text: 'User', value: 'Entymuserid',sortable : false, },
                { text: 'TimeSlots', value: 'Timeslotids',sortable : false, },
                //{ text: 'Charges', value: 'Charges', sortable: false},

                { text: 'Staus', value: 'Status',sortable : false, },
                { text: 'Amount', value: 'Amount',sortable : false, },               
                { text: 'Time', value: 'CreatedAt',sortable: false, },
                { text: 'Payout Done by', value: 'PayoutDoneBy',sortable: false, },
            ],
            tableContent: [],
            successTableContent: [],
            failedTableContet: [],
            totalRows : 0 ,
            itemsPerPage : 20,
            options: {},
        }
    },
    methods : {
        async fillTable(offset){
            this.loader = true
            const data = await nocodb.get('PayoutsAttempts',{params: {limit :this.itemsPerPage, offset:offset,sort:'-CreatedAt' }})
            this.tableContent = data.data.list
            this.totalRows = data.data.pageInfo.totalRows

            this.loader = false

        },
        getPaginationOffset(pageNumber){
          let offset = 0;
          if (pageNumber > 1){
              offset = (pageNumber-1) * 10
          }
          return offset 
      },
      renderCharges(item){
        //Iterating through charges of multiple timeslots
        if(item != null){
            item.forEach(element => {
            element.charges.forEach(x => {
                console.log(x.Charge)
                                console.log(x.Amount)

            })
        });
        }
        else return ""
        
        return "Chargeee"
      },
      handleRowClick(event){
        let path = '/trackpayouts/' + event.Id 
        this.$router.push({path:path})
      }

     },
     watch : {
        options: {
            async handler(newValue){
                // Call Api according to the next page
                
                const offset = this.getPaginationOffset(newValue.page)
                await this.fillTable(offset)
            },
            deep: true
        }
     }


}
</script>

<style>

</style>