<template>
  <v-expansion-panels multiple v-model="panel" accordion>
            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Summary</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <v-row>
                            <v-col class="text-body-2">
                                Total Time slots booked:
                            </v-col>
                            <v-col class="text-body-2">
                                Value
                            </v-col>


                            <v-col class="text-body-2">
                                Abandoned bookings:
                            </v-col>
                            <v-col class="text-body-2">
                                Value
                            </v-col>
                        </v-row> -->

                </v-expansion-panel-content>
            </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
    
}
</script>

<style>

</style>