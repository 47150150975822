<template>
  <div>
        <v-navigation-drawer :mini-variant.sync="mini" v-model="drawer" clipped app color="primary">
            <v-list>
              
                <!-- <v-list-item v-if="$auth.isAuthenticated" to="/profile">
                <v-list-item-action>
                    <v-icon class="white--text"></v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="white--text">Profile</v-list-item-title>
                </v-list-item-content>
                </v-list-item> -->

                <!-- v-list-tile was replaced to v-list-item at Vuetify 2.0 -->
                <v-list-item>
                  <v-spacer v-if="mini==false"> </v-spacer>
                  <v-btn style="float: right;" v-if="mini == false"
                    icon
                    @click.stop="mini = !mini" >                    
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn v-if="mini == true" icon @click.stop="mini =!mini" >                    
                    <v-icon> mdi-chevron-right</v-icon>
                  </v-btn>

                </v-list-item>

                <v-list-item class="white--text" to="/">                                    
                  <v-list-item-action > <v-icon class="pl-2"> mdi-home </v-icon> </v-list-item-action>
                  <v-list-item-title>Home</v-list-item-title>
                  
                </v-list-item>

                  <v-expansion-panels >
                    <v-expansion-panel >
                      <v-expansion-panel-header class="primary white--text pl-0 ml-0">
                        <v-icon class="white--text pl-0 ml-0" > dashboard </v-icon> <span v-if="mini==false"> Dashboards </span> 
                      </v-expansion-panel-header>

                      <v-expansion-panel-content class="primary ma-0 pb-0">

                        <v-list class="primary white--text ma-0 pb-0"> 
                          <v-list-item v-for="(item,i) in dashboards" :key="i" :to="item.route" > 
                            
                            <v-list-item-content >
                                <v-list-item-title class="primary white--text">{{ item.label }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                      </v-expansion-panel-content> 
                      
                    </v-expansion-panel>
                  </v-expansion-panels>

                

                <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
                <v-list-item-action>
                    <v-icon class="white--text pl-2">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <AppBar @drawerFromChild="drawer = !drawer" />

        <!-- Sizes your content based upon application components -->
        <v-main mx-4>

            <!-- Provides the application the proper gutter -->
            <v-container fluid>

            <!-- If using vue-router -->
            <slot/>
            
            </v-container>
        </v-main>

        <v-footer app>
            <!-- -->
        </v-footer>

        <v-overlay
      :opacity="1"
      :value="overlay"
    >
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import AppBar from './AppBar.vue'
export default {
    props: ['links'],
    components : {
        AppBar
    },
    data() {
        return {
            overlay: true,
            drawer: true,
            group: null,
            mini : false,
            dashboards: [
              {label : "App Analytics" , route : "/appdashboard"},
              {label :"QR Link Analytics", route: "/qrlinkdashboard"}
            ],
        }

    },
    watch: {
      group () {
        this.drawer = false
      },
      
    },
    methods: {
       
    },
    mounted() {
      // hide the overlay when everything has loaded
      // you could choose some other event, e.g. if you're loading
      // data asynchronously, you could wait until that process returns
      this.overlay = false
    }, 
}
</script>

<style>

</style>