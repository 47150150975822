import { getInstance } from './index';

export const authGuard = (to, from, next) => {

  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      if(to.meta.Roles && authService.user["https://entym.app/roles"]){
        if(isAuthrorized(authService.user["https://entym.app/roles"],to.meta.Roles)){
          return next();
        }
              }
      if(authService.isAuthenticated && (to.meta.Roles == null || to.meta.Roles == undefined)) {
        return next();
      }
      else{
        return next({path: '/unauthorized'})
      }
      
    }


    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
};

// Check if user has permission 
const isAuthrorized = function(auth0Roles,metaRoles){
  let hasPermission = false
    auth0Roles.forEach((b)=>{
      metaRoles.forEach((a)=>{
       if(b == a){
        hasPermission = true
       }
      })
    })
  return hasPermission
}