<template>
    <dashboard-layout :links="links"> 
        <v-alert
        outlined
      type="info"
      elevation="0">
            Password  
            <v-text-field 
            class="mt-0 pt-0"
            outlined
            v-model="QRMapperNocodbpassword"
            :type="showPassowrd ? 'text' : 'password'"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassowrd = !showPassowrd" readonly style="width: 20%;"
            dense> 
        
            </v-text-field>
            
        </v-alert>
        
        <iframe class="nc-embed"
            src="https://ncdb.entym.app/dashboard/#/nc/view/3dbb459b-8818-466b-af94-2280b554d47c?embed"
            frameborder="0"
            width="100%"
            height="700"
            style="background: transparent; border: 1px solid #ddd"></iframe>
    </dashboard-layout>
  
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'

export default {
    computed: { 
      links() {
        return this.$store.state.links
      },
      
    },
    data(){
        return{
            QRMapperNocodbpassword: '$$UFC$$NFC',
            showPassowrd: false
        }
    },
    components: {
      DashboardLayout
    },
}
</script>

<style>

</style>