<template>
  <finance-layout>
    <v-container fluid :key="componentKey">
    <v-data-iterator
      :items="items"
      :loading="mainLoader"
      hide-default-footer

    >
      <template v-slot:header>
        <v-toolbar
          class="mb-2"
          color="accent"
          dark
          flat
        >
          <v-toolbar-title> Confirm Payout </v-toolbar-title>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="(item,i) in props.items"
            :key="item.name"
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card>
              <v-card-title >
                Timeslot-Id : {{ item.Timeslotid }}
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <!-- <v-list-item>
                  <v-list-item-content>Guest:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.calories }}
                  </v-list-item-content>
                </v-list-item> -->

                <v-list-item>
                  <v-list-item-content>Time:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.Timeslotdata }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Amount:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.Amount}} {{item.Amountcurrency}}
                  </v-list-item-content>
                </v-list-item>

                <v-card class="mx-2">
                  <v-card-title class="ml-3 mt-2 subtitle-1"> Charges : </v-card-title>
                  <v-card-text>
                    <ol>                
                      <li v-for="(k, index) in item.Charges" :key="index">
                        
                        <div  class="d-inline-flex">
                          <div class="mx-2 body-1  grey--text text--darken-4"> {{ k.Charge}} </div>
                          <div class="mx-2 body-1"> {{ k.Amount}}</div>
                        </div> 
                    </li>
                  </ol>  
                  </v-card-text>
                  </v-card>

                <!-- <v-list-item v-for="row in item.Charges" :key="row.ChargeId">
                  <v-list-item-content> 
                    <v-text-field
                    v-model="row.Charge"
                    :rules="chargeTextRules"
                    label="Charge"
                    required
                    ></v-text-field> 
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-text-field
                  v-model="row.Amount"
                  :rules="chargeAmountRules"
                  label="Amount"
                  type="text"
                  required
                ></v-text-field>
                </v-list-item-content>
            </v-list-item>  -->

            <v-list-item v-if="!item.showDropdown">                                  
                            <v-list-item-content class="align-end">
                                <v-btn icon @click="addRow(item.Userpayoutsid)"> 
                                  Add Charge  
                                <v-icon small right>mdi-plus-circle</v-icon>
                                    
                                </v-btn> 
                            </v-list-item-content>

                            <v-list-item-content class="align-end">
                                <v-btn  icon @click="deleteRow(item.Userpayoutsid)"> 
                                  Delete row 
                                    <v-icon small right>mdi-minus-circle</v-icon>
                                    
                                </v-btn> 
                            </v-list-item-content>                    
                    </v-list-item>

                  <div v-if="item.showDropdown">             
                    <v-select
                      class="mx-4"
                      :items="AddRowSelectOptions"
                      :menu-props="{ top: false, offsetY: true }"
                      label="Select the type of charge"
                      @change="AddRowOptionSelected"
                    ></v-select>
                      
                    <div>
                        <component v-bind:is="selectedComponent" v-on:percentInputSelect="handlepercentInputSelected(item,...arguments)" v-on:NumberInputSelect="handleNumberInputSelect(item,...arguments)" v-on:CustomInputSelect="handleCustomInputSelect(item,...arguments)"></component>
                    </div>
                  </div>
                                                         
            <v-list-item>
              <v-list-item-content>Charges :</v-list-item-content>
              <v-list-item-content class="align-end">
                <span>
                    {{individualCharges(item.Charges)}}
                </span>

              </v-list-item-content>                    
            </v-list-item>

            <v-list-item>
              <v-list-item-content> Amount to be paid :</v-list-item-content>
              <v-list-item-content class="align-end">
                <span> {{individualAmount(item.Charges,i)}}</span>
                <!-- <v-text-field
                label="Amount"i
                v-model="totalPaymentAmount"
                solo
                readonly
                ></v-text-field> -->
              </v-list-item-content>
            </v-list-item>

              </v-list>

            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>

        <v-card class="mt-4">
          <v-card-title> Penalties </v-card-title>
          <v-container>
            <v-row>
              <v-col> <b>Amount</b>  </v-col>
              <v-col> <b>Issued by </b> </v-col>
              <v-col> <b>Type </b>  </v-col>
              <v-col> <b>Date Issued </b> </v-col>
              <v-col>  <b>Status</b>  </v-col>
            </v-row>
            <v-row v-for="(penalty,index) in penaltyData" :key="index">
              <v-checkbox
                v-model="penalty.selected"
              
              ></v-checkbox>
              <v-col> {{ penalty.Penaltyamount }}  {{ penalty.Penaltycurrency  }}</v-col>
              <v-col>  {{ penalty.Issuedby }}</v-col>
              <v-col>  {{ penalty.Type }}</v-col>
              <v-col>  {{ penalty.Dateissued }} </v-col>
              <v-col> {{penalty.Status}}</v-col>
             
            </v-row>
            <v-row class="ma-2"><v-btn class="yellow" @click="penCount" > Apply Penalties </v-btn> </v-row>
          </v-container>
        </v-card>

        <v-card class="mt-2 " color="accent">

          <v-card-title justify="center"> Totals </v-card-title>
          <v-row class="ml-6"> <v-col>  Total Timeslot Value : </v-col> </v-row>
          <v-row class="ml-6"> <v-col> Total Penalty : {{ totalPenalty  }}</v-col></v-row>
          <v-row class="ml-6"> <v-col> Total Charges : {{  }}</v-col></v-row>
          <v-row class="ml-6"> <v-col> Payout Amount : {{ totalPaymentAmountIncludingCharges }}</v-col></v-row>
          <v-row>
            <confirm-dialog :payouts="items"> </confirm-dialog>
          </v-row>
          
        </v-card>
        <!-- <v-toolbar
          
          flat
         
        >
          <v-toolbar-title class="subheading">            
          </v-toolbar-title>
          
          <v-row> adssa</v-row>
          <v-toolbar-items >
             <v-list-item class="pa-0">
              <v-list-item-content  class="my-auto" > 
                <v-text-field
                
                :value="totalPaymentAmount()"
                  label="Total Amount"
                  solo
                  readonly
                 
                >  </v-text-field>
                
                </v-list-item-content>
              <v-list-item-content class="align-end my-auto">
                

              </v-list-item-content>                    
            </v-list-item>

            
         </v-toolbar-items>
        </v-toolbar> -->
      </template>
    </v-data-iterator>
  </v-container>

  </finance-layout>
</template>

<script>
import ConfirmDialog from '@/components/PayoutComponents/PayoutsConfirmDialog.vue'
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import PercentageInput from '@/components/PayoutComponents/V2/PercentageInput.vue'
import NumberInput from '@/components/PayoutComponents/V2/NumberInput.vue'
import CustomInput from '@/components/PayoutComponents/V2/CustomInput.vue'
import LocationComponent from '@/components/PayoutComponents/V2/LocationComponent.vue'
//import NProgress from 'nprogress'

import {nocodb} from '@/axios'
export default {
    components: {ConfirmDialog,FinanceLayout,PercentageInput,
    NumberInput,
    CustomInput,
    LocationComponent},
    props: ['userId'],

    data(){
        return {

            chargeTextRules : [
                v => v.length >=4 || 'Minimum 4 characters'
            ],
            chargeAmountRules : [
                v => /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(v) || 'Must be a valid Number',
            ],
            chargeName1: '',
            chargeAmount1: 0.00,
            chargeName2: '',
            chargeAmount2: 0.00,
            Amount : 0,
            items: [],
            amounts: [],
            charges: [],
            mainLoader: false,
            componentKey: 0,
            penaltyData : [],
            selectedComponent: {},
            showDropdown: false,
            AddRowSelectOptions: ["Percentage Charge","Number Charge","Custom Charge"],
            

        }
    },
    computed: {
      totalPenalty(){

        return this.penaltyData.reduce((accumulator,currentValue) => {
          if(currentValue.selected){
            return accumulator = accumulator +  parseFloat(currentValue.Penaltyamount)
          }
          return accumulator
          
        },0.0)
      },
      totalPaymentAmountIncludingCharges(){
        return this.totalPaymentAmount() - this.totalPenalty
      }
      // individualCharges(index){
      //   var sum = 0
      //   if(index || this.items[index.Charges]){
      //     return sum
      //   }
      //   this.items[index].Charges.forEach(e => {
      //      if(!Number.isNaN(parseFloat(e.Amount))  ){
      //         sum += parseFloat(e.Amount)
      //       }

      //   })
      //   return sum
      // }
        // totalPaymentAmount(){
        //     let sum = 0
        //     this.items.forEach(element => {
        //         sum += parseFloat(element.paymentAmount)
        //         console.log(element)
        //     });
        //     return sum
        // },
        // totalPaymentAmount(){
        //     return parseFloat(this.Amount) - this.totalCharges
        // },
    },
    async created(){
      this.mainLoader = true
      // Get the query params
      
        const idsquery = this.$route.query.ids.join()
        console.log(idsquery)      
        var resp = await nocodb.get('Userpayouts',{ params: {where: `(Userpayoutsid,in,${idsquery})~and(Status,eq,payoutpending)`}})       
        this.items = resp.data.list
        this.items.forEach((element,index) => {
          const Charges =  [
            
            ]
          this.items[index].Charges = Charges
          this.items[index]['showDropdown'] = false;
        })

        let penaltyResp = await nocodb.get('Userpenalty', {params : { where: `(Entymuserid,eq,${this.userId})`}})
        //Add selected value for checkbox
        let newKey = "selected"
        let newValue = false
        for (var i = 0; i < penaltyResp.data.list.length; i++) {
          penaltyResp.data.list[i][newKey] = newValue;
          
          }
        this.penaltyData = penaltyResp.data.list

        
        //this.penaltyData.map(obj => ({ ...obj, selected: false }));
        console.log(this.penaltyData, 'penalty with selected')
        

        this.mainLoader = false


    },
    methods : {
      AddRowOptionSelected(selectedOption){
          if (selectedOption === 'Percentage Charge') {
              this.selectedComponent = "PercentageInput"
          } else if (selectedOption === 'Number Charge') {
              this.selectedComponent = "NumberInput"
          }
          else if(selectedOption === 'Custom Charge'){
            this.selectedComponent = "CustomInput"
          }

        },
        // Handles event emitted from child
      handlepercentInputSelected(item,eventData){
        //Add the charge to Charges Object in Items array

        //Find the object from items array
        //Get ChargeId 
        this.items.forEach((i,index)=>{
          if(i.Userpayoutsid == item.Userpayoutsid){
            const ChargeId = i.Charges.length + 1
                    //Calculate Amount 

            const Amount = i.Amount * eventData.ChargePercent / 100
            const charge = {ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount}
            this.$set(this.items[index].Charges, this.items[index].Charges.length , charge)
            //this.$set(this.items[i],'charge'+ChargeId, true);
            this.$set(this.items[index],'showDropdown', false);
          }
        })
        
        
       
        //this.$set(this.items[0].Charges, ChargeId, "asdasd");
        //this.showDropdown = false

      },
      handleNumberInputSelect(item,eventData){
        console.log(eventData)
        console.log(item)
        const ChargeId = this.charges.length + 1
        const Amount = eventData.ChargeAmount 
        const index = this.items.findIndex( i => i.Userpayoutsid == item.Userpayoutsid )
        let charge = {ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount}
        //this.items[index].Charges.push({ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount})
        this.$set(this.items[index].Charges, this.items[index].Charges.length , charge)
        this.$set(this.items[index],'showDropdown', false);
        this.$forceUpdate();
        this.componentKey = this.componentKey + 1
        console.log('items',this.items[index])
      },
      handleCustomInputSelect(item,eventData){
        console.log(item)
        const ChargeId = this.charges.length + 1
        const Amount = eventData.ChargeAmount 
        this.charges.push({ChargeId : ChargeId , Charge : eventData.ChargeName, Amount : Amount})
        this.showDropdown = false
      },
      
        
        individualCharges(charges){
          var sum = 0 
          charges.forEach(element => {
            if(!Number.isNaN(parseFloat(element.Amount))  ){
              sum += parseFloat(element.Amount)
            }
          })

          return sum
            // Old Logic       
            // const charge = parseFloat(charge1) + parseFloat(charge2)
            // return Number.isNaN(charge) ? 0 : charge
        },
        individualAmount(charges,index){

          var sum = 0     
          charges.forEach(element => {
            if(!Number.isNaN(parseFloat(element.Amount))  ){
              sum += parseFloat(element.Amount)
            }
          })

          const amount = parseFloat(this.items[index].Amount) - sum
          this.items[index].paymentAmount = amount
          // const obj = { 
          //           index : index,
          //           amount : amount
          //       }
          // this.amounts.push(obj)
          return Number.isNaN(amount) ? 0 : amount 
          

            // var amount = 0 
            // try{
            //     //TODO: Check currency before adding
            //     amount=  parseFloat(this.items[index].Amount) - parseFloat(charge1) - parseFloat(charge2)
            //     this.items[index].paymentAmount = amount
            //     // const obj = { 
            //     //     index : index,
            //     //     amount : amount
            //     // }
            //     // this.amounts.push(obj)
            // }
            // catch(e){
            //     console.log(e)
            // }            
            // return Number.isNaN(amount) ? 0 : amount 
        },
        totalPaymentAmount(){
            let sum = 0.0
            this.items.forEach(element => {
                if(element.paymentAmount && !Number.isNaN(element.paymentAmount)){
                    sum += parseFloat(element.paymentAmount)
                }
            })
            //Add penalty to total payment amount
            sum - this.totalPenalty
            return sum
        },
        
        addRow(userpayoutsid){
          // Find the right object in the array
          var index = null
          this.items.forEach((i,ind) => {
            if(i.Userpayoutsid == userpayoutsid){
              //Get new chargeid
            const chargeId = i.Charges.length + 1;
            const charge = { ChargeId: chargeId , Charge: '', Amount :0};
            //this.items[i].Charges.push(charge)
            this.$set(this.items[ind].Charges, this.items[ind].Charges.length , charge)
            //this.$router.go(0);
            //const length = this.items[i].Charges.length
            //this.componentKey = this.componentKey + 1 ;
            //console.log(this.componentKey)
            
            this.$set(this.items[ind],'charge'+chargeId, true);
              index = ind
            }
          })
          
          this.$set(this.items[index],'showDropdown', true);
          
          this.$forceUpdate();
          console.log(this.items[index],'addRow Click')

          //this.items[index]['showDropdown'] = true
        //this.componentKey = this.componentKey + 1 ;
        },

        deleteRow(userpayoutsid){

          this.items.find((o,i) => {
            if(o.Userpayoutsid === userpayoutsid){
              this.items[i].Charges.pop()

              const chargeId = this.items[i].Charges.length + 1
              //delete this.items[i]['charge'+chargeId];
             this.$delete(this.items[i],'charge'+chargeId);             
            }
             
        });

        },
        penCount(){
          //Dummy function to trigger vue reactivity
          const tt=  this.penaltyData.reduce((accumulator,currentValue) => {
            
          if(currentValue.selected){
            console.log(currentValue, 'accumulator')
            console.log(accumulator)
            return accumulator = accumulator +  parseFloat(currentValue.Penaltyamount)
            
          }
          return accumulator
          
        },0.0)
        this.penaltyCc = tt
        console.log(this.penaltyCc)
        }
        
    }

}
</script>

<style>

</style>