<template> 
  <div class="d-inline-flex align-center mx-8">         
    <v-text-field
    v-model="Charge"
    :rules="chargeTextRules"
    label="Charge"
    required
    ></v-text-field> 
                               
    <v-text-field
    class="mx-2"
    v-model="Amount"
    :rules="chargeAmountRules"
    label="Amount"
    type="text"
    required
  ></v-text-field>
  <v-btn class="ml-2" @click="applyCharge"> Apply </v-btn>
                            
  </div>
</template>

<script>
export default{
  name: 'CustomInput',
  data(){
    return {
      chargeTextRules : [
          v => v.length >=4 || 'Minimum 4 characters'
      ],
      chargeAmountRules : [
          v => /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(v) || 'Must be a valid Number',
      ],
      Charge: "",
      Amount : 0

    }
  },
  methods: {
    applyCharge(){
        //Emits the event to listen in the parent component
        const data = {ChargeName: this.Charge, ChargeAmount : this.Amount }
        this.$emit('CustomInputSelect', data)
      }
  }
  
} 

</script>