<template>
  <div class="login">
    <p v-if="!valid">{{ error }}</p>
    <form @submit.prevent="onClickLogin">
      <input type="ID" placeholder="ID" v-model="id" />
      <input type="password" placeholder="Password" v-model="password" />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginOrigin",
  data: () => ({
    id: "",
    password: "",
    rules: {
      id: [
        (val) => !!val || "ID is required",
        (val) => val.length >= 5 || "A minimum of 5 characters is allowed",
      ],
      password: [(val) => !!val || "Password is required"],
    },
  }),
  computed: {
    valid() {
      return !this.error;
    },
    error() {
      const validId = this.rules.id.map((validFn) => validFn(this.id));
      const validPwd = this.rules.password.map((validFn) =>
        validFn(this.password)
      );
      let error = "";
      const validResult = validId.concat(validPwd);
      for (let i = 0, j = validResult.length; i < j; i++) {
        if (validResult[i] !== true) {
          error = validResult[i];
          break;
        }
      }
      return error;
    },
  },
  methods: {
    onClickLogin() {
      if (this.valid) {
        console.log("request to try login", this.id, this.password);
      } else {
        console.log("id and password is invalid");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  border: 1px solid black;
  border-radius: 5px;
  padding: 1.5rem;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  .container-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  form {
    display: flex;
    flex-flow: column;
    *:not(:last-child) {
      margin-bottom: 1rem;
    }
    input {
      padding: 0.5rem;
    }
    button {
      padding: 0.5rem;
      background-color: lightgray;
      border: 1px solid gray;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: lightslategray;
      }
    }
  }
}
</style>