<template>
  <dashboard-layout :links="links">

    <div v-if="loading" class="my-16" style="display:grid;place-items:center;">
        <v-progress-circular 
      indeterminate
      color="primary"
      size="80"
    ></v-progress-circular>
    </div>
    
    
    <v-container v-else>
        <v-alert v-if="postData.IsFlagged" type="warning">  This post has been flagged</v-alert>
        <v-alert v-if="reviewstatus === 'approved'" type="success"> This post has been approved </v-alert>
        <v-alert v-else-if="reviewstatus === 'blocked'" type="error"> This post has been blocked </v-alert>        
        <v-alert v-if="requestFailed" type="error"> Error occured, please contact admin </v-alert>
        <h2> Post #{{ postData.Userpostsid}} </h2>
        
        <v-row class="my-2">
            <v-col  cols="6"> <b> Posted By  : </b> <router-link :to="userRoutingUrl(postOwnerInfo.Entymuserid)"> {{postOwnerInfo.Name}}  </router-link> </v-col>
            <v-col> <b> Flagged By : </b> 
            <ul>
                <li v-for="(item,i) in flagedByNames" :key="i">
                     <router-link :to="userRoutingUrl(item.Entymuserid)"> {{ item.Name }}</router-link> </li>
            </ul>
            </v-col>
        </v-row>
        <v-row>
            <v-col  cols="6"> <b>Time : </b> {{readableDateTime(postData.Timestamp)}} </v-col>
        </v-row>   
        <v-row>
            <v-col  cols="6"> <b>Description : </b> {{postData.Description}} </v-col> 
            
        </v-row>
             

        <v-row v-if="postData['Userpostsmediaurl List'].length > 0 && postData.Mediatype != 'VIDEO'"  >
            <v-card max-width="50%" height="auto">
                <v-carousel >
                <v-carousel-item
                
                v-for="(item, i) in postData.mediaurls"
                :key="i"
                :src="item"
                class="carousel-item"
            >
            </v-carousel-item>

        </v-carousel>

            </v-card>
            
        </v-row>

        <v-row v-if="postData.Mediatype === 'VIDEO' ">
            <video  v-for="(item,i) in postData.mediaurls" :key="i" controls> <source :src="item"> </video> 
        </v-row>

        <v-row>
            <v-col> <b>Link :</b> <a :href="postData.Weblink"> {{postData.Weblink }}</a>  </v-col>
        </v-row>

        <v-row>
            <v-col cols="6"> <b>Tags : </b> {{ postData.tags }} </v-col> 
            
        </v-row>

        <v-row class="my-2">
            <v-col offset="2">                
                <v-dialog
                v-model="verifyDialog"
                persistent
                width="550"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                        v-if="!hideActionButtons"
                        class="ma-2"                            
                        color="green"
                        text-color="white"
                        v-on="on"
                        v-bind="attrs"
                        >
                        Approve Content 
                        <v-avatar right>
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-avatar>
                        
                        </v-chip>
                    </template>
                    
                    <v-card v-if="dialogLoading" >
                        <v-progress-circular 
                    indeterminate
                    color="primary"
                    size="60"
                    ></v-progress-circular>

                    </v-card>
                    

                    <v-card v-else>
                        <v-card-title class="text-h5 grey lighten-2">
                            Are you sure you want to approve content?
                        </v-card-title>

                        <v-card-text>
                            Please verify the detais and then click "Approve" 
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="verifyDialog = !verifyDialog">
                            Cancel
                        </v-btn>
                        <v-btn 
                            color="primary"
                            text
                            @click="verifyItem('approved')"
                        >
                            Approve
                        </v-btn>

                        </v-card-actions>
                    </v-card>

                </v-dialog>

                <v-dialog
                v-model="reportDialog"
                width="500"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                    v-if="!hideActionButtons"
                    class="ma-2"                            
                    color="red"
                    text-color="white"
                    v-bind="attrs"
                    v-on="on"
                    >
                    Block Content
                    <v-avatar right>
                        <v-icon>mdi-close-circle</v-icon>
                    </v-avatar>
                    
                    </v-chip>
                </template>

                <v-card v-if="dialogLoading">
                    <v-progress-circular 
                    indeterminate
                    color="primary"
                    size="60"
                    ></v-progress-circular>
                </v-card>
                

                <v-card v-else>
                    <v-card-title class="text-h5 grey lighten-2">
                    Are you sure you want to block content ?
                    </v-card-title>
                    <v-card-text>
                        Please verify the detais and then click "Block" 
                    </v-card-text>
                    
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary"  text @click="reportDialog = !reportDialog">
                            Cancel
                        </v-btn>
                    <v-btn 
                        color="primary"
                        text
                        @click="verifyItem('blocked')"
                    >
                        Block
                    </v-btn>
                    </v-card-actions>
                </v-card>

                </v-dialog>

                

            </v-col>
            
        </v-row>
    </v-container> 
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'

import {nocodb,entymApi} from '@/axios'
export default {
    components: {DashboardLayout},
    props: ['postid'],
    computed : {
        links() {
            return this.$store.state.links
        },
        
    },
    data(){
        return {
            url : '',
            verifyDialog: false,
            reportDialog: false,
            MediaUrls: [],
            postData: {},
            flags: [],
            flagedBy: [],
            flagedByNames : [],
            postOwnerInfo : {},
            loading: false,
            reviewstatus : '',
            dialogLoading: false,
            requestFailed: false,
            hideActionButtons: false,
            
        }
    },
    async created(){
        this.loading = true
        //Get all data => Userpostflags,Userpostsmediatags,Userpostsmediaurl,Userpoststhumbnailurlinsta
        const postsresp = await nocodb.get('Userposts',{params: {where : `(Userpostsid,eq,${this.postid})`}})
        const mediaurlresp = await nocodb.get('Userpostsmediaurl',{params: {where : `(Userpostsid,eq,${this.postid})`}})
        const mediatagsresp = await nocodb.get('Userpostsmediatags',{params: {where : `(Userpostsid,eq,${this.postid})`}})
        const thumbnailurlinstaresp = await nocodb.get('Userpoststhumbnailurlinsta',{params: {where : `(Userpostsid,eq,${this.postid})`}}) 
        
        //get Post Flags
        const postflagsresp = await nocodb.get('Userpostflags', {params: {where : `(Postid,eq,${this.postid})`}})
        this.flags = postflagsresp.data.list
        this.flagedBy = postflagsresp.data.list.map( a => a.Postflaggedbyid)
        //Get Usernames 
        if(this.flagedBy.length > 0 ){ 
            let params = this.flagedBy[0]
            if(this.flagedBy.length > 1){
                this.flagedBy.forEach(element => {
                params = params + ','+  element
            });
            }            
            
            const ress = await nocodb.get('Userpersonaldata',{params: {where:(`(Entymuserid,in,${params})`)}})
            this.flagedByNames = ress.data.list


        }

        //Add posts data to main post object
        this.postData = {...postsresp.data.list[0]}
        //Check if already reviewed , if reviewed hide action buttons
        if(this.postData.Reviewstatus != null ){
            this.reviewstatus = this.postData.Reviewstatus
            this.hideActionButtons = true
        }
        //If mediaurls are present , add to main object
        if(mediaurlresp.data.list.length > 0){
            let mediaurls = mediaurlresp.data.list.map(a => a.MediaUrl)
            this.postData.mediaurls = mediaurls
        }
        if(mediatagsresp.data.list.length > 0){
            let tags = mediatagsresp.data.list.map( a => a.Tags)
            this.postData.tags = tags

        }
        if(thumbnailurlinstaresp.data.list.length > 0){
            let instathumbnails = thumbnailurlinstaresp.data.list.map(a => a.Thumbnailurlinsta)
            this.postData.instathumbnails = instathumbnails

        }

        this.url = postsresp.data.list[0].MediaUrl
        this.MediaUrls = postsresp.data.list.map(a => a.MediaUrl);

        //Getuserpersonaldata for the post owner to display
        const Userpersonaldataresp = await nocodb.get('Userpersonaldata',{params: {where:(`(Entymuserid,in,${this.postData.Entymuserid})`)}})
        this.postOwnerInfo = Userpersonaldataresp.data.list[0]

        this.loading = false
        
    },
    methods :{
        async verifyItem(Status){
            //Logic to verify content
            const body = {
                postid: this.postid,
                verifiedby : this.$auth.user.email,
                status : Status
            }
            this.dialogLoading = true
            try{
                const resp = await entymApi.post('ContentModeration/VerifyPost',body)
                if(resp.status == 200){
                //Approved indicator , close dialog , hide buttons and redirect back
                this.reviewstatus = Status
                this.requestSuccess = true
                this.hideActionButtons = true
                }


            }
            catch(e){
                console.log(e)

                this.requestFailed = true
            }
            finally{
                this.dialogLoading = false
                this.verifyDialog = false
                this.reportDialog = false
                
            }
            
                    
            
        },
        userRoutingUrl(id){
            return '/users/'+id
        },
        readableDateTime(val){
            const d = new Date(val);
            return d.toUTCString()
        }
        
    }
}
</script>

<style>

</style>