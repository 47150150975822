var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('finance-layout',[_c('v-card',[_c('v-card-title',[_vm._v(" Payouts "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","headers":_vm.payoutsTableHeaders,"items":_vm.payoutsTableContent,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading":_vm.loader,"loading-text":"Loading... Please wait","item-key":"name"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.UserId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'User' , params: {userId:item.UserId} }}},[_vm._v(" "+_vm._s(item.UserId)+" ")])]}},{key:"item.Timeslotdata",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.Timeslotdata).toString()))])]}},{key:"item.Timeslot-id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.Timeslotid)+" "+_vm._s(("( " + (item.Timeslottrackerid) + " )"))+" ")])]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true},{key:"item.Action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.redirectToPayoutsConfirm(item)}}},[_vm._v(" Complete Payout "),_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-arrow-right-bold ")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }