<template>

<finance-layout>
  <v-card>
    <v-card-title>
      Payouts
      <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details>
      </v-text-field>


      
    </v-card-title>
  <v-data-table
        dense
        hide-default-footer
        :headers="payoutsTableHeaders"
        :items="payoutsTableContent"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :loading = "loader"
        loading-text="Loading... Please wait"
        item-key="name"
        class="elevation-1">
            <template  v-slot:[`item.UserId`]="{ item }">
                        <router-link :to="{ name: 'User' , params: {userId:item.UserId} }">
                            {{ item.UserId }}
                        </router-link>
            </template>
            <template v-slot:[`item.Timeslotdata`]="{ item }">
                <span>{{ new Date(item.Timeslotdata).toString() }}</span>
            </template>
            <template v-slot:[`item.Timeslot-id`]="{item}">
                <span> {{item.Timeslotid}} {{ `( ${item.Timeslottrackerid} )`}} </span>
            </template>
            <template v-slot:footer>
              <v-pagination
                v-model="page"
                :length="pageCount"
            ></v-pagination>
          </template>

          <template v-slot:[`item.Action`]="{ item }">
            
            <v-btn
              small
              color="primary"
              dark
              @click="redirectToPayoutsConfirm(item)"
            >
              Complete Payout

              <v-icon
                
                class="mr-2"
                
            >
                mdi-arrow-right-bold
            </v-icon>
            </v-btn>
            
            
    </template>
        
        </v-data-table>

  </v-card>

</finance-layout>

</template>

<script>
import {nocodb} from '@/axios';
import FinanceLayout from '@/layouts/FinanceLayout.vue'
export default {
  components: {
    FinanceLayout
  },
    data(){
        return{
            search: '',
            loader : false,
            options: {},
            page: 0 ,
            itemsPerPage : 0 ,
            totalPayouts: 0 ,
            pageCount : 0,
            payoutsTableHeaders: [
                {text:'UserId' , align:'start' , sortable : 'false' , value : 'Entymuserid'},
                {text: 'Name' , sortable : 'false', value: 'Name'},
                {text: 'Amount' , sortable : 'false', value: 'Amount'},
                {text: 'Time' , value: 'Timeslotdata'},
                {text: 'Timeslot Id' ,  sortable : 'false' ,value: 'Timeslot-id'},
                {text: 'Action' , sortable : 'false', value: 'Action'},

            ],
            payoutsTableContent : [],
        }
    },
    methods: {
        async getPendingPayouts(){
            // Set loader to true
            this.loader = true
            //Call Api
            let resp = await nocodb.get('PayoutsView', {params: {sort : 'Lastmodifiedtimestamp', offset: this.getPaginationOffset(), limit : 25}})
            this.payoutsTableContent = resp.data.list
            console.log(resp.data)
            this.totalPayouts = resp.data.pageInfo.totalRows
            this.page = resp.data.pageInfo.page
            this.itemsPerPage = parseInt(resp.data.pageInfo.pageSize)
            this.pageCount = this.getPageCount()
            //Finally set loader to false
            this.loader = false
            },
        getPaginationOffset(){
            let offset = 0;
            if (this.page > 1){
                offset = (this.page-1) * this.itemsPerPage
            }
            console.log(`Offset ${offset} , page`)
            return offset 
        },
       getPageCount(){
        return  Math.ceil(this.totalPayouts / this.itemsPerPage)
       },
       redirectToPayoutsConfirm(item){
        this.$router.push({name:'payoutsconfirm',params:{ payoutid:item.Userpayoutsid , obj : {...item}}})
       }

    },
    computed : {

    },
     watch: {
      page: {
        handler () {
          this.getPendingPayouts()
        },
        deep: true,
      },
    },
    async created(){
        await this.getPendingPayouts()
    }

}
</script>

<style>

</style>