<template>
  <dashboard-layout :links="links">
     <v-tabs v-model="activeTab" grow>
        <v-tab>Pending</v-tab>
        <v-tab> Reviewed </v-tab>
     </v-tabs>
     <v-tabs-items v-model="activeTab"> 
        <v-tab-item>
             <v-data-table
                :loading="loader"
                :headers="headers"
                :items="posts"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                :options.sync="options"
                :server-items-length="totalRows"
                item-key="Userpostsid"
                show-expand
                class="elevation-1"
                @click:row="handleRowClick"
                @item-expanded="handleExpand"
            >
                <!-- <template v-slot:top>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-switch
                    v-model="singleExpand"
                    label="Single expand"
                    class="mt-2"
                    ></v-switch>
                </v-toolbar>
                </template> -->
                <template v-slot:[`item.isFlagged`]="{ item }">
                    <div v-if="item.IsFlagged == 1">
                        <v-chip  color="yellow"> Flagged</v-chip>
                    </div>
                    
                </template>
                
                <!-- <template v-slot:[`item.Timestamp`]="{ item }">
                   {{ readableDateTime(item) }}
                </template> -->
                <!-- <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" >
                        
                        <v-row >
                            <v-col  cols="6"> <b>Description : </b>  {{item.Description}}</v-col> 
                            <v-col> <b> Flags  </b> </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="6"> <b>Tags : </b> {{item.postTags}} </v-col>                             
                        </v-row>

                    </td>                              
                </template> -->

                
            </v-data-table>

            
        </v-tab-item>
        <v-tab-item> <content-moderation-reviewed> </content-moderation-reviewed> </v-tab-item>
     </v-tabs-items>

  </dashboard-layout> 
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import ContentModerationReviewed from '@/components/ContentModerationReviewed.vue'
import {nocodb} from '@/axios'

export default {
    components: { DashboardLayout , ContentModerationReviewed},
    computed : {
        links() {
            return this.$store.state.links
        }
     },
     data(){
        return{
            activeTab : '',
            loader: false,
            expanded: [],
            singleExpand: true,
            itemsPerPage : 10,
            headers: [
            {text: '#',align: 'start', sortable: false, value: 'isFlagged',width:1},
            { text: 'Post', align: 'start',sortable: false,value: 'Caption',},
            { text: 'Posted By', sortable: false,  value: 'Name' },
            {text: 'Time (YYYY-MM-DD)', sortable: false,  value: 'Timestamp'}],
            posts: [],
            mediaUrls: [],
            verifyDialog: false,
            reportDialog: false,
            options: {},
            totalRows: 0,

        }
     },
     async mounted(){

        this.fillTable(0)
     },
     methods : {
        async fillTable(offset){
            this.loader = true
            const data = await nocodb.get('UserPostsModerationView',{params: {where : '(Isreviewed,eq,0)', limit :this.itemsPerPage, offset:offset }})
            console.log(data.data)
            this.posts = data.data.list
            // this.posts.forEach(x => {
            //     let date = new Date(x.Timestamap)
            //     x.Timestamap = date.toUTCString()
            // })
            this.totalRows = data.data.pageInfo.totalRows
            this.loader = false

        },
        getPaginationOffset(pageNumber){
          let offset = 0;
          if (pageNumber > 1){
              offset = (pageNumber-1) * 10
          }
          return offset 
        },
        async handleExpand(event){
            console.log("Expanded")
            const resp = await nocodb.get('Userpostsmediaurl',{params: {where : `(Userpostsid,eq,${event.item.Userpostsid})`}})
            let obj = this.posts.find(a => a.Userpostsid == event.item.Userpostsid)
            obj.MediaUrl = resp.data.list[0].MediaUrl

            
        },
        handleRowClick(event){
            console.log(event)
            let path = '/moderate/posts/' + event.Userpostsid
            this.$router.push({path:path})
        },
        readableDateTime(val){
            const d = new Date(val);
            return d.toUTCString()
        }

     },
     watch : {
        options: {
            async handler(newValue){
                // Call Api according to the next page
                
                const offset = this.getPaginationOffset(newValue.page)
                await this.fillTable(offset)
            },
            deep: true
        }
     }
}
</script>

<style>

</style>