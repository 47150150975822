<template>
  <dashboard-layout :links="links">
    <v-alert
      outlined
      type="info"
      elevation="0"
    >
      To view the existing globaldata tables, visit <a href="https://ncdb.entym.app/dashboard/#/nc/p_wum6wrdatg75id"> https://ncdb.entym.app/dashboard/#/nc/p_wum6wrdatg75id </a>
       <div class="black--text mt-2">
        Credentials:
       </div>
      <div>
       
        <v-text-field outlined dense readonly label="Username" class="pt-2"  style="width: 20%;" value="test@entym.com"> </v-text-field>
        <v-text-field 
        class="mt-0 pt-0"
        outlined
      label="Password"
      v-model="nocodbpassword"
       :type="show1 ? 'text' : 'password'"
       :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
       @click:append="show1 = !show1" readonly style="width: 20%;"
       dense> 
       
      </v-text-field>

      </div>
      
    
    </v-alert>
    <v-toolbar color="accent" > <v-toolbar-title> Review  User Entered Global Data  </v-toolbar-title>    </v-toolbar>
    
    <v-data-table
        :headers="tempGlobalDataHeaders"
        :items="tempGlobalDataContent"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :loading = "loader"
        loading-text="Loading... Please wait"
        item-key="name"
        class="elevation-1">
            <template  v-slot:[`item.Timestamp`]="{ item }">
                      {{ getTimeString(item.Timestamp) }}
            </template>

            <template  v-slot:[`item.Data`]="{ item }">
                      <div class="d-flex flex-column">
                        <div v-for="(i,index) in item.Data" :key="index">
                            {{ index }} : {{ i  }}
                        </div>
                      </div>
            </template>
      

          <template v-slot:[`item.Action`]="{ item }">
            
            <v-btn small color="red"  @click="deleteItem(item)">
              Delete
              <v-icon  class="mr-2"> mdi mdi-delete-off </v-icon>
            </v-btn> 
            <v-btn class="ml-2" small  @click="routeToAddItemPage(item)">
              Add
              <v-icon  class="mr-2"> mdi-arrow-right-bold </v-icon>
            </v-btn>                        
          </template>
        
        </v-data-table>

    <!-- <v-card>
      <v-toolbar color="primary"> <v-toolbar-title>Global Data</v-toolbar-title> </v-toolbar>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$router.push({path:'/globaldata/main'})">
            <v-list-item-icon>
            <v-icon > mdi-database-eye </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Global Data View </v-list-item-title>
          </v-list-item-content>
          </v-list-item> 
          <v-list-item @click="$router.push({path:'/globaldata/temp'})">
            <v-list-item-icon>
            <v-icon > mdi-database-plus </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Global Data Temporary Insert </v-list-item-title>
          </v-list-item-content>
          </v-list-item> 
        </v-list-item-group>
      </v-list>
    </v-card> -->
    
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import { dashboarddb } from '@/axios'

export default {
    components: {
        DashboardLayout,
    },
    computed: { 
      links() {
        return this.$store.state.links
      },
      
    },
    async created(){
      await this.getData()

    },
    data(){
      return {
        show1: false,
        nocodbpassword : "ywW&J9^{!Wd{SEV3",
        search: '',
        loader : false,
        options: {},
        page: 0 ,
        itemsPerPage : 0 ,
        totalRows : 0,
        pageCount : 0,
        tempGlobalDataHeaders: [
        {text:'Table Name' , align:'start' , sortable : 'false' , value : 'Tablename'},
        {text:'UserEmail' , align:'start' , sortable : 'false' , value : 'Emailid'},
        {text:'Data' , align:'start' , sortable : 'false' , value : 'Data'},
        {text:'Timestamp ' , align:'start' , sortable : 'false' , value : 'Timestamp'},
        {text:'Action' , sortable: 'false', value:'Action'}],
        tempGlobalDataContent : []
      }
    },
    methods : {
      async getData(){
            // Set loader to true
            this.loader = true
            //Call Api
            let resp = await dashboarddb.get('TempGlobalDataView', {params: {where: '(Isreviewed,eq,0)', offset: this.getPaginationOffset(), limit : 25}})
            this.tempGlobalDataContent = resp.data.list
            console.log(resp.data)
            this.totalRows = resp.data.pageInfo.totalRows
            this.page = resp.data.pageInfo.page
            this.itemsPerPage = parseInt(resp.data.pageInfo.pageSize)
            this.pageCount = this.getPageCount()
            //Finally set loader to false
            this.loader = false
            },

      getPaginationOffset(){
            let offset = 0;
            if (this.page > 1){
                offset = (this.page-1) * this.itemsPerPage
            }
            return offset 
        },
     getPageCount(){
        return  Math.ceil(this.totalPayouts / this.itemsPerPage)
       },
      getTimeString(input){
        let inpDate = new Date(input)
        return inpDate.toISOString()
       },

       routeToAddItemPage(input){
        this.$router.push({name:'globaldataadd', params:{ id: input.Id , obj : {...input}}})
       },
       async deleteItem(item){
        this.loader = true
        console.log('From delete')
        console.log(item)
        const body = {
          isdeleted : 1 , 
          Isreviewed : 1, 
          Reviewedby : this.$auth.user.email
        }
        const resp = await dashboarddb.patch(`TempGlobalData/${item.Id}`, body)
        console.log(resp)
        //Refill the table (loader will be switched off inisde this function)
        this.getData()
        
       }
    },

    watch: {
      page: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    }

    
}
</script>

<style>

</style>