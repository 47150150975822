<template>
    
    <dashboard-layout :links="links">

      <v-card :height="windowHeight" v-if="loader" class="grid">
          <v-progress-circular indeterminate :size="80" color="primary" ></v-progress-circular>
     </v-card>

      <iframe
    :src="url"
    frameborder="0"
    :height="windowHeight"
    width="100%"
    allowtransparency
></iframe>
    </dashboard-layout>

</template>

<script>

import DashboardLayout from '@/layouts/DashboardLayout.vue'
import {mapState} from 'vuex'
import {entymApi} from '@/axios'
  export default {
    computed: { 
      links() {
        return this.$store.state.links
      },
      ...mapState(['mainCardData','secondRowData', 'regularUsersTable']) }
    ,
    components: {
      DashboardLayout
    },
    data(){
      return {
        loader:false,
        alignments: [
        'start',
        'center',
        'end',
      ],
      url : '',
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      }
    },
    async created() {
      //Set Loader to true
      this.loader = true

      //Get the screen height and width
      window.addEventListener("resize", this.getScreenDimensions);

      // Call Store ?? and Get Metabase URL
      this.$store.dispatch('getFirstrowData')
      // Axios interceptor to refresh token when expired

      let resp = await entymApi.get('Dashboard/MetabaseMainDash')
      this.url = resp.data.url

      //Load off
      this.loader = false


    },
    mounted() {
      
    },
    destroyed() {
      window.removeEventListener("resize", this.getScreenDimensions);
    },
    methods: {
      printEvent(data) {
        // Send date to API and Update the store
        console.log(data)
      },
      getScreenDimensions(){
        //Gets the new height and width
        this.windowHeight = window.innerHeight
        this.windowWidth = window.innerWidth
      }
    }
  }
</script>

<style scoped>
.grid{
  display: grid;
  place-items: center;
}
</style>
