<template>
    <v-dialog
              v-model="dialog"
              persistent
              width="500"
            >
          <template v-slot:activator="{ on, attrs }">

            <v-btn width="40%" 
            v-on="on"
            v-bind="attrs"
            class="mx-auto mb-4" 
            color="success" 
            large
            >
            {{ButtonName}}
            </v-btn>

          </template>

          <v-card v-if="!loading && isAuthenticated">
              <v-card-title class="primary" > Confirm</v-card-title>
              <v-card-text class="text-subtitle-1 font-weight-bold" dark> 
                
                <div> Please review the details before clicking confirm </div>
                
                <!-- <div v-for=" (item,index) in payoutsObject" :key="index">
                  <ol>
                  <li v-for="(k,innerIndex) in  item.Charges" :key="innerIndex">
                    <div class="d-inline-flex ">
                      <div> Charge: {{ k.Charge }}</div>
                      <div> Amount: {{ k.Amount }}</div>
                    </div>
                  </li>

                </ol>
                </div> -->



              </v-card-text>
              <v-divider></v-divider>



              <v-card-actions class="d-flex justify-center align-center">
                <v-btn @click="dialog= false"> 
                  Cancel
                </v-btn>

                <v-btn
                  color="primary"
                  @click="confirmPayout()"
                >
                  Confirm
                </v-btn>
                
              </v-card-actions>
          </v-card>

          <v-container fluid fill-height v-if="!isAuthenticated">
              <v-layout align-center justify-center>
              <v-flex>
                  <v-form @submit.prevent="signinToFirebase()">
                  <v-card class="elevation-12">
                      <v-toolbar dark color="primary">
                      <v-toolbar-title>Login</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                      <v-text-field
                          v-model="id"
                          prepend-icon="person"
                          name="login"
                          label="Login"
                          
                          type="text"
                      ></v-text-field>
                      <v-text-field
                          v-model="password"
                          prepend-icon="lock"
                          name="password"
                          label="Password"
                          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                          @click:append="() => (showPassword = !showPassword)"
                          :type="showPassword ? 'text' : 'password'"
                      ></v-text-field>
                      <p v-if="loginError" class="red--text" >{{errorMessage}}</p>
                      </v-card-text>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <!-- Check that the SDK client is not currently loading before accessing is methods -->
                      <div>
                        <v-btn @click="dialog = false"> Cancel </v-btn>
                      <v-btn  type="submit" @click="signinToFirebase()" color="primary">Login</v-btn>
                      
                      </div>
                      </v-card-actions>
                  </v-card>
                  </v-form>
              </v-flex>
              </v-layout>
          </v-container>






    </v-dialog>
</template>

<script> 
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
//var firebaseui = require('firebaseui');
import "firebaseui/dist/firebaseui.css";
export default{
    props: ['ButtonName'],
    data(){
        return {
            apiMessage : '',
            apiSuccess: "",
            loading :  '',
            bearerToken : '',
            isAuthenticated : '',
            loginError : '',
            showPassword: false,
            password: '',
            id : '',
            dialog: false
        }
    },
    methods : {
        signinToFirebase(){
            
            const auth = getAuth();
            signInWithEmailAndPassword(auth, this.id, this.password)
            .then((userCredential) => {
                // Signed in 
                this.bearerToken = ''
                this.bearerToken = userCredential.user.accessToken;
                console.log(this.bearerToken)            
                this.isAuthenticated = true
                this.$emit('authenticated', this.bearerToken)
                // Emit event along with bearer tokens
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode,errorMessage)
                this.loginError =true
                this.$emit('authFailed',error)
            });
        }
    }
}

</script>