<template>
<v-card class="mx-auto mt-12 " min-width="600" elevation=3>
    
    <v-toolbar  elevation="4" color="primary">       
        <v-toolbar-title> Payout for TimeSlot : {{obj.Timeslottrackerid}} </v-toolbar-title>
     </v-toolbar>
    <v-card-text> </v-card-text>

    <v-container>
        <v-row>
            <v-col > UserName :  </v-col> <v-col> {{obj.Name}}</v-col>
            <v-col > UserId : </v-col><v-col> {{obj.Entymuserid}}</v-col>
        </v-row>

        <v-row>
            <v-col> Amount : {{obj.Amount}} </v-col> 
            <v-col> Time : {{new Date(obj.Timeslotdata).toUTCString() }}</v-col>
        </v-row>

        <v-row class="mx-auto">
            <v-card class="pl-2">
            <v-row>
                <v-col class="ma-2"> Charges:  </v-col> <v-col> </v-col>
            </v-row>
            <v-row>
                <v-col class="ml-2" cols="6"  md="4"> <v-text-field
                    v-model="chargeName1"
                    :rules="chargeTextRules"
                    label="Charge 1"
                    required
                ></v-text-field>
                </v-col> 
                <v-col cols="6"  md="4">
                <v-text-field
                    v-model="chargeAmount1"
                    :rules="chargeAmountRules"
                    label="Amount"
                    required
                ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col  class="ml-2" cols="6"  md="4"> <v-text-field
                    v-model="chargeName2"
                    :rules="chargeTextRules"
                    label="Charge 2"
                    required
                ></v-text-field>
                </v-col> 
                <v-col cols="6"  md="4">
                <v-text-field
                    v-model="chargeAmount2"
                    :rules="chargeAmountRules"
                    label="Amount"
                    required
                ></v-text-field>
                </v-col>
            </v-row>
            <v-row >
                <v-col  class="ml-2" cols="4">
                    <label> Total Charges : </label>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                    label="Total"
                    placeholder="00.00"
                    solo
                    v-model="totalCharges"
                    readonly
                ></v-text-field>

                </v-col>
                
            </v-row>         
        </v-card>

        </v-row>
        <v-row>
            <v-col cols="4">
                <label>
                    Amount to be paid
                </label>

            </v-col>
            <v-col  cols="4">
                    <v-text-field
                    label="Total Amount"
                    v-model="totalPaymentAmount"
                    solo
                    readonly
                ></v-text-field>
            </v-col>
        </v-row>
        
        <v-row>
            <confirm-dialog> </confirm-dialog>


        </v-row>

        
    </v-container>
</v-card>

</template>

<script>
import ConfirmDialog from '@/components/PayoutComponents/PayoutsConfirmDialog.vue'
export default {
    components: {ConfirmDialog},
    data(){
        return {
            chargeTextRules : [
                v => v.length >=4 || 'Minimum 4 characters'
            ],
            chargeAmountRules : [
                v => /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(v) || 'Must be a valid Number',
            ],
            chargeName1: '',
            chargeAmount1: 0.00,
            chargeName2: '',
            chargeAmount2: 0.00,
        }
    },
    props: { obj : Object},
    async created(){

        console.log(this.$router.params)
        console.log(this.obj)

    },
    computed: {
        totalCharges: function(){
            return parseFloat(this.chargeAmount1) + parseFloat(this.chargeAmount2)
        },
        totalPaymentAmount(){
            return parseFloat(this.obj.Amount) - this.totalCharges
        },
        
        // payoutAmount: function(){
        //     return  parseFloat(this.) parseFloat(totalCharges) 
        // }
    }



}
</script>

<style>

</style>