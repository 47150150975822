<template>
  <dashboard-layout :links="links">
     <v-tabs v-model="activeTab" grow>
        <v-tab>Pending</v-tab>
        <v-tab> Reviewed </v-tab>
     </v-tabs>
     <v-tabs-items v-model="activeTab"> 
        <v-tab-item>
             <v-data-table
                :headers="headers"
                :items="posts"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="Userpostsid"
                show-expand
                class="elevation-1"
            >
                <!-- <template v-slot:top>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-switch
                    v-model="singleExpand"
                    label="Single expand"
                    class="mt-2"
                    ></v-switch>
                </v-toolbar>
                </template> -->
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" >
                        <v-container fluid>
                        <v-row >
                            <v-col  cols="6"> <b>Description : </b>  {{item.postContent}}</v-col> 
                            <v-col> <b> Flags  </b> </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="6"> <b>Tags : </b> {{item.postTags}} </v-col> 
                            
                        </v-row>
                        <v-row class="my-2">
                            <v-col offset="6">

                                
                                <v-dialog
                                v-model="veriryDialog"
                                width="500"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                        class="ma-2"                            
                                        color="green"
                                        text-color="white"
                                        v-on="on"
                                        v-bind="attrs"
                                        >
                                        Verify
                                        <v-avatar right>
                                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                                        </v-avatar>
                                        
                                        </v-chip>
                                    </template>

                                    <v-card>
                                        <v-card-title class="text-h5 grey lighten-2">
                                        Privacy Policy
                                        </v-card-title>

                                        <v-card-text>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="verifyItem(item)"
                                        >
                                            Verify
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>

                                </v-dialog>

                                <v-dialog
                                v-model="reportDialog"
                                width="500"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                    class="ma-2"                            
                                    color="red"
                                    text-color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    Report Comment
                                    <v-avatar right>
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-avatar>
                                    
                                    </v-chip>
                                </template>

                                <v-card>
                                    <v-card-title class="text-h5 grey lighten-2">
                                    Verify
                                    </v-card-title>

                                    
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="reportItem(item)"
                                    >
                                        Report
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>

                                </v-dialog>

                                

                            </v-col>
                            
                        </v-row>
                    </v-container>

                    </td>                              
                </template>
                <template v-slot:[`item.isFlagged`]="{ item }">
                    <v-chip v-if="item.isFlagged == true" color="yellow" small text-color="black">                    
                    Flagged
                    </v-chip>
                </template>
                
            </v-data-table>

            
        </v-tab-item>
        <v-tab-item> <content-moderation-reviewed> </content-moderation-reviewed> </v-tab-item>
     </v-tabs-items>

  </dashboard-layout> 
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import ContentModerationReviewed from '@/components/ContentModerationReviewed.vue'

export default {
    components: { DashboardLayout , ContentModerationReviewed},
    computed : {
        links() {
            return this.$store.state.links
        }
     },
     data(){
        return{
            activeTab : '',
            expanded: [],
            singleExpand: true,
            headers: [
            {text: '#',align: 'start', sortable: false, value: 'isFlagged',width:1},
            { text: 'Post', align: 'start',sortable: false,value: 'postHeader',},
            { text: 'Posted By', sortable: false,  value: 'email' },],
            posts: [
                {postHeader: 'This a heading', email: 'ashik.sali@enym.in',postContent: 'Test Post Content',postTags: 'ynwa',isFlagged:true},
                {postHeader: 'Another Comment', email: 'vc@entym.com',postContent: 'Another Test Commet',postTags: 'coyg',isFlagged:false}
            ],
            verifyDialog: false,
            reportDialog: false

        }
     },
     methods : {
        verifyItem(item){
            // Do Backend Action
            const deletedIndex = this.posts.indexOf(item)
            this.verifyDialog = false
            this.posts.splice(deletedIndex, 1)
            
            console.log('Verified')

        },
        reportItem(item){
            const deletedIndex = this.posts.indexOf(item)
            this.reportDialog = false

            this.posts.splice(deletedIndex, 1)
            console.log('Reported')
            console.log(item)
        }
     }
}
</script>

<style>

</style>