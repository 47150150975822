var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-layout',{attrs:{"links":_vm.links}},[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v("Pending")]),_c('v-tab',[_vm._v(" Reviewed ")])],1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.posts,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"Userpostsid","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v("Description : ")]),_vm._v(" "+_vm._s(item.postContent))]),_c('v-col',[_c('b',[_vm._v(" Flags ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v("Tags : ")]),_vm._v(" "+_vm._s(item.postTags)+" ")])],1),_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"offset":"6"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Verify "),_c('v-avatar',{attrs:{"right":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1)],1)]}}],null,true),model:{value:(_vm.veriryDialog),callback:function ($$v) {_vm.veriryDialog=$$v},expression:"veriryDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Privacy Policy ")]),_c('v-card-text',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.verifyItem(item)}}},[_vm._v(" Verify ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Report Comment "),_c('v-avatar',{attrs:{"right":""}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)]}}],null,true),model:{value:(_vm.reportDialog),callback:function ($$v) {_vm.reportDialog=$$v},expression:"reportDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Verify ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.reportItem(item)}}},[_vm._v(" Report ")])],1)],1)],1)],1)],1)],1)],1)]}},{key:"item.isFlagged",fn:function(ref){
var item = ref.item;
return [(item.isFlagged == true)?_c('v-chip',{attrs:{"color":"yellow","small":"","text-color":"black"}},[_vm._v(" Flagged ")]):_vm._e()]}}],null,true)})],1),_c('v-tab-item',[_c('content-moderation-reviewed')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }