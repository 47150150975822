<template>
  <h1>Page Not Found</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>