<template>
  
  <v-data-table
  :headers="headers"
  :items="posts"
  :loading="loader"
  :options.sync="options"
  :server-items-length="totalRows"
  item-key="Userpostsid"
  @click:row="handleRowClick"
  > 

    
  </v-data-table>

</template>

<script>
import {nocodb} from '@/axios'
export default {

    data(){
        return{
            headers: [
            {text: '#',align: 'start', sortable: false, value: 'isFlagged',width:1},
            { text: 'Post', align: 'start',sortable: false,value: 'Caption',},
            { text: 'Posted By', sortable: false,  value: 'Name' },
            {text: 'Status', sortable: false,  value: 'Reviewstatus' },
            {text: 'Reviewd By ', sortable: false,  value: 'Reviewedby' },
            {text: 'Time (YYYY-MM-DD)', sortable: false,  value: 'Timestamp'}],
            posts: [],
            options: {},
            totalRows: 0,
            itemsPerPage : 10,

        }
    },
    async created(){
        this.fillTable(0)
    },
    methods : {
        async fillTable(offset){
            this.loader = true
            const data = await nocodb.get('UserPostsModerationView',{params: {where : '(Isreviewed,eq,1)', limit :this.itemsPerPage, offset:offset }})
            console.log(data.data)
            this.posts = data.data.list
            this.totalRows = data.data.pageInfo.totalRows
            this.loader = false

        },
        getPaginationOffset(pageNumber){
          let offset = 0;
          if (pageNumber > 1){
              offset = (pageNumber-1) * 10
          }
          return offset 
        },
        handleRowClick(event){
            console.log(event)
            let path = '/moderate/posts/' + event.Userpostsid
            this.$router.push({path:path})
        }
    },
    watch : {
        options: {
            async handler(newValue){
                // Call Api according to the next page
                
                const offset = this.getPaginationOffset(newValue.page)
                await this.fillTable(offset)
            },
            deep: true
        }
     }


}
</script>

<style>

</style>