<template>
   <v-expansion-panels multiple v-model="panel" accordion>
            
            <v-expansion-panel>
                
                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Summary</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row v-if="summaryOverlay">
                        <v-col class="text-body-2">
                                <v-progress-linear
                                color="primary"
                                indeterminate
                                rounded
                            ></v-progress-linear>
                        </v-col> 
                    </v-row>
                    <v-row v-if="!summaryOverlay">
                            <v-col class="text-body-2">
                                Avg User Rating
                            </v-col>
                            <v-col class="text-body-2">
                                {{avgRating}}
                            </v-col>

                            <v-col class="text-body-2">
                                Number of Ratings
                            </v-col>
                            <v-col class="text-body-2">
                                {{numOfRatings}}
                            </v-col>
                        </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Rated By</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    hide-default-footer
                    :headers="ratedByHeader"
                    :items="ratedByContent"
                    item-key="name"
                    :loading="ratedByLoader"
                    class="elevation-1">
                    <template  v-slot:[`item.userId`]="{ item }">
                        <router-link :to="{ name: 'user' , params: {userId:item.userId} }">
                            {{ item.userId }}
                        </router-link>
                        <!-- <a target="_blank" :href="item.recieptUrl">
                            {{ item.recieptUrl }}
                        </a> -->
                    </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header class="text-subtitle2 font-weight-medium">Ratings Given</v-expansion-panel-header>
                <v-expansion-panel-content>              
                <!-- Table -->
                <v-data-table
                    dense
                    hide-default-footer
                    :headers="ratingsGivenHeader"
                    :items="ratingsGivenContent"
                    item-key="name"
                    :loading="ratingsGivenLoader"
                    class="elevation-1">
                    <template  v-slot:[`item.userId`]="{ item }">
                        
                        <router-link :to="{ name: 'user' , params: {userId:item.userId} }">
                            {{ item.userId }}
                        </router-link>
                        <!-- <a target="_blank" :href="item.recieptUrl">
                            {{ item.recieptUrl }}
                        </a> -->
                    </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>

   </v-expansion-panels>
</template>

<script>
import {entymApi} from '@/axios'
export default {
    props: ['userId'],
    async created(){
        // Start loader before API Call
        this.ratingsGivenLoader= true
        this.ratedByLoader = true
        this.summaryOverlay= true
        //Call API
        const ratingsResp = await entymApi.get('EntymUser/Ratings',{params: {EntymUserId: this.userId}})
        console.log(ratingsResp.data)
        this.avgRating = ratingsResp.data.avgRating
        this.numOfRatings = ratingsResp.data.numOfRatings
        this.ratedByContent = ratingsResp.data.ratedBy
        this.ratingsGivenContent = ratingsResp.data.ratingsGiven

        // Stop Loader
        this.ratingsGivenLoader= false
        this.ratedByLoader = false
        this.summaryOverlay = false
        

    },
    data(){
        return {
            panel: [0,1,2,3,4,5,6],
            expanded: [],
        singleExpand: true,
            summaryOverlay: false,
            ratedByLoader: false,
            ratingsGivenLoader: false,
            avgRating: 0,
            numOfRatings: 0,
            ratedByHeader: [
                { text: 'UserId',align: 'start',sortable: false,value: 'userId',},
                { text: 'Name', value: 'name' , sortable: false},
                { text: 'Rating', value: 'rating' , sortable: false},
                { text: 'TimeSlot Id', value: 'timeSlotId' , sortable: false},
            ],
            ratedByContent: [],
            ratingsGivenHeader: [
                { text: 'UserId',align: 'start',sortable: false,value: 'userId',},
                { text: 'Name', value: 'name' , sortable: false},
                { text: 'Rating', value: 'rating' , sortable: false},
                { text: 'TimeSlot Id', value: 'timeSlotId' , sortable: false},

            ],
            ratingsGivenContent: []
        }
    }
}
</script>

<style>

</style>