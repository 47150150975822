<template>
 <dashboard-layout :links="links">

     <v-card>
             <v-toolbar color="primary"> <v-toolbar-title>Content Sources</v-toolbar-title> </v-toolbar>
            <h2> </h2>
        <v-list>
            <v-list-item-group v-model="model">
                
                <!-- <v-list-item  @click="$router.push({ path: '/sendnotifications/all'})"> 
                    <v-list-item-content>
                <v-list-item-title > Send to all users </v-list-item-title>
              </v-list-item-content>                    
                 </v-list-item> -->


                <v-list-item @click="$router.push({ path: '/moderate/posts'})"> 
                
                <v-list-item-icon>
                    <v-badge :content="postsUnchecked" :value="postsUnchecked" color="blue" overlap> 
                    <v-icon > mdi-post </v-icon>
                    </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Posts</v-list-item-title>                  

                </v-list-item-content>
                <v-chip  color="yellow" text-color="black">                    
                    Flagged
                    <v-avatar right class="yellow">
                        {{flaggedPosts}}
                    </v-avatar>
                    </v-chip>
                    <v-spacer> </v-spacer>                
                </v-list-item>

                <v-list-item @click="$router.push({ path: '/moderate/postcomments'})"> 
                
                <v-list-item-icon>
                    <v-badge :content="postsCommentsUncheked" :value="postsCommentsUncheked" color="blue" overlap>
                        <v-icon > mdi-comment </v-icon>
                    </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Post Comments </v-list-item-title>
                </v-list-item-content> 
                <v-chip  color="yellow" text-color="black">                    
                    Flagged
                    <v-avatar right class="yellow">
                        {{flaggedPostComments}}
                    </v-avatar>
                    </v-chip>
                    <v-spacer> </v-spacer>    
                </v-list-item>

                 <v-list-item @click="$router.push({ path: '/moderate/publiclinks'})"> 
                
                <v-list-item-icon>
                    <v-badge :content="linksUnchecked" :value="linksUnchecked" color="blue" overlap>
                        <v-icon > mdi-link-box-variant </v-icon>
                    </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Public Links </v-list-item-title>
                </v-list-item-content> 
                <v-chip  color="yellow" text-color="black">                    
                    Flagged
                    <v-avatar right class="yellow">
                        {{flaggedLinks}}
                    </v-avatar>
                    </v-chip>
                    <v-spacer> </v-spacer>    
                </v-list-item>

            </v-list-item-group>
        </v-list>

        </v-card>
 
 </dashboard-layout>
  
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import {nocodb} from '@/axios'

//import sendNotificationForm from '@/components/sendNotificationForm.vue'
export default {
    components: { DashboardLayout},
    computed : {
        links() {
            return this.$store.state.links
        }
     },
     data(){
        return {
            model: '',
            postsUnchecked: 0,
            flaggedPosts: 0 ,
            postsCommentsUncheked: 0,
            flaggedPostComments:0,
            linksUnchecked: 0,
            flaggedLinks:0 


        }
     },
     async created(){
        const postsUnchecked = await nocodb.get('Userposts/count', { params: {where : '(Isreviewed,eq,0)'}} )
        const postsFlagged = await nocodb.get('Userposts/count',{params : {where :`(Isreviewed,eq,0)~and(IsFlagged,eq,1)`}})
        this.postsUnchecked = postsUnchecked.data.count
        this.flaggedPosts = postsFlagged.data.count
     },

     

}
</script>

<style>

</style>