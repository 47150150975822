<template>
    <finance-layout>
        
        <div  v-if="loader" class="d-flex justify-center align-center">
        <v-progress-circular              
          :size="70"
          color="primary"
          indeterminate
          :width="10"
        ></v-progress-circular>
      </div>
        
        <v-card v-else
        elevation="2"
        >
        <v-container>
            <v-row class="my-6">
               <h4>  Refund Doc Id : {{ UserRefundsData.Refunddocid }} </h4> 
            </v-row>
            <v-row>
                <v-col> Timeslot Id : {{ UserRefundsData.Timeslotid }} </v-col> <v-col> Timeslot TrackerId : {{UserRefundsData.Referenceid  }}</v-col>
            </v-row>
            <v-row>
                <v-col> Refund  Amount : {{ UserRefundsData.Refundamount }} {{ UserRefundsData.Refundcurrency }}</v-col> <v-col> Issued By : {{ UserRefundsData.Issuedby }}</v-col>
            </v-row>
            <v-row>
                <v-col> Type : {{ UserRefundsData.Type }}</v-col> <v-col> Description : {{ UserRefundsData.Description }}</v-col>
            </v-row>

            <v-row>
                <v-col> Reason : {{ UserRefundsData.Reason  }}</v-col> <v-col> Refund Issued Date : {{ UserRefundsData.Daterefundissued }}</v-col>
            </v-row>

            <v-row>
                <v-col> Seller Id : </v-col> <v-col> Seller email :</v-col>
            </v-row>

            <v-row> 
               <b> Payment Info </b> 
            </v-row>

            <v-row>
                <v-col> Payment Intent Id : {{ BookingsData.paymentintentid }}</v-col>
            </v-row>

            <v-row> 
                <v-col> Seller id : {{ BookingsData.sellerid }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="6">

                
                <v-list-item v-for="row in Charges" :key="row.ChargeId">
                  <v-list-item-content> 
                    <v-text-field
                    v-model="row.Charge"
                    :rules="chargeTextRules"
                    label="Charge"
                    required
                    ></v-text-field> 
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                  <v-text-field
                  v-model="row.Amount"
                  :rules="chargeAmountRules"
                  label="Amount"
                  type="text"
                  required
                  ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                
                <v-list-item>                                  
                        <v-list-item-content class="align-end">
                            <v-btn icon @click="addRow()"> 
                              Add row  
                                <v-icon small right>mdi-plus-circle</v-icon>
                                
                            </v-btn> 
                        </v-list-item-content>

                        <v-list-item-content class="align-end">
                            <v-btn  icon @click="deleteRow()"> 
                              Delete row 
                                <v-icon small right>mdi-minus-circle</v-icon>
                                
                            </v-btn> 
                        </v-list-item-content>

                    
                </v-list-item>
            </v-col>
            </v-row>
            <v-row class="ma-6">



                
            </v-row>

        </v-container>
    
        <v-toolbar color="grey lighten-4" >
                <p class="mx-12"> Refund Amount : {{ RefundAmount }} </p>
                <p  class="mx-12"> Total Charges : {{ TotalCharges }}</p>
                <FirebaseLoginComponent ButtonName="Refund Now" @authenticated="onAuth" @authFailed="authFailed"> </FirebaseLoginComponent>
                <!-- <v-btn class="mx-12 green"  @click="InitiateRefund"> Refund Now </v-btn> -->
            
        </v-toolbar>

        </v-card>

        
            


        

    </finance-layout>
  
</template>

<script>
import FinanceLayout from '@/layouts/FinanceLayout.vue'
import FirebaseLoginComponent from '@/components/FirebaseLoginComponent.vue'
import { nocodb, entymApi } from '@/axios'
 
//import {nocodb} from '@/axios'
export default {
    props: ['id'],
    components: {
    FinanceLayout,FirebaseLoginComponent
  },
  async created(){
    const refundsresp = await nocodb.get('Userrefunds/'+this.id)
    console.log('inside',refundsresp)
    this.UserRefundsData = refundsresp.data
    const bookingresp = await entymApi.get('Payouts/GetRefundBookingData',{params: {UserRefundsId:this.id , TimeslotTrackerId : this.UserRefundsData.Referenceid}})
    console.log('bookingresp',bookingresp)
    this.BookingsData = bookingresp.data
  },
  data(){
    return{
        UserRefundsData : {},
        BookingsData : {},
        Charges: [{ChargeId:1,Charge: '', Amount: 0}],
        chargeTextRules : [
                v => v.length >=4 || 'Minimum 4 characters'
            ],
            chargeAmountRules : [
                v => /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(v) || 'Must be a valid Number',
            ],

        UserName: '',
        loader : false,
        firebasedAuthenticated : false
        
    }
  },
  computed: {
      TotalCharges(){
        return this.Charges.reduce((sum,obj) =>{
            return sum + parseFloat(obj.Amount)
        },0)
    },
    RefundAmount(){
        return this.UserRefundsData.Refundamount - this.TotalCharges
    }
    
    
    },
    methods: {
        totalCharges: function(){
            const sum =  this.Charges.reduce((accumulator,currentValue) => {
                
                return accumulator + parseFloat(currentValue.Amount) 
            },0.0)
            return sum
        },
        totalPaymentAmount(){
            return this.obj.Amount - this.totalCharges()
        },
        addRow(){
          // Find the right object in the array
              //Get new chargeid
              const ChargeId = this.Charges.length + 1 
              const charge = { ChargeId: ChargeId , Charge: '', Amount :0}
              //this.items[i].Charges.push(charge)
              this.Charges.push(charge)
              //this.$set(this.Charges, this.Charges.length , charge)
              //this.$router.go(0);
              //const length = this.items[i].Charges.length
              //this.componentKey = this.componentKey + 1 ;
              //console.log(this.componentKey)
              
              //this.$set(this.items[i],'charge'+chargeId, true);
              console.log(this.Charges)
            },
        deleteRow(){

              this.Charges.pop()

              //const chargeId = this.Charges.length + 1
              //delete this.items[i]['charge'+chargeId];
              //  this.$delete(this.items[i],'charge'+chargeId);             
            },
        async getUserName(){
            const resp = await nocodb.get('Userpersonaldata',{params: {where:`(Entymuserid,eq,${this.obj.Entymuserid})`}})
            return resp.data.list[0].Name
        },
        async onAuth(token){
            await this.InitiateRefund(token)
        },
        async InitiateRefund(token){
            //Check if you are logged in 
            
            if (confirm(" Are you sure you want proceed with refund")  == true ){
                this.loader = true
                const body = {
                    RefundInitaiedBy : this.$auth.user.email,
                    ChargesAmount : this.TotalCharges,
                    Charges : this.Charges,
                    Currency : this.UserRefundsData.Refundcurrency,
                    RefundType : this.UserRefundsData.Type,
                    Amount : this.RefundAmount,
                    RefundsId : this.UserRefundsData.Id,
                    PaymentIntent : this.BookingsData.paymentintentid,
                    Refunddocid : this.UserRefundsData.Refunddocid,
                    token : token

                    }

                    try{
                        var resp = await entymApi.post('Payouts/CreateStripeRefund',body)
                        if(resp == 'success'){
                            alert('Refund Initiated Successfully')
                        }
                    }
                    catch(e){
                        console.error(e)
                    }
                    finally{
                        this.loader = false
                        this.$router.push({path: `/refunds-cancellations`})
                    }
                    

                   
                    
            }

            
        },
        authFailed(error){
            console.log(error)
        }
       
        

        
    }
}
</script>

<style>
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>